import React from 'react';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import About from './about';
import HomeNFLFull from './HomeNFLFull';
//import HomeNFLSuper from './HomeNFLSuper';
import HomePGAFull from './HomePGAFull';
import HomeMLBFull from './HomeMLBFull';


function App() {
  return (
   
    <div className="App">
       <Router>
      <Navbar></Navbar>
      {/* Conditional Routing:  https://dev.to/salehmubashar/conditional-routing-with-react-router-v6-229g  */}
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About/>} />
        <Route path='/HomeNFLFull' element={<HomeNFLFull/>} />
        {/* <Route path='/HomeNFLSuper' element={<HomeNFLSuper/>} />  */}
        <Route path='/HomePGAFull' element={<HomePGAFull/>} />
        <Route path='/HomeMLBFull' element={<HomeMLBFull/>} />
    </Routes>
   {/*  <div className="content">
      <Home></Home>
     </div>
  */}
     </Router>
    </div>
    
  );
}

export default App;