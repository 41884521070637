const Checkbox = ({ label, value, onChange }) => {
    return (
        <div className="checkbox">
            <label>
            <input  type="checkbox" checked={value} onChange={onChange} />
            {label}
            </label>
      </div>
    );
  };

export default Checkbox;