import React from 'react';
import { useState, useEffect } from 'react';
import PGAFullPlayerList from './PGAFullPlayerList';
import PGAFullLineupList from './PGAFullLineupList';
import PGAFullSingleLineup from './PGAFullSingleLineup.js';
//import Checkbox from './Checkbox';
import Slider from './Slider';
import styled from 'styled-components';
//import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} 
   // from 'react-accessible-accordion';
import Tooltip from "@material-ui/core/Tooltip";
//import slates from './Slates.js';

/*************************************************************************************/
/*                      SET ENVIRONMENT VARIABLES                                    */
/*************************************************************************************/

const sport = 'PGA'; // this .js file is this sport
const positionTypes = ['G']; // for player rating
const fullSinglLineupGenerate = 25; // If "one lineup at a time" is chosen, we need to grab X number of lineups behind scenes. This constant is "X".
const minPlayersToRank = 10;

var injuryAPI = '';
var lineupAPI = '';
var playerAPI = '';
var slateAPI = '';
var slatesAPI = ''; 
var boolLogMissingInjuryPlayers = false;

if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
{
    injuryAPI = process.env.REACT_APP_UAT_API_INJURY;
    playerAPI = process.env.REACT_APP_UAT_API_PLAYER;
    slateAPI = process.env.REACT_APP_UAT_API_SLATE;
    slatesAPI = process.env.REACT_APP_UAT_API_SLATES;
    lineupAPI = process.env.REACT_APP_UAT_API_PGAFULLLINEUP;
    if (process.env.REACT_APP_UAT_LOG_MSSINGINJURYPLAYERS.toUpperCase() === 'TRUE')
        {boolLogMissingInjuryPlayers = true;}
}
else if (process.env.REACT_APP_ENVIRONMENT === 'PROD')
{
    injuryAPI = process.env.REACT_APP_PROD_API_INJURY;
    playerAPI = process.env.REACT_APP_PROD_API_PLAYER;
    slateAPI = process.env.REACT_APP_PROD_API_SLATE;
    slatesAPI = process.env.REACT_APP_PROD_API_SLATES;
    lineupAPI = process.env.REACT_APP_PROD_API_PGAFULLLINEUP;
    if (process.env.REACT_APP_PROD_LOG_MSSINGINJURYPLAYERS.toUpperCase() === 'TRUE')
        {boolLogMissingInjuryPlayers = true;}
}

const Button = styled.button`
background-color: #83a9ff;
border: none;
color: white;
padding: 5px 20px;
text-align: center;
text-decoration: none;
display: inline;
font-size: 16px;
font-family: Quicksand;
border-radius: 0px;
cursor:pointer;

${({ activePositionChoice }) =>
activePositionChoice &&
`
background-color: #1e90ff;
opacity: 1;
`}
`;

const ButtonGroup = styled.div`
display: flex;
`;

const HomePGAFull = () => {

/*************************************************************************************/
/*                      SET USE STATE VARIABLES                                      */
/*************************************************************************************/
   
    /*Steps to Hide/Show modules */
    const [step, setStep] = useState(2);
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [showPrevBtn, setShowPrevBtn] = useState(false);
    const [showNextBtnSingleLineup, setShowNextBtnSingleLineup] = useState(false);

    /* Core slate attributes - loaded in useEffect in step 2 */
    const [siteOfSlate, setSiteOfSlate] = useState(""); // DK or FD
    const [typeOfSlate, setTypeOfSlate] = useState("classic"); // eg "classic"
    const [slateInfo, setSlateInfo] = useState([]); // all the slate info for the slateID passed in query string (from MySQL table)
    const [oneVsMany, setOneVsMany] = useState("One"); // default to "one" - bulk is more niche

    /*Error/warning messages */
    const [errMessage,setErrMessage]=useState('');
    const [warningMessage,setWarningMessage]=useState('');
    const [successMessage,setSuccessMessage]=useState('');
    const [isLoading, setIsLoading] = useState(false);

    /*Salary Slider */
    const [salaryMin, setSalaryMin] = useState(95);

    /*# Lineups Slider */
    const [numbOfLineups, setNumbOfLineups] = useState(fullSinglLineupGenerate);

    /* RATING / RANK stuff  */
    const [activePositionChoice, setActivePositionChoice] = useState(positionTypes[0]);
    const [tempCounter, setTempCounter] = useState(1);

    /* Get injury news and mapping data to update player statuses */
    const [injuryInfo,setInjuryInfo]=useState([]);

    /* GENERATE LINEUPS CLICK */
    const [lineupExport,setLineupExport]=useState([]);
    const [lineupPlayerSummary,setLineupPlayerSummary]=useState([]);
    const [emptyArray, setEmptyArray] = useState([]);
    const [displayPlayerSummary, setDisplayPlayerSummary] = useState(false);
    const [displayOneLineup, setDisplayOneLineup] = useState(false);
    const [oneLineupCounter, setOneLineupCounter] = useState(0);
    const [betaPassword, setBetaPassword] = useState('');
    const [requiredPassword, setRequiredPassword] = useState(false);

    /* IMPORT CSVs and JS data files */
    const [finalPlayerPool,setFinalPlayerPool]=useState([]); // final player list of *only* rated players user has clicked
    const [playerPool,setplayerPool]=useState([]);         // initial slate load - excludes min salary
    const [masterPlayerInfo,setMasterPlayerInfo] = useState([]); // master player info for sport at heand

/*************************************************************************************/
/*                      HANDLE NEXT/PREVIOUS BUTTONS                                 */
/*************************************************************************************/

/* IMPORTANT:   (see HomeNFLFull.js for more info)
    step 1 - Home.js (where they choose slate and 'one vs many')
    step 2 - Configuration settings (e.g. salary slider, lineup count slider)
    step 3 - Player rating/lock info
    step 4 - Results - Bulk summary/export or individual lineup display/management
    */

    const handleNextClick = () => {
       if (step === 2)
        {
            clearAllMessages();

            if (slateInfo.length > 0) // we found the slateID in the MySQL DB
            {
                // Make sure the slate is still active to show
                var tempSlates = [];
                var currentDate = new Date();
                var currentdateUTC = currentDate.toUTCString(); // ALWAYS UTC -- EVERYWHERE!
        
                tempSlates = slateInfo.filter(slate => {return slate.sport === sport && new Date(slate.displaySlateDate) <= Date.parse(currentdateUTC) && new Date(slate.hideSlateDate) >= Date.parse(currentdateUTC)});

                if (tempSlates.length > 0) // slate is found and is valid/active! 
                {
                    setStep(3);
                    setShowNextBtn(false); // because it's replaced with the 'generate' button

                    getInjuryInfo();
                    transformMasterPlayerData();
                }
                else
                {
                    setErrMessage('Error! Slate has expired. Hit "Back" and try again or contact support.');
                }
            }
            else
            {
                setErrMessage('Error! Slate does not exist any longer. Hit "Back" and try again or contact support.');
            }
        }
    }

    const handlePreviousClick = () => {
        if (step === 1) // Only for fatal errors
        {
            window.location.replace('./');
        }
        else if (step === 2)
        {
            if (window.confirm('You will lose all your inputs if you go back. Is that OK?'))
            {
                window.location.replace('./');
            }
        }
        else if (step === 3)
        {
            setStep(2);
            setShowNextBtn(true);
        }
        else if (step === 4)
        {
            setStep(3);
            setShowNextBtn(false);
        }
        
        clearAllMessages();
        setDisplayPlayerSummary(false);
        setDisplayOneLineup(false);
        setShowNextBtnSingleLineup(false);
    }

    const clearAllMessages = () => {
        if (errMessage !== "")
            {setErrMessage("");} 
        if (successMessage !== "")
            {setSuccessMessage("");} 
        if (warningMessage !== "")
            {setWarningMessage("");}   
    }

/*************************************************************************************/
/*                      LOAD PLAYER/SLATE INFO                                       */
/*************************************************************************************/
/* Get the specific slate at hand (from step 1) */
const getSlates = async (slateID) => { 

    // var slateDetails = slates.find(slate => {return slate.value === slateSelected});
    try {
        const responseSlate = await fetch(slatesAPI, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'sport': sport, // sport,
                'slateid': slateID, // slateSelected,
            }
        });
        const result = await responseSlate.json();

        if (responseSlate.ok) {
            if (result[0] !== undefined)
            {
                var tempSlates = result[0];
                //tempSlates = tempSlates.sort((a, b) => Number(a.order) - Number(b.order));
                setSlateInfo(tempSlates);
                setSiteOfSlate(tempSlates[0].site);
                setTypeOfSlate(tempSlates[0].slateType);
            }
        }
    } catch (err) {
        console.log('Error caught!  Problem getting slates - ' + err.message);
    } finally {
    }
}

    // this gets raw slate player data from Azure for the slate we're on
    // we'll transform/cleanse this data on the next step
    const getSlatePlayerData = async(slateID) => {
      
        //var slateDetails = slates.find(slate => {return slate.value === slateSelected});
        
        try {
            const responseSlateInfo = await fetch(slateAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                    'slateid': slateID,
                }
            });
            const result = await responseSlateInfo.json();

            if (responseSlateInfo.ok) {
                if (result[0] !== undefined)
                {
                    setplayerPool(result); // this is all available players in slate
                }
            }

        } catch (err) {
            console.log('Critical error!  Problem getting raw slate data for ' + sport + ' for the slateID: ' + slateID.toString() + '. Error:' + err.message);
            setErrMessage('Critical error!  Failed getting slate data. Contact support or try again. Error: ' + err.message);
        } finally {
        }
    }
    
    // this gets raw master player data from Azure for the sport we're on
    // we'll transform/cleanse this data on the next step
    const getMasterPlayerData = async() => {
        
        try {
            const responseMasterPlayer = await fetch(playerAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                }
            });
            const result = await responseMasterPlayer.json();

            if (responseMasterPlayer.ok) {
                if (result[0] !== undefined)
                {
                    setMasterPlayerInfo(result);
                }
            }

        } catch (err) {
            console.log('Critical error!  Problem getting player data for ' + sport + '. Error:' + err.message);
            setErrMessage('Critical error!  Failed getting player data. Contact support or try again. Error: ' + err.message);
        } finally {
        }
    }

    // this takes the master player data (for sport we're on) from the previous step and transforms it to fit DK or FD
    const transformMasterPlayerData = () => { 
        var jsonDataClean = masterPlayerInfo;
        var tempJSONdata = [];
             
        if (siteOfSlate === 'DK')
        {
            for (var i = 0; i<jsonDataClean.length; i++)
            {
                var obj = jsonDataClean[i];

                if (obj['PlayerDKName'].length > 0)
                {obj['PlayerName'] = obj['PlayerDKName'];}
            
                tempJSONdata.push(obj);
            }
            jsonDataClean = tempJSONdata;
        }
        else if (siteOfSlate === 'FD')
        {
            for (var i = 0; i<jsonDataClean.length; i++)
            {
                var obj = jsonDataClean[i];

                if (obj['PlayerFDName'].length > 0)
                {obj['PlayerName'] = obj['PlayerFDName'];}
            
                tempJSONdata.push(obj);
            }
            jsonDataClean = tempJSONdata;
        }
        setMasterPlayerInfo(jsonDataClean);
    }

/*************************************************************************************/
/*                      HANDLE CONFIGURATION UI CONTROLS                             */
/*************************************************************************************/
    
    const handleChangeMinSalary = (index) => {
        setSalaryMin(index);
        if (errMessage === "Error!  Min Salary must be less that 99%" && index <= 98.5)
            {setErrMessage("");}
      };

    const handleChangeNumbOfLineups = (index) => {
        setNumbOfLineups(index);
      };

/*************************************************************************************/
/*                      HANDLE PLAYER RATINGS & IMAGES                               */
/*************************************************************************************/

    const handleDeletePlayer = (id) => {
        // player pool the user sees/ranks
        const newPlayers = playerPool.filter(player => player.ID !== id);
        setplayerPool(newPlayers);

        // player pool behind scenes w/ their exposure choices
        const newPlayers2 = finalPlayerPool.filter(player => player.ID !== id);
        setFinalPlayerPool(newPlayers2);
    }

    // this is for position sub-header for player list view/rankings
    const handleClickPosChoice = (name, e) => {
        setActivePositionChoice(name);
    }

    const getRating = (id) => {
        return playerPool.find(player => {return player.ID === id}).exposure;
    }

    const getLock = (id) => {
        return playerPool.find(player => {return player.ID === id}).lock;
    }
    
    const getLockByNameAndID = (id) => {
        //id=id.replace("'", ""); // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
        let lockStatus = '';
     
       if (playerPool.find(player => {return player['Name + ID'] === id}) !== undefined)
            {
                if (playerPool.find(player => {return player['Name + ID'] === id}).lock === true)
                {
                    lockStatus = 'Lock';
                }
            }
      
       return lockStatus;
    }

    const getPlayerStat = (playerName, statistic) => {
        //playerName=playerName.replace("'", ""); // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
        let playerStat = '';
 
        if (masterPlayerInfo.find(player => {return player.PlayerName === playerName}) !== undefined)
             {
                if (statistic === "Top10")
                {
                    playerStat = masterPlayerInfo.find(player => {return player.PlayerName === playerName}).Top10s;
                }
            }
        if (playerStat.length === 0)
             {playerStat = 'N/A';}

        return playerStat;
    }

    const setRatingValue = (id, newRating) => {

        const tempPlayers = playerPool; 
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        clearAllMessages();

        if (newRating === null) // if user un-ranks a player
            {newRating = 0;}  // API/proc should ignore un-ranked players
        
        if (tempPlayer.lock === false) // Don't touch if locked, as it's basically a max rating
        {
            // update the player pool the user sees and ranks
            tempPlayer.exposure = newRating;
            let arrayindex = playerPool.findIndex(obj => obj.ID === id);
            tempPlayers[arrayindex] = tempPlayer;

            setplayerPool(tempPlayers);

            // update the player pool behind the scenes for the final 'generate' click
            const tempPlayers2 = finalPlayerPool; 
            let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
            if (tempPlayer2 !== undefined)
            {
                tempPlayer2.exposure = newRating;
                let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
                tempPlayers2[arrayindex2] = tempPlayer2;
            }
            else
            {
                tempPlayers2.push(tempPlayer);
            }

            setFinalPlayerPool(tempPlayers2);
            // dirty hack to refresh the player pool the user sees
            setTempCounter(tempCounter + 1); // ...  so updating a counter...
        }
    }

    const setLockValue = (id) => {

        // update the player pool the user sees and ranks
        const tempPlayers = playerPool; 
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        let newLockValue = false;

        if (tempPlayer.lock === false)
        {
            newLockValue = true;
            setRatingValue(id, 5);
        }
        tempPlayer.lock = newLockValue;
        let arrayindex = playerPool.findIndex(obj => obj.ID === id);
        tempPlayers[arrayindex] = tempPlayer;
        setplayerPool(tempPlayers);

        // update the player pool behind the scenes for the final 'generate' click
        const tempPlayers2 = finalPlayerPool; 
        let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
        if (tempPlayer2 !== undefined)
        {
            tempPlayer2.lock = newLockValue;;
            let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
            tempPlayers2[arrayindex2] = tempPlayer2;
        }
        else
        {
            tempPlayers2.push(tempPlayer);
        }
        setFinalPlayerPool(tempPlayers2);
        // dirty hack to refresh the player pool the user sees
        setTempCounter(tempCounter + 1); // ...  so updating a counter...
    }

    /* Player Images */
    const getImage = (id) => {
       //id=id.replace("'", "");  // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
       let pictureURL = 'NoPlayerImage.png';

       if (masterPlayerInfo.find(player => {return player.PlayerName === id}) !== undefined)
            {pictureURL = masterPlayerInfo.find(player => {return player.PlayerName === id}).PicURL;}
       if (pictureURL.length === 0)
            {pictureURL = 'NoPlayerImage.png';}
       return pictureURL;
    }

/*************************************************************************************/
/*                      HANDLE INJURY STATUSES                                       */
/*************************************************************************************/
    const getInjuryInfo = async () => {
        try {
            //console.log('start injury PGA');

            var currentdateUTC = new Date();
            currentdateUTC = currentdateUTC.toUTCString();
        
            const responseInjury = await fetch(injuryAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                    'reactdate': currentdateUTC,
                }
            });
            const result = await responseInjury.json();

            //console.log('after injury call PGA');

            if (responseInjury.ok) {
                if (result[0] !== undefined)
                {
                    //console.log('PGA injury results returned!');
                    // Update the injury result set with either DK or FD name/team
                    let i = 0;
                    while (i < result.length)
                    {
                        let updatedPlayerAndTeam = masterPlayerInfo.find(player => {return player.PlayerInjuryName === result[i].playername});
                        if (updatedPlayerAndTeam !== undefined)
                        {
                            result[i].playername = updatedPlayerAndTeam.PlayerName;
                        }
                        i++;
                    }
                }
            }
            // Now handle case of overrides in this sport's MasterPlayers.csv (loaded from previous step)
            // First, find all players that have an active injurty status override in the MasterPlayers.csv file
            let i2 = 0;

            let tempOverrideStatusPlayers = masterPlayerInfo.filter(player => {return player.OverrideStatus !== "" && (player.OverrideDateUTC === "" || Date.parse(player.OverrideDateUTC.toString() + " GMT") > Date.parse(currentdateUTC))});
      
             // Next, loop through those players and either UPDATE or INSERT into injury result set
            while (i2 < tempOverrideStatusPlayers.length)
                    {
                        let tempInjuredInfo = result.find(player => {return player.playername === tempOverrideStatusPlayers[i2].PlayerName});
                        if (tempInjuredInfo !== undefined) // UPDATE
                            {
                                let tempPlayerIndex = result.findIndex(obj => obj.playername === tempOverrideStatusPlayers[i2].PlayerName)
                                result[tempPlayerIndex].playerstatus = tempOverrideStatusPlayers[i2].OverrideStatus;
                                result[tempPlayerIndex].injurytype = "";
                            }
                        else  // INSERT
                            {
                                result.push({
                                    playername: tempOverrideStatusPlayers[i2].PlayerName,
                                    playerteam: "",
                                    playerwithteam: "",
                                    playerstatus: tempOverrideStatusPlayers[i2].OverrideStatus,
                                    injurytype: ""
                                });
                            }
                        i2 = i2 + 1;
                    }
            setInjuryInfo(result);

        } catch (err) {
            //setErrMessage('Error caught!  Problem getting injury news. ' + err.message);
            console.log('Error caught!  Problem getting injury news. ' + err.message);
        } finally {
        }
    }

    // This checks for unknown players in our partner's injury HTML (e.g. if they have a 'Jr.' after a name and the FD/DK/master data we have does not)
        // basically if there is an injured player in our vendor's site that we do *not* have a maching name/team in our master player CSV file, then print in console
        // the solution here is to modify teh master player CSV file and fill in the column 'PlayerInjuryName' w/ the injury's site's player name
    const findUnknownInjuredPlayers = (e) => {

        if (injuryInfo !== undefined && playerPool !== undefined)
        {
            let i = 0;
            while (i < injuryInfo.length){
                let tempInjuredPlayer = injuryInfo[i];
        
                let tempPlayer = playerPool.find(player => {return ((player.Name + ' (' + player.TeamAbbrev + ')') === tempInjuredPlayer.playerwithteam)});
            
                if (tempPlayer === undefined)
                {
                    console.log(injuryInfo[i].playerwithteam);
                }
        
                i++;
            }
        }
    }
    // this is for binding the injury status to player list
     const getInjuryStatus = (playerName) => {

        let injuryStatus = "";

        if (injuryInfo.find(user => {return user.playername === playerName}) !== undefined
            &&
            injuryInfo.find(user => {return user.playername === playerName}).playerstatus.length > 0
            )
        {
            injuryStatus = '(' + injuryInfo.find(user => {return user.playername === playerName}).playerstatus + ') ';
        }

        return injuryStatus;
     }

/*************************************************************************************/
/*                      GENERATE LINEUP(S)                                           */
/*************************************************************************************/
    
  /* SINGLE lineup - called as user migrates the single lineups */
    const handleNextSingleLineup = (e) => {
         // if we've reached the end of the lineup batch, generate a new batch! 
        if (fullSinglLineupGenerate === (oneLineupCounter + 1))
        {
            setShowNextBtnSingleLineup(false);
            setIsLoading(true);
            generateLineups();
        }
        else
        {
            setOneLineupCounter(oneLineupCounter + 1);
            clearAllMessages();
        }
    }

    /* Generate clicked - for both SINGLE and BULK */
    const handleGenerateClick =  (e) => {
        try {
            // Prep

            clearAllMessages();
            setIsLoading(true);
            setLineupPlayerSummary(emptyArray);
            setLineupExport(emptyArray);
            setDisplayPlayerSummary(false);
            setDisplayOneLineup(false);

            // Validation

            if (salaryMin > 98.5)
                {setErrMessage('Error!  Min Salary must be less that 99%');}

            if (errMessage.length === 0)
            {
                const countOfPlayersRated = finalPlayerPool.length;
                const countOfPlayersNeeded = minPlayersToRank - countOfPlayersRated;
                
                if (countOfPlayersNeeded > 0)
                    {setErrMessage('Error! Please rate at least ' + countOfPlayersNeeded.toString() + ' more players.');}
            }

            // Generate

            if (errMessage.length === 0 && finalPlayerPool.length > 0)
            {
                generateLineups();
            }
            else if (finalPlayerPool.length === 0)
            {
                setErrMessage('Error!  Player Pool has ' + finalPlayerPool.length.toString() + ' players. Please rate players or refresh the page and start again.');
                setIsLoading(false);
            }
            else
            {setIsLoading(false);}
        }
        catch (err) {
            setErrMessage('Error caught!  Problem with generating lineups');
            setIsLoading(false);
        } finally {
                
        }
    }

    /* SINGLE and BULK lineups */
    const generateLineups =  async () => {
        try {
            const countOfPlayersRated = finalPlayerPool.length;
            const countOfPlayersNeeded = minPlayersToRank - countOfPlayersRated;
            
            if (countOfPlayersNeeded > 0)
                {setErrMessage('Error! Please rate at least ' + countOfPlayersNeeded.toString() + ' more players.');}

            if( 
                (countOfPlayersNeeded <= 0) 
                &&
                (salaryMin <= 98.5)
                &&
                (betaPassword.length > 0 || requiredPassword === false)
            )
            {
                // this is for debug reasons to find injured players we can't match to the player pool
                if (boolLogMissingInjuryPlayers)
                    {findUnknownInjuredPlayers();}

                const cleanPassword = "gutter";//betaPassword.toLowerCase().replace(/\s+$/g, ''); 
                const encodedData = btoa(cleanPassword); // encode a string
                
                /* This is super useful to see what JSON we are passing API */
                
                /*
                console.log (JSON.stringify({
                    loginName: "defaultuser",
                        passwordValue:  encodedData,
                        dfsSite: siteOfSlate,
                        dfsSlateType: typeOfSlate,
                        salaryMin: salaryMin,
                        numbOfLineups: numbOfLineups,
                        finalPlayerPool: finalPlayerPool
                }));
                */

                const response = await fetch(lineupAPI, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginName: "defaultuser",
                        passwordValue:  encodedData,
                        dfsSite: siteOfSlate,
                        dfsSlateType: typeOfSlate,
                        salaryMin: salaryMin,
                        numbOfLineups: numbOfLineups,
                        finalPlayerPool: finalPlayerPool
                    })
                });
                const result = await response.json();
               
                if (!response.ok) {
                    setErrMessage('Error!  ' + result['originalError']['info']['message'].toString());
                    setIsLoading(false);
                  
                    if (result['originalError']['info']['message'].toString() !== "Incorrect password")
                    {
                        setRequiredPassword(false);
                    }
                }
                else
                {
                    if (result[0] !== undefined)
                    {
                        setRequiredPassword(false);
                        if (result[0].length === 0)
                        {
                            setStep(3);
                            setWarningMessage('Warning:  No lineups generated.  Try to lower your "Min Salary" or add more players.');
                        }
                        else 
                        {
                            setShowNextBtn(false);

                            /* ONE LINEUP */
                            if (oneVsMany === "One")
                            {
                                if (result[0].length >= 1 && result[0].length < numbOfLineups)
                                {
                                    setStep(3);
                                    setWarningMessage('Warning: Not enough lineups generated.  Try to lower your "Min Salary" or add more players.');
                                    setSuccessMessage("");
                                }
                                else
                                {
                                    setSuccessMessage("");
                                    setDisplayOneLineup(true);
                                    setOneLineupCounter(0);
                                    setStep(4);
                                    setLineupExport(result[0]);
                                    setShowNextBtnSingleLineup(true);
                                    setIsLoading(false);
                                }
                            }
                            /* MANY LINEUPS */
                            else 
                            {
                                setStep(4);
                                setLineupExport(result[0]);
                                setDisplayPlayerSummary(true);
                                setLineupPlayerSummary(result[1]);
                                if (result[0].length >= 1 && result[0].length < numbOfLineups)
                                {
                                    setStep(3);
                                    setWarningMessage('Warning:  Only ' + result[0].length.toString() + ' of your ' + numbOfLineups.toString() + ' lineups generated.  Try to lower your "Min Salary" or add more players.');
                                    setSuccessMessage("");
                                }
                                else if (result[0].length === numbOfLineups)
                                {
                                    setSuccessMessage("Success!  Export your " + numbOfLineups.toString() + " lineups or go back to your pool.");
                                }
                            }
                        }
                        setIsLoading(false);
                    }
                }
            }
        } catch (err) {
            let errDescription = ""
            if (err.message.toString() === "f.originalError is undefined" || err.message.toString() === "Cannot read properties of undefined (reading 'info')")
            {
                errDescription = "Apologies, please hit 'generate' again.";
            }
            else
            {
                errDescription = 'Error caught!  Problem generating lineups from server. ' + err.message.toString();
            }
            setErrMessage(errDescription);
            setIsLoading(false);
        } finally {
           setIsLoading(false);
        }
    }

/*************************************************************************************/
/*                      USE-EFFECT() AND HTML RETURN                                 */
/*************************************************************************************/

    useEffect (() => {

        // Get query params to know what slate was selected
        const queryParams = new URLSearchParams(window.location.search);
        const slateID = parseInt(queryParams.get("slateID"));
        const oneVsManyParam = queryParams.get("lineups");

        if (slateID > 0)
        {
            getSlates(slateID); // this is for validation before loading the actual player data on the next step
            setStep(2);
            setShowPrevBtn(true);
          
            if (oneVsManyParam !== null && oneVsManyParam.length > 0)
                { setOneVsMany(oneVsManyParam); }
            else 
                { setOneVsMany("One"); } // Default to 'One' lineup if this variable isn't passed

            /* Now time to load most of the master player and also slate data from Azure CSV files -- See step summary comments up top for details */
            getSlatePlayerData(slateID); 
            getMasterPlayerData(); 
        }
        else // index is -1 which means user somehow navigatd to an inactive slate
        {
            setStep(1);
            setShowPrevBtn(true);
            setShowNextBtn(false);
            setErrMessage('Error! Slate not found. Hit "Back" and try again or contact support.');
        }         
       
    }, []
    );

    return (
        <div className="content">
        <div className = "home">
             {
            (step===2) && <br></br>
             }
            {
            (step===2) &&
                <h3>Min Salary
                    <Tooltip
                        title="% of site's salary cap"
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                </h3>
            }
             {
            (step===2) &&
                <Slider id="minSalarySlider" defaultValue={95} minValue={85} maxValue={100} stepAmount={0.5}
                value={salaryMin}
                onChange={handleChangeMinSalary}
                charDisplay="%"
                >
                </Slider>
            }
       {
            (step===2 && oneVsMany === "Many") &&
                <br></br>
            }
            {
            (step===2 && oneVsMany === "Many") &&
                <h3>Number of Lineups</h3>
            }
            {
            (step===2 && oneVsMany === "Many") &&
                <Slider id="numberOfLineups" defaultValue={fullSinglLineupGenerate} minValue={2} maxValue={150} stepAmount={1}
                value={numbOfLineups}
                onChange={handleChangeNumbOfLineups}
                charDisplay=""
                >
                </Slider>
            }
           {(step===4) && displayOneLineup && showNextBtnSingleLineup &&
                <div className="div-flexRight">
                    <button className="button" onClick={handleNextSingleLineup}>Next Lineup</button>
                 </div>
            }
            {(step===4) && displayOneLineup && 
             <br></br>
            }
            {(step===4) && displayOneLineup && successMessage &&
             <br></br>
            }
            {errMessage&& (
                <p className="error"> {errMessage} </p>
                )
            }
             {successMessage&& (
                <p className="success"> {successMessage} </p>
                )
            }
            {successMessage && (step===4) && displayOneLineup && 
            <br></br>
            }
            {warningMessage&& (
                <p className="warning"> {warningMessage} </p>
                )
            }
            {isLoading&& (
                <p className="loading"> Loading... </p>
                )
            }
            {
            (step===3) && !isLoading &&
                <div className='flex'> 
                <div className="div-flexLeft">
                    <h3>Players
                    <Tooltip
                        title="Rating is the likelihood the player will be in a lineup.  Players with no stars will be excluded from the result set."
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                    </h3>
                </div>
                <div className="div-flexRightGenerate">
                <button className="button" onClick={handleGenerateClick}>Generate</button><br></br></div>
                </div>
            }
            {
            (step===3) &&
                <ButtonGroup>
                    {positionTypes.map(type => (
                    <Button
                        key={type}
                        activePositionChoice={activePositionChoice === type}
                        onClick={() => handleClickPosChoice(type)}
                        >
                            {type}
                    </Button>
                    ))}
                </ButtonGroup>
            }
            {
            (step===3) &&
                <PGAFullPlayerList players={playerPool.filter((player) => player.Position === activePositionChoice)} title="Players" handleDelete={handleDeletePlayer} setRatingValue = {setRatingValue} getRating = {getRating} getImage = {getImage} getInjuryStatus = {getInjuryStatus} setLockValue = {setLockValue} getLock = {getLock} getPlayerStat = {getPlayerStat}></PGAFullPlayerList>
            }
             {
            (step===3) &&
            <br></br>
             }
             {(step===4) && displayPlayerSummary && 
                <PGAFullLineupList lineups={lineupPlayerSummary} lineupExport={lineupExport} getImage = {getImage} sport={sport} site={siteOfSlate} lineupCount={numbOfLineups}></PGAFullLineupList>
            }

            {(step===4) && displayOneLineup && 
             <br></br>
            }
             {(step===4) && displayOneLineup && 
                <PGAFullSingleLineup players={lineupExport} lineupCounter={oneLineupCounter} getImage = {getImage} getInjuryStatus = {getInjuryStatus} sport={sport} site={siteOfSlate} getLockByNameAndID={getLockByNameAndID}></PGAFullSingleLineup>
            }

             {(step===2 || step===4) &&
             <br></br>
            }
            
            {
            showPrevBtn &&
                <div className="div-flexLeft">
                <button className="button" onClick={handlePreviousClick}>Back</button>
                </div>
                }
            {
            showNextBtn &&
                <div className="div-flexRight">
                <button className="button" onClick={handleNextClick}>Next</button>
                </div>
            }
             {(step===4) && displayOneLineup && showNextBtnSingleLineup &&
                <div className="div-flexRight">
                    <button className="button" onClick={handleNextSingleLineup}>Next Lineup</button>
                 </div>
            }
        </div>
        </div>
    );
}
export default HomePGAFull;