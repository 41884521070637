import React from 'react';
import { useState, useEffect } from 'react';
import NFLFullPlayerList from './NFLFullPlayerList';
import NFLFullLineupList from './NFLFullLineupList';
import NFLFullSingleLineup from './NFLFullSingleLineup.js';
import Checkbox from './Checkbox';
import Select from 'react-select';
import Slider from './Slider';
import styled from 'styled-components';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} 
    from 'react-accessible-accordion';
import Tooltip from "@material-ui/core/Tooltip";
import Cookies from 'js-cookie';
// import { ForkLeftTwoTone } from '@mui/icons-material';

/*************************************************************************************/
/*                      SET ENVIRONMENT VARIABLES                                    */
/*************************************************************************************/

const sport = 'NFL'; // this .js file is this sport
const positionTypes = ['QB', 'RB', 'WR', 'TE', 'DST']; // for player rating
const fullSinglLineupGenerate = 25; // If "one lineup at a time" is chosen, we need to grab X number of lineups behind scenes. This constant is "X".

var injuryAPI = '';
var defenseAPI = '';
var templateAPI = '';
var lineupAPI = '';
var playerAPI = '';
var slateAPI = '';
var slatesAPI = ''; 
var boolLogMissingInjuryPlayers = false;

if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
{
    injuryAPI = process.env.REACT_APP_UAT_API_INJURY;
    defenseAPI = process.env.REACT_APP_UAT_API_DEFENSE;
    templateAPI = process.env.REACT_APP_UAT_API_TEMPLATES;
    playerAPI = process.env.REACT_APP_UAT_API_PLAYER;
    slateAPI = process.env.REACT_APP_UAT_API_SLATE;
    slatesAPI = process.env.REACT_APP_UAT_API_SLATES;
    lineupAPI = process.env.REACT_APP_UAT_API_NFLFULLLINEUP;
    if (process.env.REACT_APP_UAT_LOG_MSSINGINJURYPLAYERS.toUpperCase() === 'TRUE')
        {boolLogMissingInjuryPlayers = true;}
}
else if (process.env.REACT_APP_ENVIRONMENT === 'PROD')
{
    injuryAPI = process.env.REACT_APP_PROD_API_INJURY;
    defenseAPI = process.env.REACT_APP_PROD_API_DEFENSE;
    templateAPI = process.env.REACT_APP_PROD_API_TEMPLATES;
    playerAPI = process.env.REACT_APP_PROD_API_PLAYER;
    slateAPI = process.env.REACT_APP_PROD_API_SLATE;
    slatesAPI = process.env.REACT_APP_PROD_API_SLATES;
    lineupAPI = process.env.REACT_APP_PROD_API_NFLFULLLINEUP;
    if (process.env.REACT_APP_PROD_LOG_MSSINGINJURYPLAYERS.toUpperCase() === 'TRUE')
        {boolLogMissingInjuryPlayers = true;}
}

const Button = styled.button`
background-color: #83a9ff;
border: none;
color: white;
text-align: center;
text-decoration: none;
display: inline;
font-size: 16px;
font-family: Quicksand;
border-radius: 0px;
cursor:pointer;

${({ activePositionChoice }) =>
activePositionChoice &&
`
background-color: #1e90ff;
opacity: 1;
`}
`;

const ButtonGroup = styled.div`
display: flex;
`;

const HomeNFLFull = () => {

/*************************************************************************************/
/*                      SET USE STATE VARIABLES                                      */
/*************************************************************************************/
   
    /*Steps to Hide/Show modules */
    const [step, setStep] = useState(2);
    const [showNextBtn, setShowNextBtn] = useState(false);
    const [showPrevBtn, setShowPrevBtn] = useState(false);
    const [showNextBtnSingleLineup, setShowNextBtnSingleLineup] = useState(false);

    /* Core slate attributes - loaded in useEffect in step 2 */
    const [siteOfSlate, setSiteOfSlate] = useState(""); // DK or FD
    const [typeOfSlate, setTypeOfSlate] = useState("classic"); // eg "classic"
    const [slateInfo, setSlateInfo] = useState([]); // all the slate info for the slateID passed in query string (from MySQL table)
    const [oneVsMany, setOneVsMany] = useState("One"); // default to "one" - bulk is more niche

    /*Error/warning messages */
    const [errMessage,setErrMessage]=useState('');
    const [warningMessage,setWarningMessage]=useState('');
    const [successMessage,setSuccessMessage]=useState('');
    const [isLoading, setIsLoading] = useState(false);

    /*Flex checkboxes */
    const [checkedFlexOne, setCheckedFlexOne] = useState(true);
    const [checkedFlexTwo, setCheckedFlexTwo] = useState(true);
    const [checkedFlexThree, setCheckedFlexThree] = useState(true);

    /*Salary Slider */
    const [salaryMin, setSalaryMin] = useState(95);

    /*# Lineups Slider */
    const [numbOfLineups, setNumbOfLineups] = useState(fullSinglLineupGenerate);

    /* Additional options */
        /*Stack checkboxes */
    const [checkedStackRB, setCheckedStackRB] = useState(false);
    const [checkedStackWR, setCheckedStackWR] = useState(false);
    const [checkedStackTE, setCheckedStackTE] = useState(false);
    const [checkedStackDEF, setCheckedStackDEF] = useState(false);
    const [checkedMiniStackRB, setCheckedMiniStackRB] = useState(false);
    const [checkedMiniStackWR, setCheckedMiniStackWR] = useState(false);
    const [checkedMiniStackTE, setCheckedMiniStackTE] = useState(false);
    const [checkedPrimaryStackBoost, setCheckedPrimaryStackBoost] = useState(false); 
         /*Run back checkboxes */
    const [checkedRunBackRB, setCheckedRunBackRB] = useState(false);
    const [checkedRunBackWR, setCheckedRunBackWR] = useState(false);
    const [checkedRunBackTE, setCheckedRunBackTE] = useState(false);
         /*Exclude players against Defense */
    const [checkedExcludeVsDef, setCheckedExcludeVsDef] = useState(false);
         /*RB/DST Stack Boost */
    const [checkedRBandDSTboost, setCheckedRBandDSTboost] = useState(false);
    const [countMaxPlayers, setCountMaxPlayers] = useState(4);

    /* RATING / RANK stuff  */
          /*Include / Exclude min salary players (not DST) to speed up */
    const [checkedAddRemoveMinPlayers, setCheckedAddRemoveMinPlayers] = useState(false);
    const [activePositionChoice, setActivePositionChoice] = useState(positionTypes[0]);
    const [tempCounter, setTempCounter] = useState(1);
    const [ddlTeams, setDdlTeams] = useState([]);
    const [ddlFilteredTeams, setDdlFilteredTeams] = useState([]);
    const [ddlTemplates, setDdlTemplates] = useState([]);
    const [quickRankUserWarning, setQuickRankUserWarning] = useState(false);

    /* Get injury news and mapping data to update player statuses */
    const [injuryInfo,setInjuryInfo]=useState([]);

    /* GENERATE LINEUPS CLICK */
    const [lineupExport,setLineupExport]=useState([]);
    const [lineupPlayerSummary,setLineupPlayerSummary]=useState([]);
    const [emptyArray, setEmptyArray] = useState([]);
    const [displayPlayerSummary, setDisplayPlayerSummary] = useState(false);
    const [displayOneLineup, setDisplayOneLineup] = useState(false);
    const [oneLineupCounter, setOneLineupCounter] = useState(0);
    const [betaPassword, setBetaPassword] = useState('');
    const [requiredPassword, setRequiredPassword] = useState(false);

    /* IMPORT CSVs and JS data files */
    const [finalPlayerPool,setFinalPlayerPool]=useState([]); // final player list of *only* rated players user has clicked
    const [playerPool,setplayerPool]=useState([]);         // initial slate load - excludes min salary
    const [playerPoolFull,setplayerPoolFull]=useState([]); // initial slate load - includes min salary
    const [masterPlayerInfo,setMasterPlayerInfo] = useState([]); // master player info for sport at heand
    const [defenseRank, setDefenseRank] = useState([]);

    /* Template stuff */
    const [userUniqueIdentifier, setUserUniqueIdentifier] = useState(0); /* Cookie for template display */
    const [ddlTemplateSelected, setDdlTemplateSelected] = useState(null);
    const [loadPlayerRatingsOffTemplate, setLoadPlayerRatingsOffTemplate] = useState(false);
/*************************************************************************************/
/*                      HANDLE NEXT/PREVIOUS BUTTONS                                 */
/*************************************************************************************/

/* IMPORTANT:
    step 1 - Home.js (where they choose slate and 'one vs many')
    step 2 - Configuration settings (e.g. salary slider, stacking)
            Upon load:   getSlatePlayerData(), getMasterPlayerData() & getdefenseRank(); 
                    -- fills setMasterPlayerInfo, setPlayerPoolFull and setDefenseRank
    step 3 - Player rating/lock info
            Upon load:   getInjuryInfo(), transformMasterPlayerData(), fillTeamsDDL() & filterSlateMinSalaries(); 
                    -- fills setInjuryInfo, updates setMasterPlayerInfo and fills setplayerPool (which excludes min salary players)
    step 4 - Results - Bulk summary/export or individual lineup display/management
    */

    const handleNextClick = () => {
       if (step === 2)
        {
            stepThreeTasks();
        }
    }

    const stepThreeTasks = () => {
        clearAllMessages();

        if (slateInfo.length > 0) // we found the slateID in the MySQL DB
        {
            // Make sure the slate is still active to show
            var tempSlates = [];
            var currentDate = new Date();
            var currentdateUTC = currentDate.toUTCString(); // ALWAYS UTC -- EVERYWHERE!
      
            tempSlates = slateInfo.filter(slate => {return slate.sport === sport && new Date(slate.displaySlateDate) <= Date.parse(currentdateUTC) && new Date(slate.hideSlateDate) >= Date.parse(currentdateUTC)});

            if (tempSlates.length > 0) // slate is found and is valid/active! 
            {
                setStep(3);
                setShowNextBtn(false); // because it's replaced with the 'generate' button

                getInjuryInfo();
                transformMasterPlayerData(); // raw data loaded on previous step, now we need to match it to either DK or FD player data
                if (loadPlayerRatingsOffTemplate === true)
                {
                    applyTemplateSettings("players", ddlTemplateSelected.value.toString());
                    setLoadPlayerRatingsOffTemplate(false); // after you load initially from template, don't do it again unless user explicitely changes templates
                }
                filterSlateMinSalaries(); // raw data loaded on previous step, now we transform for DK/FD and such
                fillTeamsDDL(); // grab unique teams from slate to show as filter
            }
            else
            {
                setErrMessage('Error! Slate has expired. Hit "Back" and try again or contact support.');
            }
        }
        else
        {
            setErrMessage('Error! Slate does not exist any longer. Hit "Back" and try again or contact support.');
        }
    }

    const handlePreviousClick = () => {
        if (step === 1) // Only for fatal errors
        {
            window.location.replace('./');
        }
        else if (step === 2)
        {
            if (window.confirm('You may lose all your settings and ratings if you go back. Is that OK?'))
            {
                window.location.replace('./');
            }
        }
        else if (step === 3)
        {
            setStep(2);
            setShowNextBtn(true);
        }
        else if (step === 4)
        {
            setStep(3);
            setShowNextBtn(false);
            setDdlFilteredTeams(emptyArray);
            ddlTeamChangeHandler(emptyArray, checkedAddRemoveMinPlayers);
        }
        
        clearAllMessages();
        setDisplayPlayerSummary(false);
        setDisplayOneLineup(false);
        setShowNextBtnSingleLineup(false);
    }

    const clearAllMessages = () => {
        if (errMessage !== "")
            {setErrMessage("");} 
        if (successMessage !== "")
            {setSuccessMessage("");} 
        if (warningMessage !== "")
            {setWarningMessage("");}   
    }

/*************************************************************************************/
/*                      LOAD PLAYER/SLATE INFO                                       */
/*************************************************************************************/
/* Get the specific slate at hand (from step 1) */
const getSlates = async (slateID) => { 

    // var slateDetails = slates.find(slate => {return slate.value === slateSelected});
    try {
        const responseSlate = await fetch(slatesAPI, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'sport': sport, // sport,
                'slateid': slateID, // slateSelected,
            }
        });
        const result = await responseSlate.json();

        if (responseSlate.ok) {
            if (result[0] !== undefined)
            {
                var tempSlates = result[0];
                //tempSlates = tempSlates.sort((a, b) => Number(a.order) - Number(b.order));
                setSlateInfo(tempSlates);
                setSiteOfSlate(tempSlates[0].site);
                setTypeOfSlate(tempSlates[0].slateType);
            }
        }
    } catch (err) {
        console.log('Error caught!  Problem getting slates - ' + err.message);
    } finally {
    }
}

    // this gets raw slate player data from Azure for the slate we're on
    // we'll transform/cleanse this data on the next step
    const getSlatePlayerData = async(slateID) => {
      
        //var slateDetails = slateInfo.find(slate => {return slate.value === slateSelected});
        
        try {
            const responseSlateInfo = await fetch(slateAPI, {
                   method: 'GET',
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'sport': sport,
                       'slateid': slateID,
                   }
               });
            const result = await responseSlateInfo.json();

            if (responseSlateInfo.ok) {
                if (result[0] !== undefined)
                {
                    setplayerPoolFull(result); // this is *all* available players in slate - including min salary players
                    return result.length;
                }
            }
        } catch (err) {
            console.log('Critical error!  Problem getting raw slate data for ' + sport + ' for the slateID: ' + slateID.toString() + '. Error:' + err.message);
            setErrMessage('Critical error!  Failed getting slate data. Contact support or try again. Error: ' + err.message);
        }
        return 0;
    }

    const filterSlateMinSalaries = () => { 
        
        var tempPlayers = "";
        if (siteOfSlate === "DK")
        {
            tempPlayers = playerPoolFull.filter(
                player => (
                        (player.Salary > 4000 && (player.Position === 'QB' || player.Position === 'RB'))
                        ||
                        (player.Salary > 3000 && player.Position === 'WR')
                        ||
                        (player.Salary > 2500 && player.Position === 'TE')
                        ||
                        (player.Salary > 1 && player.Position === 'DST')
                    )
            )
        }
        else if (siteOfSlate === "FD")
        {
            tempPlayers = playerPoolFull.filter(
                player => (
                            (player.Salary > 6000 && player.Position === 'QB')
                            ||
                            (player.Salary > 4000 && (player.Position === 'WR' || player.Position === 'RB' || player.Position === 'TE'))
                            ||
                            (player.Salary > 1 && player.Position === 'DST')
                        )
                )
        }
        
        setplayerPool(tempPlayers);
    }

    const fillTeamsDDL = () => { 
        var tempJSONdata = [];

        for (var i = 0; i<playerPoolFull.length; i++)
        {
            var tempTeamName = playerPoolFull[i]["TeamAbbrev"];
            let tempDDLTeamsObject = tempJSONdata.findIndex(obj2 => obj2.label === tempTeamName);
         
            if (tempDDLTeamsObject === -1)
            {
                tempJSONdata.push({
                    value: i,
                    label: tempTeamName
                });
            }
        }
     
        tempJSONdata.sort(function (a, b) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          //console.log(tempJSONdata);
        setDdlTeams(tempJSONdata);
    }
    
    const ddlTeamChangeHandler = (value, bShowMinSalaries) => { 
        var filteredX = [];

        // this function gets called when user adds/removes teams in filter *AND* when the "include non-starters" is checked/un-checked
        //      In case 1, we don't have a 2nd param variable, so set it to useState for the "include non-starter" checkbox
        //      In case 2, we have a 2nd param... and it's the value the user just changed the "include nonstarter" checkbox to
        //          the reason we don't look at the use-state here is that it hasn't been updated yet, so we lean on passing it into this functoin
        if (bShowMinSalaries !== true && bShowMinSalaries !== false)
            {bShowMinSalaries = checkedAddRemoveMinPlayers;}

        if (value.length === 0) // filter has been cleared, show all teams!
        {
            if (bShowMinSalaries === false) // do *not* include non starters
            {
                filterSlateMinSalaries();
                //filteredX = playerPoolFull.filter(itemX => itemX.lineupOrder >= 0);    
            }
            else // include non starters
            {
                filteredX = playerPoolFull; 
                setplayerPool(filteredX);
            }
        }
        else  // we are filtering by team(s)
        {
            // Use map to get a simple array of team values. Ex: ['BOS', 'CLE', 'CHC']
            let teamFilter = value.map(itemY => { return itemY.label; });

            // Now filter on the team filter (and also look to see if we should be showing non-starters)
            if (bShowMinSalaries === false) // do *not* include non starters
            {
                //filteredX = playerPoolFull.filter(itemX => teamFilter.includes(itemX.TeamAbbrev) && itemX.lineupOrder >= 0); 
                    if (siteOfSlate === "DK")
                    {
                        filteredX = playerPoolFull.filter(
                            player => (
                                    teamFilter.includes(player.TeamAbbrev)
                                    &&
                                    (
                                        (player.Salary > 4000 && (player.Position === 'QB' || player.Position === 'RB'))
                                        ||
                                        (player.Salary > 3000 && player.Position === 'WR')
                                        ||
                                        (player.Salary > 2500 && player.Position === 'TE')
                                        ||
                                        (player.Salary > 1 && player.Position === 'DST')
                                    )
                                )
                        )
                    }
                    else if (siteOfSlate === "FD")
                    {
                        filteredX = playerPoolFull.filter(
                            player => (
                                    teamFilter.includes(player.TeamAbbrev)
                                    &&
                                    (
                                        (player.Salary > 6000 && player.Position === 'QB')
                                        ||
                                        (player.Salary > 4000 && (player.Position === 'WR' || player.Position === 'RB' || player.Position === 'TE'))
                                        ||
                                        (player.Salary > 1 && player.Position === 'DST')
                                    )
                                )
                        )
                    }
                setplayerPool(filteredX);
            }
            else // include non starters
            {
                filteredX = playerPoolFull.filter(itemX => teamFilter.includes(itemX.TeamAbbrev)); 
                setplayerPool(filteredX);
            }
        }

        setDdlFilteredTeams(value);
    };

    // this gets raw master player data from Azure for the sport we're on
    // we'll transform/cleanse this data on the next step
    const getMasterPlayerData = async() => {
        
        try {
            const responseMasterPlayer = await fetch(playerAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                }
            });
            const result = await responseMasterPlayer.json();

            if (responseMasterPlayer.ok) {
                if (result[0] !== undefined)
                {
                    setMasterPlayerInfo(result);
                }
            }

        } catch (err) {
            console.log('Critical error!  Problem getting player data for ' + sport + '. Error:' + err.message);
            setErrMessage('Critical error!  Failed getting player data. Contact support or try again. Error: ' + err.message);
        } finally {
            setShowNextBtn(true);
        }
    }
    
    // this takes the master player data (for sport we're on) from the previous step and transforms it to fit DK or FD
    const transformMasterPlayerData = () => { 
        var jsonDataClean = masterPlayerInfo;
        var tempJSONdata = [];

        if (siteOfSlate === 'DK')
        {
            for (var i = 0; i<jsonDataClean.length; i++)
            {
                var obj = jsonDataClean[i];

                if (obj['PlayerDKName'].length > 0)
                {
                    obj['NameTeam'] = obj['PlayerDKName'] + ' (' + obj['PlayerTeam'] + ')';
                }
                else
                {
                    obj['NameTeam'] = obj['PlayerName'] + ' (' + obj['PlayerTeam'] + ')';
                }

                if (obj['PlayerInjuryName'].length > 0)
                {
                    
                    obj['NameTeamInjury'] = obj['PlayerInjuryName'] + ' (' + obj['PlayerTeam'] + ')';
                }
                else
                {
                    obj['NameTeamInjury'] = obj['PlayerName'] + ' (' + obj['PlayerTeam'] + ')';
                }

                tempJSONdata.push(obj);
            }
            jsonDataClean = tempJSONdata;
        }
        else if (siteOfSlate === 'FD')
        {
            for (var j = 0; j<jsonDataClean.length; j++)
            {
                var obj2 = jsonDataClean[j];

                if (obj2['PlayerFDName'].length > 0)
                {
                    obj2['NameTeam'] = obj2['PlayerFDName'] + ' (' + obj2['PlayerTeam'] + ')';
                }
                else
                {
                    obj2['NameTeam'] = obj2['PlayerName'] + ' (' + obj2['PlayerTeam'] + ')';
                }

                if (obj2['PlayerInjuryName'].length > 0)
                {
                    obj2['NameTeamInjury'] = obj2['PlayerInjuryName'] + ' (' + obj2['PlayerTeam'] + ')';
                }
                else
                {
                    obj2['NameTeamInjury'] = obj2['PlayerName'] + ' (' + obj2['PlayerTeam'] + ')';
                }

                tempJSONdata.push(obj2);
            }
            jsonDataClean = tempJSONdata;
        }
        setMasterPlayerInfo(jsonDataClean);
    }

    const generateUniqueIdentifier = () => {
        const now = new Date();

        // Extract year, month, day, hour, minute, and second
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hour = String(now.getHours()).padStart(2, '0');
        const minute = String(now.getMinutes()).padStart(2, '0');
        const second = String(now.getSeconds()).padStart(2, '0');

        // Generate a random 4-digit integer
        const randomInt = Math.floor(1000 + Math.random() * 9000);

        // Combine all parts into a unique identifier
        return `${year}${month}${day}${hour}${minute}${second}${randomInt}`;
    };

/*************************************************************************************/
/*                      HANDLE CONFIGURATION UI CONTROLS                             */
/*************************************************************************************/
    
    const handleChangeFlexOne = () => {
        setCheckedFlexOne(!checkedFlexOne);
        if (errMessage === "Error!  Choose at least 1 Flex Position above")
            {setErrMessage("");}
      };
    
    const handleChangeFlexTwo = () => {
        setCheckedFlexTwo(!checkedFlexTwo);
        if (errMessage === "Error!  Choose at least 1 Flex Position above")
            {setErrMessage("");}
      };

    const handleChangeFlexThree = () => {
        setCheckedFlexThree(!checkedFlexThree);
        if (errMessage === "Error!  Choose at least 1 Flex Position above")
            {setErrMessage("");}
      };

    const handleChangeMinSalary = (index) => {
        setSalaryMin(index);
        if (errMessage === "Error!  Min Salary must be 99% or less" && index <= 99)
            {setErrMessage("");}
      };

    const handleChangeNumbOfLineups = (index) => {
        setNumbOfLineups(index);
      };
   
    const handleChangeCheckedStackRB = () => {
        setCheckedStackRB(!checkedStackRB);
      };
    
    const handleChangeCheckedStackWR = () => {
        setCheckedStackWR(!checkedStackWR);
      };

    const handleChangeCheckedStackTE = () => {
        setCheckedStackTE(!checkedStackTE);
      };

    const handleChangeCheckedStackDEF = () => {
        setCheckedStackDEF(!checkedStackDEF);
      };

    const handleChangeCheckedPrimaryBoost = () => {
        setCheckedPrimaryStackBoost(!checkedPrimaryStackBoost);
      };

    const handleChangeCheckedMiniStackRB = () => {
        setCheckedMiniStackRB(!checkedMiniStackRB);
      };
    
    const handleChangeCheckedMiniStackWR = () => {
        setCheckedMiniStackWR(!checkedMiniStackWR);
      };

    const handleChangeCheckedMiniStackTE = () => {
        setCheckedMiniStackTE(!checkedMiniStackTE);
      };

    const handleChangeCheckedRunBackRB = () => {
        setCheckedRunBackRB(!checkedRunBackRB);
      };
    
    const handleChangeCheckedRunBackWR = () => {
        setCheckedRunBackWR(!checkedRunBackWR);
      };

    const handleChangeCheckedRunBackTE = () => {
        setCheckedRunBackTE(!checkedRunBackTE);
      };

    const handleChangeCheckedExcludeVsDef = () => {
        setCheckedExcludeVsDef(!checkedExcludeVsDef);
      };

    const handleChangeCheckedRBandDSTboost = () => {
        setCheckedRBandDSTboost(!checkedRBandDSTboost);
      };

    function incrementCount() {
        let tempCount = countMaxPlayers
        if (tempCount < 4)
            {tempCount = tempCount + 1;}
        setCountMaxPlayers(tempCount);
    }

    function decrementCount() {
        let tempCount = countMaxPlayers
        if (tempCount > 1)
            {tempCount = tempCount - 1;} 
        setCountMaxPlayers(tempCount);
    }

/*************************************************************************************/
/*                      HANDLE PLAYER RATINGS & IMAGES                               */
/*************************************************************************************/

    const handleDeletePlayer = (id) => {
        // player pool the user sees/ranks
        const newPlayers = playerPool.filter(player => player.ID !== id);
        setplayerPool(newPlayers);

        // player pool behind scenes w/ their exposure choices
        const newPlayers2 = finalPlayerPool.filter(player => player.ID !== id);
        setFinalPlayerPool(newPlayers2);
    }

    // this is for position sub-header for player list view/rankings
    const handleClickPosChoice = (name, e) => {
        setActivePositionChoice(name);
    }

    // this is for removing all min salary players for performance reasons
    const handleAddRemoveMinPlayers = (e) => {    
   
        const fullPlayers = playerPoolFull;

        if (checkedAddRemoveMinPlayers === false)
            {setplayerPool(fullPlayers); }       
        else
        {
            filterSlateMinSalaries();
        }
        setCheckedAddRemoveMinPlayers(!checkedAddRemoveMinPlayers);
        ddlTeamChangeHandler(ddlFilteredTeams, !checkedAddRemoveMinPlayers); // now filter the changed display set to what the filter was when they clicked to add or remove non-starters
    }

    const getRating = (id) => {
        return playerPool.find(player => {return player.ID === id}).exposure;
    }

    const getRatingImage = (id) => {
       let ratingImage = '';
     
       if (id.length >= 7)
       {
            if (id.slice(0,7) === "1 Stars")
                {ratingImage = "Stars/Stars_1.png";}
            else if (id.slice(0,7) === "2 Stars")
                {ratingImage = "Stars/Stars_2.png";}
            else if (id.slice(0,7) === "3 Stars")
                {ratingImage = "Stars/Stars_3.png";}
            else if (id.slice(0,7) === "4 Stars")
                {ratingImage = "Stars/Stars_4.png";}
            else if (id.slice(0,7) === "5 Stars")
                {ratingImage = "Stars/Stars_5.png";}
            else 
            {
                console.log('prob: ' + id.toString());
            }
        }
        else if (id === "Lock")
        {
            ratingImage = "Lock.png";
        }

       return ratingImage;
    }

    const getRatingImageClass = (id) => {
        let ratingImageClass = '';
      
        if (id.length >= 7)
        {
            ratingImageClass = "lineup-item-bulkLineup-stars";
         }
         else if (id === "Lock")
         {
            ratingImageClass = "LockImageResponsive";
         }
 
        return ratingImageClass;
     }

    const getStack = (id) => {
        return playerPool.find(player => {return player.ID === id}).stack;
    }

    const getLock = (id) => {
        return playerPool.find(player => {return player.ID === id}).lock;
    }

    const getLockByNameAndID = (id) => {
        //id=id.replace("'", ""); // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
        let lockStatus = '';
     
       if (playerPool.find(player => {return player['Name + ID'] === id}) !== undefined)
            {
                if (playerPool.find(player => {return player['Name + ID'] === id}).lock === true)
                {
                    lockStatus = 'Lock';
                }
            }
      
       return lockStatus;
    }

    const setRatingValue = (id, newRating) => {

        const tempPlayers = playerPool; 
        let tempPlayer = playerPool.find(player => {return player.ID === id}); 
        const tempPlayersFull = playerPoolFull;
        let tempPlayerFull = playerPoolFull.find(player => {return player.ID === id});

        clearAllMessages();

        if (newRating === null) // if user un-ranks a player
            {newRating = 0;}  // API/proc should ignore un-ranked players
        
        if (tempPlayer.lock === false) // Don't touch if locked, as it's basically a max rating
        {
            // update the player pool the user sees and ranks
            tempPlayer.exposure = newRating;
            let arrayindex = playerPool.findIndex(obj => obj.ID === id);
            tempPlayers[arrayindex] = tempPlayer;

            setplayerPool(tempPlayers);

            // keep teh full set up to date... as the team and 'min salary' filters need such
            tempPlayerFull.exposure = newRating;
            let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === id);
            tempPlayersFull[arrayindexFull] = tempPlayerFull;

            setplayerPoolFull (tempPlayersFull);

            // update the player pool behind the scenes for the final 'generate' click
            const tempPlayers2 = finalPlayerPool; 
            let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
            if (tempPlayer2 !== undefined)
            {
                tempPlayer2.exposure = newRating;
                let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
                tempPlayers2[arrayindex2] = tempPlayer2;
            }
            else
            {
                tempPlayers2.push(tempPlayer);
            }

            setFinalPlayerPool(tempPlayers2);
            // dirty hack to refresh the player pool the user sees
            setTempCounter(tempCounter + 1); // ...  so updating a counter...
        }
    }

    const setStackValue = (id) => {

        // update the player pool the user sees and ranks
        const tempPlayers = playerPool; 
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        const tempPlayersFull = playerPoolFull;
        let tempPlayerFull = playerPoolFull.find(player => {return player.ID === id});
        let newStackValue = false;

        if (tempPlayer.stack === false)
        {
            newStackValue = true;
        }
        tempPlayer.stack = newStackValue;
        tempPlayerFull.stack = newStackValue;

        let arrayindex = playerPool.findIndex(obj => obj.ID === id);
        tempPlayers[arrayindex] = tempPlayer;
        setplayerPool(tempPlayers);

        let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === id);
        tempPlayersFull[arrayindexFull] = tempPlayerFull;
        setplayerPoolFull(tempPlayersFull);

        // update the player pool behind the scenes for the final 'generate' click
        const tempPlayers2 = finalPlayerPool; 
        let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
        if (tempPlayer2 !== undefined)
        {
            tempPlayer2.stack = newStackValue;
            let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
            tempPlayers2[arrayindex2] = tempPlayer2;
        }
        else
        {
            tempPlayers2.push(tempPlayer);
        }

        setFinalPlayerPool(tempPlayers2);
        // dirty hack to refresh the player pool the user sees
        setTempCounter(tempCounter + 1); // ...  so updating a counter...
    }

    const setLockValue = (id) => {

        // update the player pool the user sees and ranks
        const tempPlayers = playerPool; 
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        const tempPlayersFull = playerPoolFull;
        let tempPlayerFull = playerPoolFull.find(player => {return player.ID === id});
        let newLockValue = false;

        if (tempPlayer.lock === false)
        {
            newLockValue = true;
            setRatingValue(id, 5);
        }
        tempPlayer.lock = newLockValue;
        tempPlayerFull.lock = newLockValue;

        let arrayindex = playerPool.findIndex(obj => obj.ID === id);
        tempPlayers[arrayindex] = tempPlayer;
        setplayerPool(tempPlayers);

        let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === id);
        tempPlayersFull[arrayindexFull] = tempPlayerFull;
        setplayerPoolFull(tempPlayersFull);

        // update the player pool behind the scenes for the final 'generate' click
        const tempPlayers2 = finalPlayerPool; 
        let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
        if (tempPlayer2 !== undefined)
        {
            tempPlayer2.lock = newLockValue;;
            let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
            tempPlayers2[arrayindex2] = tempPlayer2;
        }
        else
        {
            tempPlayers2.push(tempPlayer);
        }
        setFinalPlayerPool(tempPlayers2);
        // dirty hack to refresh the player pool the user sees
        setTempCounter(tempCounter + 1); // ...  so updating a counter...
    }

    /* Player Images */
    const getImage = (id) => {
       //id=id.replace("'", "");  // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
       let pictureURL = 'NoPlayerImage.png';

       if (masterPlayerInfo.find(player => {return player.NameTeam === id}) !== undefined)
            {pictureURL = masterPlayerInfo.find(player => {return player.NameTeam === id}).PicURL;}
       if (pictureURL.length === 0)
            {pictureURL = 'NoPlayerImage.png';}
       return pictureURL;
    }

    const getPlayerSearchURL = (sPlayername, sTeam, sPosition) => {

        var playerSearchURL = 'https://www.rotowire.com/search.php?sport=NFL&term=';

        if (sPosition === "DST" || sPosition === "DEF")
        {
            switch(sTeam) {
                case "ARI": playerSearchURL = "https://www.rotowire.com/football/team/arizona-cardinals-ari"; break;
                case "ATL": playerSearchURL = "https://www.rotowire.com/football/team/atlanta-falcons-atl"; break;
                case "BAL": playerSearchURL = "https://www.rotowire.com/football/team/baltimore-ravens-bal"; break;
                case "BUF": playerSearchURL = "https://www.rotowire.com/football/team/buffalo-bills-buf"; break;
                case "CAR": playerSearchURL = "https://www.rotowire.com/football/team/carolina-panthers-car"; break;
                case "CHI": playerSearchURL = "https://www.rotowire.com/football/team/chicago-bears-chi"; break;
                case "CIN": playerSearchURL = "https://www.rotowire.com/football/team/cincinnati-bengals-cin"; break;
                case "CLE": playerSearchURL = "https://www.rotowire.com/football/team/cleveland-browns-cle"; break;
                case "DAL": playerSearchURL = "https://www.rotowire.com/football/team/dallas-cowboys-dal"; break;
                case "DEN": playerSearchURL = "https://www.rotowire.com/football/team/denver-broncos-den"; break;
                case "DET": playerSearchURL = "https://www.rotowire.com/football/team/detroit-lions-det"; break;
                case "GB": playerSearchURL = "https://www.rotowire.com/football/team/green-bay-packers-gb"; break;
                case "HOU": playerSearchURL = "https://www.rotowire.com/football/team/houston-texans-hou"; break;
                case "IND": playerSearchURL = "https://www.rotowire.com/football/team/indianapolis-colts-ind"; break;
                case "JAX": playerSearchURL = "https://www.rotowire.com/football/team/jacksonville-jaguars-jax"; break;
                case "KC": playerSearchURL = "https://www.rotowire.com/football/team/kansas-city-chiefs-kc"; break;
                case "LAC": playerSearchURL = "https://www.rotowire.com/football/team/los-angeles-chargers-lac"; break;
                case "LAR": playerSearchURL = "https://www.rotowire.com/football/team/los-angeles-rams-lar"; break;
                case "LV": playerSearchURL = "https://www.rotowire.com/football/team/las-vegas-raiders-lv"; break;
                case "MIA": playerSearchURL = "https://www.rotowire.com/football/team/miami-dolphins-mia"; break;
                case "MIN": playerSearchURL = "https://www.rotowire.com/football/team/minnesota-vikings-min"; break;
                case "NE": playerSearchURL = "https://www.rotowire.com/football/team/new-england-patriots-ne"; break;
                case "NO": playerSearchURL = "https://www.rotowire.com/football/team/new-orleans-saints-no"; break;
                case "NYG": playerSearchURL = "https://www.rotowire.com/football/team/new-york-giants-nyg"; break;
                case "NYJ": playerSearchURL = "https://www.rotowire.com/football/team/new-york-jets-nyj"; break;
                case "PHI": playerSearchURL = "https://www.rotowire.com/football/team/philadelphia-eagles-phi"; break;
                case "PIT": playerSearchURL = "https://www.rotowire.com/football/team/pittsburgh-steelers-pit"; break;
                case "SEA": playerSearchURL = "https://www.rotowire.com/football/team/seattle-seahawks-sea"; break;
                case "SF": playerSearchURL = "https://www.rotowire.com/football/team/san-francisco-49ers-sf"; break;
                case "TB": playerSearchURL = "https://www.rotowire.com/football/team/tampa-bay-buccaneers-tb"; break;
                case "TEN": playerSearchURL = "https://www.rotowire.com/football/team/tennessee-titans-ten"; break;
                case "WAS": playerSearchURL = "https://www.rotowire.com/football/team/washington-commanders-was"; break;
                default: playerSearchURL = "https://www.rotowire.com/";
            }
        }
        else
        {
            var playerName = sPlayername;
            playerName = playerName.replace(" Jr.", "");
            playerName = playerName.replace(" Sr.", "");
            playerName = playerName.replace(" III", "");
            playerName = playerName.replace(" II", "");
            playerName = encodeURIComponent(playerName.trim());
            playerSearchURL = playerSearchURL + playerName;
        }

        return playerSearchURL;
     }

    /* 3-star quick rank on filtered players */
    const handleQuickRank = (e) => {
        if (quickRankUserWarning === true || window.confirm('This will rate all the un-rated displayed ' + activePositionChoice + 's with 3 stars.  Is that OK?'))
        {
            setQuickRankUserWarning(true);
            var quickRankAmount = 3;
            clearAllMessages();

            var jsonPlayerPool = playerPool;
            var jsonResultPlayerPool = [];
            var jsonPlayerPoolFull = playerPoolFull;
            var jsonPlayerPoolFinal = finalPlayerPool;

            for (var i = 0; i<jsonPlayerPool.length; i++)
            {
                var obj = jsonPlayerPool[i];
                var playerID = obj['ID'].toString();

                if (obj['lock']  === false && obj['exposure'] === 0 && obj['Position'] === activePositionChoice) // Don't touch if locked or already ranked
                {
                    obj['exposure'] = parseInt(quickRankAmount, 10);

                    let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === playerID);
                    jsonPlayerPoolFull[arrayindexFull] = obj;

                    let arrayindexFinal = finalPlayerPool.findIndex(obj => obj.ID === playerID);
                    if (arrayindexFinal !== -1)
                    {
                        jsonPlayerPoolFinal[arrayindexFinal] = obj;
                    }
                    else
                    {
                        jsonPlayerPoolFinal.push(obj);
                    }
                }
                jsonResultPlayerPool.push(obj);
            }
            setplayerPool(jsonResultPlayerPool);
            setplayerPoolFull(jsonPlayerPoolFull);
            setFinalPlayerPool(jsonPlayerPoolFinal);
        }   
   }

/*************************************************************************************/
/*                      HANDLE TEMPLATE LOGIC                                      */
/*************************************************************************************/

const getTemplates = async (slateSelected, userUniqueID, slatePlayerCount) => { 
    // var slateDetails = slates.find(slate => {return slate.value === slateSelected});
    try {
        const responseTemplate = await fetch(templateAPI, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'sport': sport,
                'slatevalue': slateSelected,
                'useruniqueid': userUniqueID.toString(),
                'numberofslateplayers': slatePlayerCount.toString(),
            }
        });
        const result = await responseTemplate.json();

        if (responseTemplate.ok) {
            if (result[0] !== undefined)
            {
                if (result[0].length !== 0)
                {
                    result[0].push({
                        label: "Select...",
                        value: 99999999,
                        rankings: ""
                    });

                    var tempTemplates = result[0];
                    tempTemplates = tempTemplates.sort((a, b) => Number(b.value) - Number(a.value));
                    setDdlTemplates(tempTemplates);
                   
                    const defaultSelected = tempTemplates.find(template => template.value === 99999999);
                    setDdlTemplateSelected(defaultSelected);
                }
            }
        }
    } catch (err) {
        console.log('Error caught!  Problem getting templates for slate ' + slateSelected + '. Error:' + err.message);
    } finally {
    }
}

const ddlTemplateChangeHandler = (e) => { 
    // only do stuff if user actually changes the template here
    if (e.value.toString() !== ddlTemplateSelected.value.toString())
    {
        // If user is changing from template A to template B, warn them
        if (e.value.toString() !== '99999999' && ddlTemplateSelected.value.toString() != '99999999')
        {
            if (window.confirm('By changing rating templates, you will lose any settings or ratings you may have modified. Is that OK?'))
                {
                    setLoadPlayerRatingsOffTemplate(true);
                    setDdlTemplateSelected(e);
                    applyTemplateSettings('settings', e.value.toString());
                }
        }
        // Else if user is changing from no template to a template, just do the thing
        else if (e.value.toString() !== '99999999' && ddlTemplateSelected.value.toString() === '99999999')
        {
            setLoadPlayerRatingsOffTemplate(true);
            setDdlTemplateSelected(e);
            applyTemplateSettings('settings', e.value.toString());
            /* Get rid of 'select...' option now, it's no longer applicable */
            const updatedTemplates = ddlTemplates.filter(template => template.value !== 99999999);
            setDdlTemplates(updatedTemplates);
        }
    }
};

const applyTemplateSettings = (applySettingsType, templateID) => { 
    // applySettingsType is 'all' or 'settings' or 'players'
    if (templateID.toString() !== '99999999')  // If "none" or "select..." is selected, don't do anything
    {
        let tempTemplate = ddlTemplates.find(ddlTemplate => {return ddlTemplate.value.toString() === templateID});
        // STOP!  CAREFUL CHANGING.  THIS, OF COURSE, LOGIC/ORDER NEEDS TO MATCH THE LOGIC THAT WRITES THIS IN 'scrapeTemplateSettings'
        const values =  tempTemplate.rankings.split(',');
        if (applySettingsType.toUpperCase() === 'ALL' || applySettingsType.toUpperCase() === 'SETTINGS')
        {
            const TempFlexRB = values[3] === 'true'; // FlexRB
            setCheckedFlexOne(TempFlexRB);
            const TempFlexWR = values[4] === 'true'; // FlexWR
            setCheckedFlexTwo(TempFlexWR);
            const TempFlexTE = values[5] === 'true'; // FlexTE
            setCheckedFlexThree(TempFlexTE);
            const TempMinSalary = parseFloat(values[6]); // MinSalary
            setSalaryMin(TempMinSalary);
            const TempNumbOfLineups = parseInt(values[7], 10); // NumbOfLineups
            setNumbOfLineups(TempNumbOfLineups);
            const TempQBStackRB = values[8] === 'true'; // QBStackRB
            setCheckedStackRB(TempQBStackRB);
            const TempQBStackWR = values[9] === 'true'; // QBStackWR
            setCheckedStackWR(TempQBStackWR);
            const TempQBStackTE = values[10] === 'true'; // QBStackTE
            setCheckedStackTE(TempQBStackTE);
            const TempQBStackDST = values[11] === 'true'; // QBStackDST
            setCheckedStackDEF(TempQBStackDST);
            const TempDoubleStackBoost = values[12] === 'true'; // DoubleStackBoost
            setCheckedPrimaryStackBoost(TempDoubleStackBoost);
            const TempRunBackRB = values[13] === 'true'; // RunBackRB
            setCheckedRunBackRB(TempRunBackRB);
            const TempRunBackWR = values[14] === 'true'; // RunBackWR
            setCheckedRunBackWR(TempRunBackWR);
            const TempRunBackTE = values[15] === 'true'; // RunBackTE
            setCheckedRunBackTE(TempRunBackTE);
            const TempMiniRB = values[16] === 'true'; // MiniRB
            setCheckedMiniStackRB(TempMiniRB);
            const TempMiniWR = values[17] === 'true'; // MiniWR
            setCheckedMiniStackWR(TempMiniWR);
            const TempMiniTE = values[18] === 'true'; // MiniTE
            setCheckedMiniStackTE(TempMiniTE);
            const TempExcludeAgainstDST = values[19] === 'true'; // ExcludeAgainstDST
            setCheckedExcludeVsDef(TempExcludeAgainstDST);
            const TempRBDSTBoost = values[20] === 'true'; // RBDSTBoost
            setCheckedRBandDSTboost(TempRBDSTBoost);
            const TempMaxPlayers = parseInt(values[21], 10); // MaxPlayers
            setCountMaxPlayers(TempMaxPlayers);
        }

        if (applySettingsType.toUpperCase() === 'ALL' || applySettingsType.toUpperCase() === 'PLAYERS')
        {
            // Extract PlayerRatings and split by |
            var rankingsArray = [];
            rankingsArray = values[22].split('|');
            var jsonDataCleanAutoRank = playerPoolFull;
            var tempJSONdataAutoRank = [];
            var tempfinalplayerPool = [];
    
            for (var i = 0; i<jsonDataCleanAutoRank.length; i++)
            {
                var obj = jsonDataCleanAutoRank[i];

                //console.log("i: " + i.toString() + " autorank: " + rankingsArray[i].toString() + " for player: " + obj['Name']);
                if (rankingsArray !== null)
                {
                    if (rankingsArray[i].length > 0)
                    {
                        obj['exposure'] = parseInt(rankingsArray[i].replace("S", "").replace("L", ""), 10);
                        if (parseInt(rankingsArray[i].replace("S", "").replace("L", ""), 10) > 0)
                        {   
                        
                            if (rankingsArray[i].length > 1)
                            {
                                if (rankingsArray[i].substring(1, 2).toString() === "L")
                                {
                                    obj['lock'] = true;
                                }
                                else if (rankingsArray[i].substring(1, 2).toString() === "S")
                                {
                                    obj['stack'] = true;
                                }
                            }
                            tempfinalplayerPool.push(obj);
                        }
                    } 
                    else{
                         obj['exposure'] = 0;
                    }
                }
                else{
                        obj['exposure'] = 0;
                }
                        
                tempJSONdataAutoRank.push(obj);
            }

            jsonDataCleanAutoRank = tempJSONdataAutoRank;
            setplayerPoolFull(jsonDataCleanAutoRank);
            setFinalPlayerPool(tempfinalplayerPool);
        }
    }
}

const scrapeTemplateSettings = () => {
    // Get the current date and time in GMT and format it to minute precision
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hour = String(now.getUTCHours()).padStart(2, '0');
    const minute = String(now.getUTCMinutes()).padStart(2, '0');

    const fullPlayerPoolCount = playerPoolFull.length;
    // Construct the dateTime in "YYYY-MM-DDTHH:MMZ" format
    const dateTime = `${year}-${month}-${day}T${hour}:${minute}Z`;
    const userID = userUniqueIdentifier;
    const flexRB = checkedFlexOne;
    const flexWR = checkedFlexTwo;
    const flexTE = checkedFlexThree;
    const minSalary = salaryMin;
    const numberOfLineups = numbOfLineups;
    const qbStackRB = checkedStackRB;
    const qbStackWR = checkedStackWR;
    const qbStackTE = checkedStackTE;
    const qbStackDST = checkedStackDEF;
    const doubleStackBoost = checkedPrimaryStackBoost;
    const runBackRB = checkedRunBackRB;
    const runBackWR = checkedRunBackWR;
    const runBackTE = checkedRunBackTE;
    const miniRB = checkedMiniStackRB;
    const miniWR = checkedMiniStackWR;
    const miniTE = checkedMiniStackTE;
    const excludeAgainstDST = checkedExcludeVsDef;
    const rbDSTBoost = checkedRBandDSTboost;
    const maxPlayers = countMaxPlayers;
    const playerRankings = scrapeTemplatePlayers();

    // STOP!  CAREFUL CHANGING.  THIS, OF COURSE, LOGIC/ORDER NEEDS TO MATCH THE LOGIC THAT READS THIS IN 'applyTemplateSettings'
    return `${fullPlayerPoolCount},${dateTime},${userID},${flexRB},${flexWR},${flexTE},${minSalary},${numberOfLineups},${qbStackRB},${qbStackWR},${qbStackTE},${qbStackDST},${doubleStackBoost},${runBackRB},${runBackWR},${runBackTE},${miniRB},${miniWR},${miniTE},${excludeAgainstDST},${rbDSTBoost},${maxPlayers},${playerRankings}`;
}

/* Export the user's player rankings to the console */
const scrapeTemplatePlayers = () => {
    const tempPlayersFull = playerPoolFull;
    var rankingsString = "";
    var i2 = 0;

    while (i2 < tempPlayersFull.length)
    {
        if (tempPlayersFull[i2].exposure !== undefined)
        {
            if (tempPlayersFull.length !== (i2 + 1)) // if *not* last player in the list
            {
                if (tempPlayersFull[i2].exposure.length === 0 || tempPlayersFull[i2].exposure.toString() === "0")
                {
                    rankingsString = rankingsString + "|"; // i don't put the 0 in order to save space.. when extracting this string later, if no value is found, it's assumed a 0
                }
                else
                {
                    if (tempPlayersFull[i2].lock === true)
                    {
                        rankingsString = rankingsString + tempPlayersFull[i2].exposure.toString() + "L|";
                    }
                    else if (tempPlayersFull[i2].stack === true)
                    {
                        rankingsString = rankingsString + tempPlayersFull[i2].exposure.toString() + "S|";
                    }
                    else
                    {
                        rankingsString = rankingsString + tempPlayersFull[i2].exposure.toString() + "|";
                    }
                }
            }
            else if ((tempPlayersFull[i2].exposure.length === 0 || tempPlayersFull[i2].exposure.toString() === "0") && tempPlayersFull.length === (i2 + 1))
            {
                rankingsString = rankingsString + "0";
            } 
            else
            {
                if (tempPlayersFull[i2].lock === true)
                {
                    rankingsString = rankingsString + tempPlayersFull[i2].exposure.toString() + "L";
                }
                else if (tempPlayersFull[i2].stack === true)
                {
                    rankingsString = rankingsString + tempPlayersFull[i2].exposure.toString() + "S";
                }
                else
                {
                    rankingsString = rankingsString + tempPlayersFull[i2].exposure.toString();
                }
            }
        }
        i2 = i2 + 1;
    }
    
   return rankingsString;
}

/*************************************************************************************/
/*                      HANDLE DEFENSE MATCHUPS                                      */
/*************************************************************************************/
    const getOppRank = (team, gameInfo, position) => {
        gameInfo=gameInfo.replace(team, "");
        gameInfo = gameInfo.substring(0, 4);
        gameInfo=gameInfo.replace(" ", "");
        gameInfo=gameInfo.replace("@", "");
        let opponentRank = 0;
        let matchup = "";

        /* Get opponent rank based on position */
        if (position === 'RB')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).RushDefRank;
            }
        }
        if (position === 'WR' || position === 'QB')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).PassDefRank;
            }
        }
        if (position === 'TE')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).TEDefRank;
            }
        }

        /* Now categorize the rank in a given tier */
        if (opponentRank > 0 && opponentRank < 5.5)
        {
            matchup = 'BRUTAL';
        }
        else if (opponentRank >= 5.5 && opponentRank < 10.5)
        {
            matchup = 'Hard';
        }
        else if (opponentRank >= 10.5 && opponentRank < 14.5)
        {
            matchup = 'Slightly Hard';
        }
        else if (opponentRank >= 14.5 && opponentRank < 18.5)
        {
            matchup = 'Average';
        }
        else if (opponentRank >= 18.5 && opponentRank < 22.5)
        {
            matchup = 'Slightly Easy';
        }
        else if (opponentRank >= 22.5 && opponentRank < 27.5)
        {
            matchup = 'Easy';
        }
        else if (opponentRank >= 27.5 && opponentRank <= 32)
        {
            matchup = 'CUPCAKE';
        }
        else
        {
            matchup = 'Loading...';
        }

        if (position === 'DST')
            {matchup = 'N/A'}

        return matchup;
     }

     // exact same logic as above, just doing it to get the class so we can display a certain color for the opponent rank
     const getOppClassRank = (team, gameInfo, position) => {
        gameInfo=gameInfo.replace(team, "");
        gameInfo = gameInfo.substring(0, 4);
        gameInfo=gameInfo.replace(" ", "");
        gameInfo=gameInfo.replace("@", "");
        let opponentRank = 0;
        let matchup = "";

        if (position === 'RB')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).RushDefRank;
            }
        }
        if (position === 'WR' || position === 'QB')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).PassDefRank;
            }
        }
        if (position === 'TE')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).TEDefRank;
            }
        }

        if (opponentRank > 0 && opponentRank < 5.5)
        {
            matchup = 'OppRankBrutal';
        }
        else if (opponentRank >= 5.5 && opponentRank < 10.5)
        {
            matchup = 'OppRankHard';
        }
        else if (opponentRank >= 10.5 && opponentRank < 14.5)
        {
            matchup = 'OppRankSlightlyHard';
        }
        else if (opponentRank >= 14.5 && opponentRank < 18.5)
        {
            matchup = 'OppRankAverage';
        }
        else if (opponentRank >= 18.5 && opponentRank < 22.5)
        {
            matchup = 'OppRankSlightlyEasy';
        }
        else if (opponentRank >= 22.5 && opponentRank < 27.5)
        {
            matchup = 'OppRankEasy';
        }
        else if (opponentRank >= 27.5 && opponentRank <= 32)
        {
            matchup = 'OppRankCupcake';
        }
        else
        {
            matchup = 'Loading....';
        }

        if (position === 'DST')
            {matchup = 'OppRankAverage'}

        return matchup;
     }

    const getdefenseRank = async () => { 

        try {
            const responseDefense = await fetch(defenseAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                }
            });
            const result = await responseDefense.json();

            if (responseDefense.ok) {
                if (result[0] !== undefined)
                {
                    setDefenseRank(result);
                }
            }
        } catch (err) {
            console.log('Error caught!  Problem getting defense ranking for ' + sport + '. Error:' + err.message);
        } finally {
        }
    }

/*************************************************************************************/
/*                      GET GAME INFO/OPPONENT                                    */
/*************************************************************************************/

const getGameOpp = (sGameInfo) => {
    var GameOpp = "";

    if (sGameInfo.length > 0)
    {  
        if (sGameInfo.includes(' '))
        {
            GameOpp = sGameInfo.substring(0, sGameInfo.indexOf(' '));
        }
        else
        {
          GameOpp = sGameInfo;
        }
    }
    return GameOpp;
}

const getGameDateTime = (sGameInfo) => {
    var GameDateTime = "";

    if (sGameInfo.length > 0)
    {  
        if (sGameInfo.includes(' '))
        {
            GameDateTime = sGameInfo.substring(sGameInfo.indexOf(' ') + 1, sGameInfo.length);
        }
    }
    return GameDateTime;
}

const getGameAwayTeam = (sGameInfo) => {
    var AwayTeam = "";

    if (sGameInfo.length > 0)
    {  
        AwayTeam = sGameInfo.substring(0, sGameInfo.indexOf('@'));
    }

    return AwayTeam;
}

const getGameHomeTeam = (sGameInfo) => {
    var HomeTeam = "";

    if (sGameInfo.length > 0)
    {  
        if (sGameInfo.includes(' ')) //  SF@PIT 09/10/23 01:00PM
        {
            HomeTeam = sGameInfo.substring(sGameInfo.indexOf('@'), sGameInfo.indexOf(' '));
        }
        else
        {
            HomeTeam = sGameInfo.substring(sGameInfo.indexOf('@'), sGameInfo.length);
        }
    }

    return HomeTeam;
}

const getGameAwayTeamClass = (sGameInfo, sPlayerTeam) => {
    var AwayTeamClass = "";
    var AwayTeam = "";
    
    if (sGameInfo.length > 0)
    {  
        AwayTeam = sGameInfo.substring(0, sGameInfo.indexOf('@'));
    }

    if (AwayTeam.toUpperCase() === sPlayerTeam.toUpperCase())
    {
        AwayTeamClass = "divBoldSmallNoWrap"
    }
    else
    {
        AwayTeamClass = "divSmallNoWrap";
    }
    
    return AwayTeamClass;
}

const getGameHomeTeamClass = (sGameInfo, sPlayerTeam) => {
    var HomeTeamClass = "";
    var HomeTeam = "";

    if (sGameInfo.includes(' ')) //  SF@PIT 09/10/23 01:00PM
    {
        HomeTeam = sGameInfo.substring(sGameInfo.indexOf('@'), sGameInfo.indexOf(' '));
    }
    else
    {
        HomeTeam = sGameInfo.substring(sGameInfo.indexOf('@'), sGameInfo.length);
    }

    if ( HomeTeam.toUpperCase() === ('@' + sPlayerTeam.toUpperCase()))
    {
        HomeTeamClass = "divBoldSmallNoWrap"
    }
    else
    {
        HomeTeamClass = "divSmallNoWrap";
    }

    return HomeTeamClass;
}

/* if the proprietary opponent's pass or rush 'ranking' is far worse than the other, show the funnel image if applicable to the position */
const getFunnel = (team, gameInfo, position) => {
    gameInfo=gameInfo.replace(team, "");
    gameInfo = gameInfo.substring(0, 4);
    gameInfo=gameInfo.replace(" ", "");
    gameInfo=gameInfo.replace("@", "");
    let opponentRankRush = 0;
    let opponentRankPass = 0;
    let funnelImage = "onepixel"; // need a default here somehow

    if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
    {
        opponentRankRush = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).RushDefRank;
    }

    if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
    {
        opponentRankPass = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).PassDefRank;
    }

    if (position === 'WR' || position === 'QB' || position === 'TE')
    {
        if ((opponentRankPass - opponentRankRush) >= 10 )
        {
            funnelImage = 'BlueFunnel';
        }
    }
    else if (position === 'RB')
    {
        if ((opponentRankRush - opponentRankPass) >= 10 )
        {
            funnelImage = 'RedFunnel';
        }
    }

    return funnelImage;
 }

/*************************************************************************************/
/*                      HANDLE INJURY STATUSES                                       */
/*************************************************************************************/
    const getInjuryInfo = async () => {
        try {

            var currentdateUTC = new Date();
            currentdateUTC = currentdateUTC.toUTCString();

            const responseInjury = await fetch(injuryAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                    'reactdate': currentdateUTC,
                }
            });
            const result = await responseInjury.json();

            if (responseInjury.ok) {
                if (result[0] !== undefined)
                {
                    // Update the injury result set with either DK or FD name/team
                    let i = 0;
                    while (i < result.length)
                    {
                        let updatedPlayerAndTeam = masterPlayerInfo.find(playerAndTeam => {return playerAndTeam.NameTeamInjury === result[i].playerwithteam});
                        if (updatedPlayerAndTeam !== undefined)
                        {
                            result[i].playerwithteam = updatedPlayerAndTeam.NameTeam;
                        }
                        i++;
                    }
                }
            }

            // Now handle case of overrides in this sport's MasterPlayers.csv (loaded from previous step)
            // First, find all players that have an active injurty status override in NFLMasterPlayers.csv
            let i2 = 0;

            let tempOverrideStatusPlayers = masterPlayerInfo.filter(player => {return player.OverrideStatus !== "" && (player.OverrideDateUTC === "" || Date.parse(player.OverrideDateUTC.toString() + " GMT") > Date.parse(currentdateUTC))});
            // Next, loop through those players and either UPDATE or INSERT into injury result set
            while (i2 < tempOverrideStatusPlayers.length)
                    {
                        let tempInjuredInfo = result.find(playerAndTeam => {return playerAndTeam.playerwithteam === tempOverrideStatusPlayers[i2].NameTeam});
                        if (tempInjuredInfo !== undefined) // UPDATE
                            {
                                let tempPlayerIndex = result.findIndex(obj => obj.playerwithteam === tempOverrideStatusPlayers[i2].NameTeam)
                                result[tempPlayerIndex].playerstatus = tempOverrideStatusPlayers[i2].OverrideStatus;
                                result[tempPlayerIndex].injurytype = "";
                            }
                        else  // INSERT
                            {
                                result.push({
                                    playername: "",
                                    playerteam: "",
                                    playerwithteam: tempOverrideStatusPlayers[i2].NameTeam,
                                    playerstatus: tempOverrideStatusPlayers[i2].OverrideStatus,
                                    injurytype: ""
                                });
                            }
                        i2 = i2 + 1;
            }
            setInjuryInfo(result);         
        } catch (err) {
            //setErrMessage('Error caught!  Problem getting injury news. ' + err.message);
            console.log('Error caught!  Problem getting injury news. ' + err.message);
        } finally {
        }
    }

    // This checks for unknown players in our partner's injury HTML (e.g. if they have a 'Jr.' after a name and the FD/DK/master data we have does not)
        // basically if there is an injured player in our vendor's site that we do *not* have a maching name/team in our master player CSV file, then print in console
        // the solution here is to modify teh master player CSV file and fill in the column 'PlayerInjuryName' w/ the injury's site's player name
    const findUnknownInjuredPlayers = (e) => {
        //console.log(injuryInfo);
        if (injuryInfo !== undefined && playerPoolFull !== undefined)
        {
            let i = 0;
            while (i < injuryInfo.length){
                let tempInjuredPlayer = injuryInfo[i];
                let tempSpecificTeam = playerPoolFull.filter(player => {return (player.TeamAbbrev === tempInjuredPlayer.playerteam)});

                if (tempSpecificTeam !== undefined && tempSpecificTeam.length > 0) // is the player's *team* part of the slate?
                {
                    let tempPlayer = playerPoolFull.find(player => {return ((player.Name + ' (' + player.TeamAbbrev + ')') === tempInjuredPlayer.playerwithteam)});
                
                    if (tempPlayer === undefined)
                    {
                        console.log(injuryInfo[i].playerwithteam);
                    }
                }
                i++;
            }
        }
    }
    // this is for binding the injury status to player list
     const getInjuryStatus = (playerAndTeam) => {

        let injuryStatus = "";

        if (injuryInfo.find(user => {return user.playerwithteam === playerAndTeam}) !== undefined
            &&
            injuryInfo.find(user => {return user.playerwithteam === playerAndTeam}).playerstatus.length > 0
            )
        {
            injuryStatus = '(' + injuryInfo.find(user => {return user.playerwithteam === playerAndTeam}).playerstatus + ') ';
        }

        return injuryStatus;
     }

/*************************************************************************************/
/*                      GENERATE LINEUP(S)                                           */
/*************************************************************************************/
    
  /* SINGLE lineup - called as user migrates the single lineups */
    const handleNextSingleLineup = (e) => {
         // if we've reached the end of the lineup batch, generate a new batch! 
        if (fullSinglLineupGenerate === (oneLineupCounter + 1))
        {
            setShowNextBtnSingleLineup(false);
            setIsLoading(true);
            generateLineups();
        }
        else
        {
            setOneLineupCounter(oneLineupCounter + 1);
            clearAllMessages();
        }
    }

    /* Generate clicked - for both SINGLE and BULK */
    const handleGenerateClick =  (e) => {
        try {
            // Prep

            clearAllMessages();
            setIsLoading(true);
            setLineupPlayerSummary(emptyArray);
            setLineupExport(emptyArray);
            setDisplayPlayerSummary(false);
            setDisplayOneLineup(false);

            // Validation

            if (checkedFlexOne === false && checkedFlexTwo === false && checkedFlexThree === false)
                {setErrMessage('Error!  Choose at least 1 Flex Position above');}
            else if (salaryMin > 99)
                {setErrMessage('Error!  Min Salary must be less than or equal to 99%');}

            if (errMessage.length === 0)
            {
                const QBs = finalPlayerPool.filter(player => player.Position === 'QB');
                const RBs = finalPlayerPool.filter(player => player.Position === 'RB');
                const WRs = finalPlayerPool.filter(player => player.Position === 'WR');
                const TEs = finalPlayerPool.filter(player => player.Position === 'TE');
                const DSTs = finalPlayerPool.filter(player => player.Position === 'DST');

                if (QBs.length < 1 || RBs.length < 2 || WRs.length < 3 || TEs.length < 1 || DSTs.length < 1)
                    {setErrMessage('Error!  Pick at least 1 QB, 2 RBs, 3 WRs, 1 TE and 1 DST');}
            }

            if (checkedStackDEF === true && checkedStackRB === false && checkedStackWR === false && checkedStackTE === false)
                {setErrMessage('Error!  You can not stack ONLY the DST.');}

            // Generate

            if (errMessage.length === 0 && finalPlayerPool.length > 0)
            {
                generateLineups();
            }
            else if (finalPlayerPool.length === 0)
            {
                setErrMessage('Error!  Player Pool has ' + finalPlayerPool.length.toString() + ' players. Please rate players or refresh the page and start again.');
                setIsLoading(false);
            }
            else
            {setIsLoading(false);}
        }
        catch (err) {
            setErrMessage('Error caught!  Problem with generating lineups');
            setIsLoading(false);
        } finally {
                
        }
    }

    /* SINGLE and BULK lineups */
    const generateLineups =  async () => {
        try {
            const QBs = finalPlayerPool.filter(player => player.Position === 'QB');
            const RBs = finalPlayerPool.filter(player => player.Position === 'RB');
            const WRs = finalPlayerPool.filter(player => player.Position === 'WR');
            const TEs = finalPlayerPool.filter(player => player.Position === 'TE');
            const DSTs = finalPlayerPool.filter(player => player.Position === 'DST'); 
       
            if( 
                (checkedFlexOne === true || checkedFlexTwo === true || checkedFlexThree === true) 
                &&
                (salaryMin <= 99)
                &&
                (finalPlayerPool.length >= 2)
                &&
                (QBs.length >= 1 && RBs.length >= 2 && WRs.length >= 3 && TEs.length >= 1 && DSTs.length >= 1)
                &&
                (betaPassword.length > 0 || requiredPassword === false)
            )
            {
                // this is for debug reasons to find injured players we can't match to the player pool
                if (boolLogMissingInjuryPlayers)
                    {findUnknownInjuredPlayers();}

                const cleanPassword = "gutter";//betaPassword.toLowerCase().replace(/\s+$/g, ''); 
                const encodedData = btoa(cleanPassword); // encode a string

                const templateSettings = scrapeTemplateSettings();
                
                if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                {
                    console.log (JSON.stringify({
                        loginName: "defaultuser",
                        passwordValue:  encodedData,
                        slateID: slateInfo[0].value,
                        dfsSlateType: typeOfSlate,
                        checkedFlexOne: checkedFlexOne,
                        checkedFlexTwo: checkedFlexTwo,
                        checkedFlexThree: checkedFlexThree,
                        salaryMin: salaryMin,
                        numbOfLineups: numbOfLineups,
                        checkedStackRB: checkedStackRB,
                        checkedStackWR: checkedStackWR,
                        checkedStackTE: checkedStackTE,
                        checkedStackDEF: checkedStackDEF,
                        checkedMiniStackRB: checkedMiniStackRB,
                        checkedMiniStackWR: checkedMiniStackWR,
                        checkedMiniStackTE: checkedMiniStackTE,
                        checkedRunBackRB: checkedRunBackRB,
                        checkedRunBackWR: checkedRunBackWR,
                        checkedRunBackTE: checkedRunBackTE,
                        checkedExcludeVsDef: checkedExcludeVsDef,
                        checkedRBandDSTboost: checkedRBandDSTboost,
                        countMaxPlayers: countMaxPlayers,
                        primaryStackBoost: checkedPrimaryStackBoost,
                        passThrough1: templateSettings,
                        passThrough2: "",
                        passThrough3: "",
                        passThrough4: "",
                        passThrough5: "",
                        finalPlayerPool: finalPlayerPool
                    }));
                }     

                const response = await fetch(lineupAPI, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginName: "defaultuser",
                        passwordValue:  encodedData,
                        slateID: slateInfo[0].value,
                        dfsSlateType: typeOfSlate,
                        checkedFlexOne: checkedFlexOne,
                        checkedFlexTwo: checkedFlexTwo,
                        checkedFlexThree: checkedFlexThree,
                        salaryMin: salaryMin,
                        numbOfLineups: numbOfLineups,
                        checkedStackRB: checkedStackRB,
                        checkedStackWR: checkedStackWR,
                        checkedStackTE: checkedStackTE,
                        checkedStackDEF: checkedStackDEF,
                        checkedMiniStackRB: checkedMiniStackRB,
                        checkedMiniStackWR: checkedMiniStackWR,
                        checkedMiniStackTE: checkedMiniStackTE,
                        checkedRunBackRB: checkedRunBackRB,
                        checkedRunBackWR: checkedRunBackWR,
                        checkedRunBackTE: checkedRunBackTE,
                        checkedExcludeVsDef: checkedExcludeVsDef,
                        checkedRBandDSTboost: checkedRBandDSTboost,
                        countMaxPlayers: countMaxPlayers,
                        primaryStackBoost: checkedPrimaryStackBoost,
                        passThrough1: templateSettings,
                        passThrough2: "",
                        passThrough3: "",
                        passThrough4: "",
                        passThrough5: "",
                        finalPlayerPool: finalPlayerPool
                    })
                });
                const result = await response.json();
               
                if (!response.ok) {
                    setErrMessage('Error!  ' + result['originalError']['info']['message'].toString());
                    setIsLoading(false);
                  
                    if (result['originalError']['info']['message'].toString() !== "Incorrect password")
                    {
                        setRequiredPassword(false);
                    }
                }
                else
                {
                    if (result[0] !== undefined)
                    {
                        setRequiredPassword(false);
                        if (result[0].length === 0)
                        {
                            setStep(3);
                            setWarningMessage('Warning:  No lineups generated.  Try to lower your "Min Salary" or add more players.');
                        }
                        else 
                        {
                            setShowNextBtn(false);

                            /* ONE LINEUP */
                            if (oneVsMany === "One")
                            {
                                if (result[0].length >= 1 && result[0].length < numbOfLineups)
                                {
                                    setStep(3);
                                    setWarningMessage('Warning: Not enough lineups generated.  Try to lower your "Min Salary" or add more players.');
                                    setSuccessMessage("");
                                }
                                else
                                {
                                    setSuccessMessage("");
                                    setDisplayOneLineup(true);
                                    setOneLineupCounter(0);
                                    setStep(4);
                                    setLineupExport(result[0]);
                                    setShowNextBtnSingleLineup(true);
                                    setIsLoading(false);
                                }
                            }
                            /* MANY LINEUPS */
                            else 
                            {
                                setStep(4);
                                setLineupExport(result[0]);
                                setDisplayPlayerSummary(true);
                                setLineupPlayerSummary(result[1]);
                                if (result[0].length >= 1 && result[0].length < numbOfLineups)
                                {
                                    setStep(3);
                                    setWarningMessage('Warning:  Only ' + result[0].length.toString() + ' of your ' + numbOfLineups.toString() + ' lineups generated.  Try to lower your "Min Salary" or add more players.');
                                    setSuccessMessage("");
                                }
                                else if (result[0].length === numbOfLineups)
                                {
                                    setSuccessMessage("Success!  Export your " + numbOfLineups.toString() + " lineups or go back to your pool.");
                                }
                            }
                        }
                        setIsLoading(false);
                    }
                }
            }
        } catch (err) {
            let errDescription = ""
            if (err.message.toString() === "f.originalError is undefined" || err.message.toString() === "Cannot read properties of undefined (reading 'info')")
            {
                errDescription = "Apologies, please hit 'generate' again.";
            }
            else
            {
                errDescription = 'Error caught!  Problem generating lineups from server. ' + err.message.toString();
            }
            setErrMessage(errDescription);
            setIsLoading(false);
        } finally {
           setIsLoading(false);
        }
    }

/*************************************************************************************/
/*                      USE-EFFECT() AND HTML RETURN                                 */
/*************************************************************************************/

    useEffect (() => {

        // Get query params to know what slate was selected
        const queryParams = new URLSearchParams(window.location.search);
        const slateID = parseInt(queryParams.get("slateID"));
        const oneVsManyParam = queryParams.get("lineups");

        if (slateID > 0)
        {
            getSlates(slateID); // this is for validation before loading the actual player data on the next step
            setStep(2);
            setShowPrevBtn(true);
          
            if (oneVsManyParam !== null && oneVsManyParam.length > 0)
                { setOneVsMany(oneVsManyParam); }
            else 
                { setOneVsMany("One"); } // Default to 'One' lineup if this variable isn't passed

            getMasterPlayerData(); 
            getdefenseRank(); 

            /* Set cookie for dynamic templates */
            let identifier = Cookies.get('userUniqueBBIdentifier');

            if (!identifier) {
                identifier = generateUniqueIdentifier();
                Cookies.set('userUniqueBBIdentifier', identifier, { expires: 365 });
            }
            setUserUniqueIdentifier(identifier);

            /* Now time to load most of the master player and also slate data from Azure CSV files -- See step summary comments up top for details */
            const fetchSlatePlayerData = async () => {
                try {
                    // Wait for getSlatePlayerData to complete and return the player count
                    const slatePlayerCount = await getSlatePlayerData(slateID);
        
                    // Call getTemplates only after getSlatePlayerData has completed
                    getTemplates(slateID, identifier, slatePlayerCount);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
        
            fetchSlatePlayerData(); // Call fetchData to initiate the data loading process
        }
        else // The query string is missing a positive integer for 'slateID'
        {
            setStep(1);
            setShowPrevBtn(true);
            setShowNextBtn(false);
            setErrMessage('Error! Slate not found. Hit "Back" and try again or contact support.');
        }        
    }, []
    );

    return (
        <div className="content">
        <div className = "home">
            {
                (step===2) &&
                <h3>Flex Position
                    <Tooltip
                        title="Select the eligible positions for your 'Flex' spot. Breezy Builder will always put the player in the latest game in your 'Flex'."
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip" ></img>
                    </Tooltip>
                </h3>
            }
            {
                (step===2) &&
                 <div>
                    <Checkbox
                        label=" RB"
                        value={checkedFlexOne}
                        onChange={handleChangeFlexOne}
                    />
                    <Checkbox
                        label=" WR"
                        value={checkedFlexTwo}
                        onChange={handleChangeFlexTwo}
                    />
                    <Checkbox
                        label=" TE"
                        value={checkedFlexThree}
                        onChange={handleChangeFlexThree}
                    />
                 </div>
            }
             {
            (step===2) && <br></br>
             }
            {
            (step===2) &&
                <h3>Min Salary
                    <Tooltip
                        title="This is the minimum % of the site's salary cap your lineup will use.  The maximum will always be 100% of the salary cap."
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                </h3>
            }
             {
            (step===2) &&
                <Slider id="minSalarySlider" defaultValue={95} minValue={85} maxValue={100} stepAmount={0.5}
                value={salaryMin}
                onChange={handleChangeMinSalary}
                charDisplay="%"
                >
                </Slider>
            }
       {
            (step===2) &&
                <br></br>
            }
            {
            (step===2 && oneVsMany === "Many") &&
                <h3>Number of Lineups</h3>
            }
            {
            (step===2 && oneVsMany === "Many") &&
                <Slider id="numberOfLineups" defaultValue={fullSinglLineupGenerate} minValue={2} maxValue={150} stepAmount={1}
                value={numbOfLineups}
                onChange={handleChangeNumbOfLineups}
                charDisplay=""
                >
                </Slider>
            }
             {
            (step===2 && oneVsMany === "Many") &&
                <br></br>
            }
           {
            (step===2) &&
                <h3>Recent Runs
                    <Tooltip
                        title="Preload settings and player ratings from a previous run.  You will be able to modify these settings, as you please."
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                </h3>
            }
            {
            (step===2) &&
                <div className="div-inline">
                    <Select className="custom-select" options={ddlTemplates} label="Select Template" value={ddlTemplateSelected} onChange={ddlTemplateChangeHandler} isSearchable={false} ></Select> 
                </div>
            }
            {
            (step===2) &&
                <Accordion allowZeroExpanded >
                    <AccordionItem uuid="a">
                        <AccordionItemHeading>
                            <AccordionItemButton >
                                Additional Options
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        
                        <AccordionItemPanel >
                            <h3>Stacking
                                <Tooltip
                                    title="Leave blank if stacking isn't required.  Your 'primary' game in a lineup is the game with your QB.  The 'run back' for the 'primary' game only applies if you've rated at least 1 player from the QB's opponent.  The 'secondary' game does not inlcude your QB and if you choose a mini-stack there, it could be 2 players from the same team (2v0) or opposing teams (1v1)."
                                    placement="top"
                                >
                                    <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                                </Tooltip>
                            </h3>
                            <div>
                            <h6>&nbsp;&nbsp;&nbsp;<b>Primary</b></h6>
                            <label className="divIndent">QB stack with:</label><br></br>
                                <div className="divIndentLots" >
                                    <Checkbox
                                        label=" RB"
                                        value={checkedStackRB}
                                        onChange={handleChangeCheckedStackRB}
                                    />
                                    <Checkbox
                                        label=" WR"
                                        value={checkedStackWR}
                                        onChange={handleChangeCheckedStackWR}
                                    />
                                    <Checkbox
                                        label=" TE"
                                        value={checkedStackTE}
                                        onChange={handleChangeCheckedStackTE}
                                    />
                                    <Checkbox
                                        label=" DST"
                                        value={checkedStackDEF}
                                        onChange={handleChangeCheckedStackDEF}
                                    />
                                </div>
                            </div>
                            <div>
                                    <div className="divIndentLotsItalics" >
                                    <Checkbox
                                        label=" Double Stack Boost"
                                        value={checkedPrimaryStackBoost}
                                        onChange={handleChangeCheckedPrimaryBoost}
                                        
                                    />
                                    </div>
                            </div>
                            <div>
                            <label className="divIndent" >Try to run it back with:</label> <br></br>
                                <div className="divIndentLots" >
                                    <Checkbox
                                        label=" RB"
                                        value={checkedRunBackRB}
                                        onChange={handleChangeCheckedRunBackRB}
                                    />
                                    <Checkbox
                                        label=" WR"
                                        value={checkedRunBackWR}
                                        onChange={handleChangeCheckedRunBackWR}
                                    />
                                    <Checkbox
                                        label=" TE"
                                        value={checkedRunBackTE}
                                        onChange={handleChangeCheckedRunBackTE}
                                    />
                                </div>
                            </div>
                            <h6>&nbsp;&nbsp;&nbsp;<b>Secondary</b></h6>
                            <label className="divIndent">Mini 2-player stack with:</label><br></br>
                                <div className="divIndentLots" >
                                    <Checkbox
                                        label=" RB"
                                        value={checkedMiniStackRB}
                                        onChange={handleChangeCheckedMiniStackRB}
                                    />
                                    <Checkbox
                                        label=" WR"
                                        value={checkedMiniStackWR}
                                        onChange={handleChangeCheckedMiniStackWR}
                                    />
                                    <Checkbox
                                        label=" TE"
                                        value={checkedMiniStackTE}
                                        onChange={handleChangeCheckedMiniStackTE}
                                    />
                                </div>
                            <br></br>
                            <h3>Defense
                            <Tooltip
                                    title="Check this box if you do *not* want your lineup's RBs/WRs/TE(s) going against your lineup's DST.  Regardless of this checkbox, Breezy Builder will never have a lineup's QB going against a lineup's DST."
                                    placement="top"
                                >
                                    <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                                </Tooltip>
                            </h3>
                            <div>
                            <label className="divIndent">Exclude players <b><i>vs</i></b> your defense</label>
                            <Checkbox
                                        label=""
                                        value={checkedExcludeVsDef}
                                        onChange={handleChangeCheckedExcludeVsDef}
                                    />
                            </div>
                            <div>
                            <label className="divIndent">RB/DST Stack Boost</label>
                            <Checkbox
                                        label=""
                                        value={checkedRBandDSTboost}
                                        onChange={handleChangeCheckedRBandDSTboost}
                                    />
                            </div>
                            <br></br>
                            <h3>Limits
                                <Tooltip
                                    title="*IMPORTANT* This checkbox limit does *not* look at players from your 'primary' stacked game (if you have one) or your lineup's DST.  Setting this limit to '1' is acceptable, especially in large fields."
                                    placement="top"
                                >
                                    <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                                </Tooltip>
                            </h3>
                            <div display="inline">
                                <label className="divIndent">Max players from same team</label>&nbsp; 
                                <button className="buttonRemove" onClick={decrementCount}></button>                            
                                &nbsp;{countMaxPlayers}&nbsp;
                                <button className="buttonAdd" onClick={incrementCount}></button>
                            
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            }
           
           {(step===4) && displayOneLineup && showNextBtnSingleLineup &&
                <div className="div-flexRight">
                    <button className="button" onClick={handleNextSingleLineup}>Next Lineup</button>
                 </div>
            }
            {(step===4) && displayOneLineup && 
             <br></br>
            }
            {(step===4) && displayOneLineup && successMessage &&
             <br></br>
            }
            {errMessage&& (
                <p className="error"> {errMessage} </p>
                )
            }
             {successMessage&& (
                <p className="success"> {successMessage} </p>
                )
            }
            {successMessage && (step===4) && displayOneLineup && 
            <br></br>
            }
            {warningMessage&& (
                <p className="warning"> {warningMessage} </p>
                )
            }
          
            {
            (step===3) &&
                <div className='flex'> 
                <div className="div-flexLeft">
                    <h3>Players
                    <Tooltip
                        title="Rating is the likelihood the player will be in a lineup.  Players with zero stars will be excluded from all lineups.  The 'Stack Only' checkbox appears if stacking options are configured on the previous step, and if checked, the player will only appear in lineups as part of the primary game stack (which is your QB's game).  'Quick Rate' will give 3 stars to each un-rated player based on the position *and* team filters currently selected.  Opponent's proprietary ranking is specific to the player's position.  A blue funnel represents an opponent being much weaker against the pass than run.  A red funnel is weaker against the run."
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                    </h3>
                </div>
                </div>
            }
            {
           (step===3) &&
                    <div className="divInlineAndHeightInlineBlock">
                           <label>
                             Team Filter
                            </label>
                            <br></br>
                            <div display="inline">
                           <Select
                                isMulti
                                name="label"
                                options={ddlTeams}
                                className="custom-select-teams" /*basic-multi-select*/
                                classNamePrefix="select"
                                onChange={ddlTeamChangeHandler}
                                isSearchable={false}
                            />
                            </div>
                    </div>
            }
            {isLoading&& (
                <p className="loadingNFL"> Loading... </p>
                )
            }
            {
           (step===3) && !isLoading &&
                            <div className="div-flexRightGenerate">
                                <button className="button" onClick={handleGenerateClick}>Generate</button>
                            </div>
            }

            {
            (step===3) &&
                 <div className="divInlineAndHeight">
                    <Checkbox
                        label=""
                        value={checkedAddRemoveMinPlayers}
                        onChange={handleAddRemoveMinPlayers}
                    />
                    <label className="labelItalic" onClick={handleAddRemoveMinPlayers}>Include 'Min Salary' Players
                        </label>
                </div>
            }

            {
            (step===3) && <br></br>
            }

            {
            (step===3) &&
            <div className="divInlineAndHeightInlineBlock">
                <ButtonGroup>
                    {positionTypes.map(type => (
                    <Button
                        key={type}
                        activePositionChoice={activePositionChoice === type}
                        onClick={() => handleClickPosChoice(type)}
                        className="ButtonPositionsNFL"
                        >
                            {type}
                    </Button>
                    ))}
                </ButtonGroup>
            </div>
            }

            {
           (step===3) &&
                             <div className="div-quickRank">
                                <button className="buttonQuickRank" onClick={handleQuickRank}>Quick Rate</button>
                                </div>
            }

            {
            (step===3) &&
                <NFLFullPlayerList players={playerPool.filter((player) => player.Position === activePositionChoice)} title="Players" handleDelete={handleDeletePlayer} setRatingValue = {setRatingValue} getRating = {getRating} getImage = {getImage} getPlayerSearchURL = {getPlayerSearchURL} getOppRank = {getOppRank} getOppClassRank = {getOppClassRank} getGameAwayTeamClass = {getGameAwayTeamClass} getGameAwayTeam = {getGameAwayTeam} getGameHomeTeamClass = {getGameHomeTeamClass} getGameHomeTeam = {getGameHomeTeam} getInjuryStatus = {getInjuryStatus} getFunnel ={getFunnel} setStackValue = {setStackValue} getStack = {getStack} setLockValue = {setLockValue} getLock = {getLock} getLockByNameAndID = {getLockByNameAndID} getGameOpp = {getGameOpp} getGameDateTime = {getGameDateTime} checkedStackRB = {checkedStackRB} checkedStackWR = {checkedStackWR} checkedStackTE = {checkedStackTE} checkedRunBackRB = {checkedRunBackRB} checkedRunBackWR = {checkedRunBackWR} checkedRunBackTE = {checkedRunBackTE} site={siteOfSlate}></NFLFullPlayerList>
            }
             {
            (step===3) &&
            <br></br>
             }
             {(step===4) && displayPlayerSummary && 
                <NFLFullLineupList lineups={lineupPlayerSummary} lineupExport={lineupExport} getImage = {getImage} sport={sport} site={siteOfSlate} lineupCount={numbOfLineups} getInjuryStatus={getInjuryStatus} getRatingImage={getRatingImage} getRatingImageClass={getRatingImageClass} getPlayerSearchURL={getPlayerSearchURL}></NFLFullLineupList>
            }

            {(step===4) && displayOneLineup && 
             <br></br>
            }
             {(step===4) && displayOneLineup && 
                <NFLFullSingleLineup players={lineupExport} lineupCounter={oneLineupCounter} getImage = {getImage} getInjuryStatus = {getInjuryStatus} sport={sport} site={siteOfSlate} getLockByNameAndID = {getLockByNameAndID} getPlayerSearchURL = {getPlayerSearchURL} getOppRank = {getOppRank} getOppClassRank = {getOppClassRank} getFunnel = {getFunnel}></NFLFullSingleLineup>
            }

             {(step===2 || step===4) &&
             <br></br>
            }
            
            {
            showPrevBtn &&
                <div className="div-flexLeft">
                <button className="button" onClick={handlePreviousClick}>Back</button>
                </div>
                }
            {
            showNextBtn &&
                <div className="div-flexRight">
                <button className="button" onClick={handleNextClick}>Next</button>
                </div>
            }
            {(step===4) && displayOneLineup && showNextBtnSingleLineup &&
                <div className="div-flexRight">
                    <button className="button" onClick={handleNextSingleLineup}>Next Lineup</button>
                 </div>
            }
            
        </div>
        </div>
    );
}
export default HomeNFLFull;