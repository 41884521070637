import React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Checkbox from './Checkbox';

const NFLFullPlayerList = ({players,title,handleDelete, setRatingValue, getRating, getImage, getPlayerSearchURL, getOppRank, getOppClassRank, getGameAwayTeamClass, getGameAwayTeam, getGameHomeTeamClass, getGameHomeTeam, getInjuryStatus, getFunnel, setStackValue, getStack, setLockValue, getLock, getLockByNameAndID, getGameOpp, getGameDateTime, checkedStackRB, checkedStackWR, checkedStackTE, checkedRunBackRB, checkedRunBackWR, checkedRunBackTE, site}) => {

    const styles = {
        Scrollbars: {
          wdith: 0,
          height: 500
        }
    }    

    return (
        <div className="player-list">
                <div className="outlet">
                        <div className="player-view-col">
                            <div className="player-item-NFL-col"><b>Rating</b></div>
                            <div className="player-item-NFL-col"></div>
                            <div className="player-item-NFL-col"><b>Player</b></div>
                            <div className="player-item-NFL-col-responsive"></div>
                            <div className="player-item-NFL-col-responsive"></div>
                            <div className="player-item-NFL-col-responsive"><b>Salary</b></div>
                            <div className="player-item-NFL-col-responsive"><b>Game</b></div>
                        </div>
                        <Scrollbars  id="scrollBarPlayers" className="scrollbar" autoHide style={{ height: 500}}>
                        <div>
                        {players.map((player) => (
                            <div className = "player-view" key={player.ID}>
                                <div className={"player-item" + getLock(player.ID).toString()}>
                                <Box component="fieldset" 
                                    mb={0} borderColor="transparent"
                                    sx={{ width: "0%", paddingLeft: "0px", paddingRight: "0px", paddingTop: "2px", paddingBottom: "0px"}}>
                                        <Rating
                                            name="PlayerRating"
                                            defaultValue={0}
                                            value= {getRating(player.ID)}
                                            onChange={(event, newValue) => setRatingValue(player.ID, newValue)}
                                        />
                                    </Box>
                                    {
                                    (
                                        ((checkedStackRB || checkedRunBackRB) && player.Position.toString() === "RB") ||
                                        ((checkedStackWR || checkedRunBackWR) && player.Position.toString() === "WR") ||
                                        ((checkedStackTE || checkedRunBackTE) && player.Position.toString() === "TE")
                                    ) &&
                                    <div className={"Rating_" + player.Position.toString()}>
                                        <Checkbox
                                            label=" Stack Only"
                                            value= {getStack(player.ID)}
                                            onChange={() => setStackValue(player.ID)}
                                        />
                                    </div>
                                    }
                                    <div className={"Lock_" + player.Position.toString()}>
                                        <Checkbox
                                            label=" Lock"
                                            value= {getLock(player.ID)}
                                            onChange={() => setLockValue(player.ID)}
                                        />
                                    </div>
                                </div>
                                <div className={"player-item-image" + getLockByNameAndID(player["Name + ID"])}>
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <a href={getPlayerSearchURL(player.Name, player.TeamAbbrev, player.Position)} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(player.Name + ' ('  + player.TeamAbbrev + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        </a>
                                    </div>
                                </div>
                                <div className={"player-item" + getLockByNameAndID(player["Name + ID"])}>
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(player.Name + ' ('  + player.TeamAbbrev + ')')}</div>
                                    <a href={getPlayerSearchURL(player.Name, player.TeamAbbrev, player.Position)} target="_blank">
                                        {player.Name.length > 17 &&
                                            <div className="divBoldResponsiveSmall">{player.Name.substring(0,17) + '..'}</div>
                                        }
                                        {player.Name.length <= 17 &&
                                            <div className="divBoldResponsive">{player.Name}</div>
                                        }
                                    </a>
                                    <a href={getPlayerSearchURL(player.Name, player.TeamAbbrev, player.Position)} target="_blank">
                                            <Image 
                                            src= {"RwLogo.png"}
                                            className={"player-view-rwLogo"}
                                            rounded
                                            />
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(player.TeamAbbrev, player['Game Info'], player.Position)}>
                                                {getOppRank(player.TeamAbbrev, player['Game Info'], player.Position)}
                                            </div>
                                    </div>
                                    <br></br>
                                    <div className="responsive-NFL-playerInfo">
                                        <div className="Matchup">Salary: </div>{player.Salary} <br></br>
                                        <div className="Matchup">Game: </div>
                                            <div className={getGameAwayTeamClass(player['Game Info'], player.TeamAbbrev)}>
                                                {getGameAwayTeam(player['Game Info'])}
                                            </div>
                                            <div className={getGameHomeTeamClass(player['Game Info'], player.TeamAbbrev)}>
                                                {getGameHomeTeam(player['Game Info'])} 
                                            </div>
                                            <br></br> {getGameDateTime(player['Game Info'])}
                                    </div>
                                </div>
                                <div className="player-item-funnel-logo">
                                        <Image 
                                            src= {getFunnel(player.TeamAbbrev, player['Game Info'], player.Position) + ".png"}
                                            className={"player-view-funnel"}
                                            rounded
                                        />
                                </div>
                                <div className="player-item-NFL-logo">
                                        <Image 
                                            src= {"NFLLogos/" + player.TeamAbbrev + ".png"}
                                            className={"player-view-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="player-item-margintop-center"><b>{player.Salary}</b></div>
                                <div className={"player-item-margintop_" + site.toString()}>{player['Game Info']}</div>
                            </div>
                        ))}
                        </div>
                        </Scrollbars> 
                </div>
        </div>
    );
}
export default NFLFullPlayerList;