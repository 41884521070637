import React from 'react';
import { useState, useEffect } from 'react';
import MLBFullPlayerList from './MLBFullPlayerList';
import MLBFullLineupList from './MLBFullLineupList';
import MLBFullSingleLineup from './MLBFullSingleLineup.js';
import Checkbox from './Checkbox';
import Select from 'react-select';
import Slider from './Slider';
import styled from 'styled-components';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} 
    from 'react-accessible-accordion';
import Tooltip from "@material-ui/core/Tooltip";

/*************************************************************************************/
/*                      SET ENVIRONMENT VARIABLES                                    */
/*************************************************************************************/

const sport = 'MLB'; // this .js file is this sport
const positionTypes = ['Pitchers', 'Hitters']; // for player rating
const fullSinglLineupGenerate = 25; // If "one lineup at a time" is chosen, we need to grab X number of lineups behind scenes. This constant is "X".
const draftkingsSlateTimeZone = 'EDT';   // crucial: During MLB sesason, DK MLB have game times in EDT (*not* just ET) timezone and that's 4 hours from GMT. We need this in case a team has a double header 
const lineupOrderSlateTimeZone = 'EDT';  // Rotowire currently displays in EDT (same as DK)... storing here in case it changes

var injuryAPI = '';
var lineuporderAPI = '';
var lineupAPI = '';
var playerAPI = '';
var slateAPI = ''; // data for 1 given slate
var slatesAPI = ''; // get all slates across all sports
var boolLogMissingInjuryPlayers = false;

if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
{
    injuryAPI = process.env.REACT_APP_UAT_API_INJURY;
    lineuporderAPI = process.env.REACT_APP_UAT_API_LINEUPORDER;
    playerAPI = process.env.REACT_APP_UAT_API_PLAYER;
    slateAPI = process.env.REACT_APP_UAT_API_SLATE;
    slatesAPI = process.env.REACT_APP_UAT_API_SLATES;
    lineupAPI = process.env.REACT_APP_UAT_API_MLBFULLLINEUP;
    if (process.env.REACT_APP_UAT_LOG_MSSINGINJURYPLAYERS.toUpperCase() === 'TRUE')
        {boolLogMissingInjuryPlayers = true;}
}
else if (process.env.REACT_APP_ENVIRONMENT === 'PROD')
{
    injuryAPI = process.env.REACT_APP_PROD_API_INJURY;
    lineuporderAPI = process.env.REACT_APP_PROD_API_LINEUPORDER;
    playerAPI = process.env.REACT_APP_PROD_API_PLAYER;
    slateAPI = process.env.REACT_APP_PROD_API_SLATE;
    slatesAPI = process.env.REACT_APP_PROD_API_SLATES;
    lineupAPI = process.env.REACT_APP_PROD_API_MLBFULLLINEUP;
    if (process.env.REACT_APP_PROD_LOG_MSSINGINJURYPLAYERS.toUpperCase() === 'TRUE')
        {boolLogMissingInjuryPlayers = true;}
}

const Button = styled.button`
background-color: #83a9ff;
border: none;
color: white;
padding: 5px 20px;
text-align: center;
text-decoration: none;
display: inline;
font-size: 16px;
font-family: Quicksand;
border-radius: 0px;
cursor:pointer;

${({ activePositionChoice }) =>
activePositionChoice &&
`
background-color: #1e90ff;
opacity: 1;
`}
`;

const ButtonGroup = styled.div`
display: inline;
`;

const HomeMLBFull = () => {

/*************************************************************************************/
/*                      SET USE STATE VARIABLES                                      */
/*************************************************************************************/
   
    /*Steps to Hide/Show modules */
    const [step, setStep] = useState(2);
    const [step3Loaded, setStep3Loaded] = useState(false); // only on the first time moving to step 3, should certain functions be called
    const [showNextBtn, setShowNextBtn] = useState(false);
    const [showPrevBtn, setShowPrevBtn] = useState(false);
    const [showNextBtnSingleLineup, setShowNextBtnSingleLineup] = useState(false);
    const [backdoor, setBackdoor] = useState(false); // dirty backdoor

    /* Core slate attributes - loaded in useEffect in step 2 */
    const [siteOfSlate, setSiteOfSlate] = useState(""); // DK or FD
    const [typeOfSlate, setTypeOfSlate] = useState("classic"); // eg "classic"
    const [slateInfo, setSlateInfo] = useState([]); // all the slate info for the slateID passed in query string (from MySQL table)
    const [oneVsMany, setOneVsMany] = useState("One"); // default to "One" - bulk is more niche

    /*Error/warning messages */
    const [errMessage,setErrMessage]=useState('');
    const [warningMessage,setWarningMessage]=useState('');
    const [successMessage,setSuccessMessage]=useState('');
    const [isLoading, setIsLoading] = useState(false);

    /*Salary Slider */
    const [salaryMin, setSalaryMin] = useState(95);

    /*# Lineups Slider */
    const [numbOfLineups, setNumbOfLineups] = useState(fullSinglLineupGenerate);

    /* Additional options */
        /* Primary Stack*/
    const [checkedStackPrimary, setCheckedStackPrimary] = useState(false);
    const [stackPrimaryTeamMinHitters, setStackPrimaryTeamMinHitters] = useState(2);
    const [stackPrimaryTeamMaxHitters, setStackPrimaryTeamMaxHitters] = useState(4);
    const [stackPrimaryTeamOppMinHitters, setStackPrimaryTeamOppMinHitters] = useState(2);
    const [stackPrimaryTeamOppMaxHitters, setStackPrimaryTeamOppMaxHitters] = useState(4);
         /*Secondary Stack */
    const [checkedStackSecondary, setCheckedStackSecondary] = useState(false);
    const [stackSecondaryTeamMinHitters, setStackSecondaryTeamMinHitters] = useState(2);
    const [stackSecondaryTeamMaxHitters, setStackSecondaryTeamMaxHitters] = useState(4);
    const [stackSecondaryTeamOppMinHitters, setStackSecondaryTeamOppMinHitters] = useState(0);
    const [stackSecondaryTeamOppMaxHitters, setStackSecondaryTeamOppMaxHitters] = useState(2);
          /* Stack - lineup spots away */
    const [stackLineupSpotsAway, setStackLineupSpotsAway] = useState(3);
         /*Exclude players against Defense */
    const [checkedExcludeVsDef, setCheckedExcludeVsDef] = useState(false);
    const [countMaxPlayers, setCountMaxPlayers] = useState(5);

    /* RATING / RANK stuff  */
          /*Include / Exclude min salary players to speed up */
    const [checkedAddRemoveNonStarters, setCheckedAddRemoveNonStarters] = useState(false);
    const [activePositionChoice, setActivePositionChoice] = useState(positionTypes[0]);
    const [tempCounter, setTempCounter] = useState(1);
    const [ddlTeams, setDdlTeams] = useState([]);
    const [ddlFilteredTeams, setDdlFilteredTeams] = useState([]);
    const [quickRankUserWarning, setQuickRankUserWarning] = useState(false);
    const [checkedFilterC, setCheckedFilterC] = useState(true);
    const [checkedFilter1B, setCheckedFilter1B] = useState(true);
    const [checkedFilter2B, setCheckedFilter2B] = useState(true);
    const [checkedFilter3B, setCheckedFilter3B] = useState(true);
    const [checkedFilterSS, setCheckedFilterSS] = useState(true);
    const [checkedFilterOF, setCheckedFilterOF] = useState(true);

    /* Get injury news and mapping data to update player statuses */
    // const [injuryInfo,setInjuryInfo]=useState([]);

    /* GENERATE LINEUPS CLICK */
    const [lineupExport,setLineupExport]=useState([]);
    const [lineupPlayerSummary,setLineupPlayerSummary]=useState([]);
    const [emptyArray, setEmptyArray] = useState([]);
    const [displayPlayerSummary, setDisplayPlayerSummary] = useState(false);
    const [displayOneLineup, setDisplayOneLineup] = useState(false);
    const [oneLineupCounter, setOneLineupCounter] = useState(0);
    const [betaPassword, setBetaPassword] = useState('');
    const [requiredPassword, setRequiredPassword] = useState(false);

    /* IMPORT CSVs and JS data files */
    const [finalPlayerPool,setFinalPlayerPool]=useState([]); // final player list of *only* rated players user has clicked
    const [playerPool,setplayerPool]=useState([]);         // initial slate load - excludes min salary
    const [playerPoolFull,setplayerPoolFull]=useState([]); // initial slate load - includes min salary
    const [masterPlayerInfo,setMasterPlayerInfo] = useState([]); // master player info for sport at heand
    const [lineupOrder, setLineupOrder] = useState([]);
    const [minSlateGameDate, setMinSlateGameDate] = useState(new Date("01/01/2023 1:00:00 PM GMT"));
    const [maxSlateGameDate, setMaxSlateGameDate] = useState(new Date("01/01/2033 1:00:00 PM GMT"));

/*************************************************************************************/
/*                      HANDLE NEXT/PREVIOUS BUTTONS                                 */
/*************************************************************************************/

/* IMPORTANT:
    step 1 - Home.js (where they choose slate and 'one vs many')
    step 2 - Configuration settings (e.g. salary slider, stacking)
            Upon load:   getSlateAndLineupPlayerData(), getMasterPlayerData(); 
                    -- fills setMasterPlayerInfo, setPlayerPoolFull and setLineupOrder
    step 3 - Player rating/lock info
            Upon 1st load:   awakenDB(), transformMasterAndLineupData(), updatePlayerPoolWithLineupOrder(), fillTeamsDDL(); 
            Upon additional loads:  just set the filters (eg team, position, starters) to what they were
                    -- fills setInjuryInfo, updates setMasterPlayerInfo and fills setplayerPool
    step 4 - Results - Bulk summary/export or individual lineup display/management
    */

    const handleNextClick = () => {
       if (step === 2)
        {
            if (slateInfo.length > 0) // we found the slateID in the MySQL DB
            {
                var tempSlates = [];
                var currentDate = new Date();
                var currentdateUTC = currentDate.toUTCString(); // ALWAYS UTC -- EVERYWHERE!
        
                tempSlates = slateInfo.filter(slate => {return slate.sport === sport && new Date(slate.displaySlateDate) <= Date.parse(currentdateUTC) && new Date(slate.hideSlateDate) >= Date.parse(currentdateUTC)});

                if (tempSlates.length > 0) // slate is found and is valid/active! 
                {
                    setShowNextBtn(false); // because it's replaced with the 'generate' button
                    //await delay(5000); // doesn't really fit purpose but maybe for future
                    clearAllMessages();
                    setStep(3);
                    
                    if (step3Loaded === false) // Is this the first time step 3 has been loaded?
                    {
                        setStep3Loaded(true);
                        if (backdoor === false)
                        {
                            awakenDB(); // for MLB, really just awakens Azure DB in prep of user hitting 'generate' soon (which uses Azure)
                        }
                        else
                        {
                            console.log('skip the awakening');
                        }
                        transformMasterAndLineupData(); // For raw 'master' and 'lineup' data loaded on previous step, update it to match either DK or FD player data
                        updatePlayerPoolWithLineupOrder();
                        fillTeamsDDL(); // grab unique teams from slate to show as filter
                    }

                    let positionArray = [];

                    if (checkedFilterC === true) {positionArray.push('C');} 
                    if (checkedFilter1B === true) {positionArray.push('1B');}
                    if (checkedFilter2B === true) {positionArray.push('2B');}
                    if (checkedFilter3B === true) {positionArray.push('3B');}
                    if (checkedFilterSS === true) {positionArray.push('SS');}
                    if (checkedFilterOF === true) {positionArray.push('OF');}

                    setDdlFilteredTeams(emptyArray);
                    filterHitterDisplay(emptyArray, positionArray, checkedAddRemoveNonStarters); // passing 'ddlFilteredTeams' doesn't populate the UI :( so gotta clear it from filter
                }
                else
                {
                    setErrMessage('Error! Slate has expired. Hit "Back" and try again or contact support.');
                }
            }
            else
            {
                setErrMessage('Error! Slate does not exist any longer. Hit "Back" and try again or contact support.');
            }
        }
    }

    const handlePreviousClick = () => {
        if (step === 1) // Only for fatal errors
        {
            window.location.replace('./');
        }
        else if (step === 2)
        {
            if (window.confirm('You will lose all your inputs if you go back. Is that OK?'))
            {
                window.location.replace('./');
            }
        }
        else if (step === 3)
        {
            setStep(2);
            setShowNextBtn(true);
        }
        else if (step === 4)
        {
            setStep(3);
            setShowNextBtn(false);

            let positionArray = [];
            if (checkedFilterC === true) {positionArray.push('C');} 
            if (checkedFilter1B === true) {positionArray.push('1B');}
            if (checkedFilter2B === true) {positionArray.push('2B');}
            if (checkedFilter3B === true) {positionArray.push('3B');}
            if (checkedFilterSS === true) {positionArray.push('SS');}
            if (checkedFilterOF === true) {positionArray.push('OF');}

            setDdlFilteredTeams(emptyArray);
            filterHitterDisplay(emptyArray, positionArray, checkedAddRemoveNonStarters); // passing 'ddlFilteredTeams' doesn't populate the UI :( so gotta clear it from filter
        }
        
        clearAllMessages();
        setDisplayPlayerSummary(false);
        setDisplayOneLineup(false);
        setShowNextBtnSingleLineup(false);
    }

    const clearAllMessages = () => {
        if (errMessage !== "")
            {setErrMessage("");} 
        if (successMessage !== "")
            {setSuccessMessage("");} 
        if (warningMessage !== "")
            {setWarningMessage("");}   
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

/*************************************************************************************/
/*                      LOAD PLAYER/SLATE INFO                                       */
/*************************************************************************************/
// get *all* slates across sports
const getSlates = async (slateID) => { 

    // var slateDetails = slates.find(slate => {return slate.value === slateSelected});
    try {
        const responseSlate = await fetch(slatesAPI, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'sport': sport, // sport,
                'slateid': slateID, // slateSelected,
            }
        });
        const result = await responseSlate.json();

        if (responseSlate.ok) {
            if (result[0] !== undefined)
            {
                var tempSlates = result[0];
                //tempSlates = tempSlates.sort((a, b) => Number(a.order) - Number(b.order));
                setSlateInfo(tempSlates);
                setSiteOfSlate(tempSlates[0].site);
                setTypeOfSlate(tempSlates[0].slateType);
            }
        }
    } catch (err) {
        console.log('Error caught!  Problem getting slates - ' + err.message);
    } finally {
    }
}

    // this gets raw slate player data from Azure for the slate we're on
    const getSlateAndLineupPlayerData = async(slateID) => {
      
        //var slateDetails = slates.find(slate => {return slate.value === slateSelected});
        try {
            const responseSlateInfo = await fetch(slateAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                    'slateid': slateID,
                }
            });
            const result = await responseSlateInfo.json();

            if (responseSlateInfo.ok) {
                if (result[0] !== undefined)
                {
                    setplayerPoolFull(result); // this is *all* available players in slate - including non starters
                   
                    /*
                    // whoa, hard lesson on .sort vs .toSorted... .sort actually sorts original array (as it's a reference)
                    // whoa, hard lesson #2 on .toSorted... it doesn't work on my android Chrome browser... 

                    var tempSortedSlate = result.toSorted((a, b) => Date.parse(getGameDateTime(a['Game Info'].replace("PM", " PM"))) - Date.parse(getGameDateTime(b['Game Info'].replace("PM", " PM"))));
                    // var tempSortedSlate = result.sort((a, b) => Date.parse(getGameDateTime(a['Game Info'].replace("PM", " PM"))) - Date.parse(getGameDateTime(b['Game Info'].replace("PM", " PM"))));
                    */

                    // so first we find the relative min/max gamedates on the given slate
                    var totalRows = result.length;
                    if (totalRows > 100)
                        {totalRows = 100;} // roll the dice and only look at 100 highest priced players to HOPEFULLY cover all games

                    var minSlateDate = getGameDateTime(result[0]['Game Info'].replace("PM", " PM"));
                    var maxSlateDate = getGameDateTime(result[0]['Game Info'].replace("PM", " PM"));
                    for (var i = 1; i<totalRows; i++) // start at 1, as we used 0 as the starting point for min/max
                    {
                        if (Date.parse(getGameDateTime(result[i]['Game Info'].replace("PM", " PM"))) < Date.parse(minSlateDate))
                            {minSlateDate = getGameDateTime(result[i]['Game Info'].replace("PM", " PM"));}
                        else if (Date.parse(getGameDateTime(result[i]['Game Info'].replace("PM", " PM"))) > Date.parse(maxSlateDate))
                            { maxSlateDate = getGameDateTime(result[i]['Game Info'].replace("PM", " PM"));}
                    }

                    // CRUCIAL: Convert to correct time zone!  On DK, summer bball game times are EDT timezone so pull that timezone from variable (draftkingsSlateTimeZone)
                    var minGameDate = new Date(minSlateDate.toString() + ' ' + draftkingsSlateTimeZone);
                    if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                        {console.log('1st slate game:' + minGameDate);}

                    var maxGameDate = new Date(maxSlateDate.toString() + ' ' + draftkingsSlateTimeZone);
                    if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                        {console.log('last slate game:' + maxGameDate);}
                    
                    setMinSlateGameDate(minGameDate);
                    setMaxSlateGameDate(maxGameDate);
                    
                    if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                        {console.log('slate player count: ' + result.length.toString());}

                    // We get and immediately filter the lineup order here (for double header reasons...)
                    getLineupOrder(minGameDate, maxGameDate);
                }
            }

        } catch (err) {
            console.log('Critical error!  Problem getting raw slate data for ' + sport + ' for the slateID: ' + slateID.toString() + '. Error:' + err.message);
            setErrMessage('Critical error!  Failed getting slate data. Contact support or try again. Error: ' + err.message);
        } finally {
        }
    }

    // Not used anymore... replaced with 'filterHitterDisplay'
    /*
    const filterSlateStartersOnly = () => { 

        var tempJSONdata = [];
        tempJSONdata = playerPoolFull.filter(player => {return (player.lineupOrder >= 0)});
                     
        setplayerPool(tempJSONdata);
    }
    */

    const fillTeamsDDL = () => { 
        var tempJSONdata = [];

        for (var i = 0; i<playerPoolFull.length; i++)
        {
            var tempTeamName = playerPoolFull[i]["TeamAbbrev"];
            let tempDDLTeamsObject = tempJSONdata.findIndex(obj2 => obj2.label === tempTeamName);
         
            if (tempDDLTeamsObject === -1)
            {
                tempJSONdata.push({
                    value: i,
                    label: tempTeamName
                });
            }
        }
     
        tempJSONdata.sort(function (a, b) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

        setDdlTeams(tempJSONdata);
    }

    const ddlTeamChangeHandler = (value) => { 

        // first fine all the positions that are checked
        let positionArray = [];
        if (checkedFilterC === true) {positionArray.push('C');} 
        if (checkedFilter1B === true) {positionArray.push('1B');}
        if (checkedFilter2B === true) {positionArray.push('2B');}
        if (checkedFilter3B === true) {positionArray.push('3B');}
        if (checkedFilterSS === true) {positionArray.push('SS');}
        if (checkedFilterOF === true) {positionArray.push('OF');}

        // now pass those checked positions, along with the updated team filter choice to the master 'filter' function
        filterHitterDisplay(value, positionArray, checkedAddRemoveNonStarters);

        // lastly, make sure to set the new team filter choice to use state var
        setDdlFilteredTeams(value);
    };
    
    // this gets raw master player data from Azure for the sport we're on
    // we'll transform/cleanse this data on the next step
    const getMasterPlayerData = async() => {
        
        try {
            const responseMasterPlayer = await fetch(playerAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                }
            });
            const result = await responseMasterPlayer.json();

            if (responseMasterPlayer.ok) {
                if (result[0] !== undefined)
                {
                    setMasterPlayerInfo(result);
                    if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                        {console.log('master player count: ' + result.length.toString());}
                }
            }

        } catch (err) {
            console.log('Critical error!  Problem getting player data for ' + sport + '. Error:' + err.message);
            setErrMessage('Critical error!  Failed getting player data. Contact support or try again. Error: ' + err.message);
        } finally {
        }
    }

    // Time to update all player name OVERRIDES (both DK/FD differences and Rotowire lineup order)
    // this takes the master player data from the previous step and transforms it to fit DK or FD names (as applicable)
    // does same for lineup order data
    const transformMasterAndLineupData = () => { 

        var jsonDataCleanMasterPlayerInfo = masterPlayerInfo;
        var jsonDataCleanLineUpOrder = lineupOrder; // already filtered to only have this slate's games
        var resultJSONMasterPlayerInfo = [];

        if (siteOfSlate === 'DK')
        {
            for (var i = 0; i<jsonDataCleanMasterPlayerInfo.length; i++)
            {
                var obj = jsonDataCleanMasterPlayerInfo[i];

                if (obj['PlayerDKName'].length > 0)
                {
                    obj['NameTeam'] = obj['PlayerDKName'] + ' (' + obj['PlayerTeam'] + ')';
                    obj['PlayerName'] = obj['PlayerDKName'];
                }
                else
                {
                    obj['NameTeam'] = obj['PlayerName'] + ' (' + obj['PlayerTeam'] + ')';
                }

                resultJSONMasterPlayerInfo.push(obj);

                if (obj['PlayerLineupName'].length > 0)
                {
                    var tempJSONLineupdata = [];
                    tempJSONLineupdata = lineupOrder.filter(player => {return player.playername === obj['PlayerLineupName'] && player.playerteam === obj['PlayerTeam']});  
                    
                    if (tempJSONLineupdata !== undefined && tempJSONLineupdata.length > 0) // do we have a player in today's starting lineup whose master name needs to be updated?
                    {
                        if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                            {console.log('We got a DK player with name override used. Lineup name: ' + tempJSONLineupdata[0].playername); 
                            }
            
                        //  I had initially built this loop for double headers, but keeping it in just in case i suppose (look into more later)
                        for (var j = 0; j<tempJSONLineupdata.length; j++)
                        {
                            let gameTime = tempJSONLineupdata[j].gameTime;
                            let tempPlayer = lineupOrder.find(player => {return player.playername === obj['PlayerLineupName'] && player.playerteam === obj['PlayerTeam'] && player.gameTime === gameTime});
                            let arrayindex = lineupOrder.findIndex(obj2 => obj2.playername === obj['PlayerLineupName'] && obj2.playerteam === obj['PlayerTeam'] && obj2.gameTime === gameTime);
                           
                            if (obj['PlayerDKName'].length > 0)
                                {tempPlayer.playername = obj['PlayerDKName'];} 
                            else
                                {tempPlayer.playername = obj['PlayerName'];} 

                            jsonDataCleanLineUpOrder[arrayindex] = tempPlayer;
                        }
                    }
                }
            }
            jsonDataCleanMasterPlayerInfo = resultJSONMasterPlayerInfo;
        }
        else if (siteOfSlate === 'FD')
        {
            for (var i = 0; i<jsonDataCleanMasterPlayerInfo.length; i++)
            {
                var obj = jsonDataCleanMasterPlayerInfo[i];

                if (obj['PlayerFDName'].length > 0)
                {
                    obj['NameTeam'] = obj['PlayerFDName'] + ' (' + obj['PlayerTeam'] + ')';
                    obj['PlayerName'] = obj['PlayerFDName'];
                }
                else
                {
                    obj['NameTeam'] = obj['PlayerName'] + ' (' + obj['PlayerTeam'] + ')';
                }

                resultJSONMasterPlayerInfo.push(obj);

                if (obj['PlayerLineupName'].length > 0)
                {
                    var tempJSONLineupdata = [];
                    tempJSONLineupdata = lineupOrder.filter(player => {return player.playername === obj['PlayerLineupName'] && player.playerteam === obj['PlayerTeam']});  
                    
                    if (tempJSONLineupdata !== undefined && tempJSONLineupdata.length > 0)
                    {
                        if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                            {console.log('We got a FD player with name override used. Lineup name: ' + tempJSONLineupdata[0].playername); 
                            }
                        for (var j = 0; j<tempJSONLineupdata.length; j++)
                        {
                            let gameTime = tempJSONLineupdata[j].gameTime;
                            let tempPlayer = lineupOrder.find(player => {return player.playername === obj['PlayerLineupName'] && player.playerteam === obj['PlayerTeam'] && player.gameTime === gameTime});
                            let arrayindex = lineupOrder.findIndex(obj2 => obj2.playername === obj['PlayerLineupName'] && obj2.playerteam === obj['PlayerTeam'] && obj2.gameTime === gameTime);
                           
                            if (obj['PlayerFDName'].length > 0)
                                {tempPlayer.playername = obj['PlayerFDName'];} 
                            else
                                {tempPlayer.playername = obj['PlayerName'];} 

                            jsonDataCleanLineUpOrder[arrayindex] = tempPlayer;
                        }
                    }
                }          
            }
            jsonDataCleanMasterPlayerInfo = resultJSONMasterPlayerInfo;
        }
        setMasterPlayerInfo(jsonDataCleanMasterPlayerInfo);
        setLineupOrder(jsonDataCleanLineUpOrder);
    }

    // this takes lineup order data and updates the *slate* JSON players w/ it
    const updatePlayerPoolWithLineupOrder = () => { 
        var jsonDataFullPlayerPool = playerPoolFull;
        var resultJSONSlateDataWithLineupInfo = [];

        for (var i = 0; i<jsonDataFullPlayerPool.length; i++)
        {
            var obj = jsonDataFullPlayerPool[i];

            let tempPlayer = lineupOrder.find(player => {return player.playername === obj['Name'] && player.playerteam === obj['TeamAbbrev']});
            if (tempPlayer !== undefined)
                {obj['lineupOrder'] = tempPlayer.lineupPosition;}
            else
                {obj['lineupOrder'] = -1;}

                resultJSONSlateDataWithLineupInfo.push(obj);
        }
        
        setplayerPoolFull(resultJSONSlateDataWithLineupInfo);

        // this is for debug reasons to find lineup scrape players we can't match to the player pool
        // for each user, go to the master MLB file on Azure and put the lineup scrape (e..g Rotowire) name in the lineup column at the end of the CSV
        if (boolLogMissingInjuryPlayers)
            {findUnknownLineupPlayers();}
    }
    

/*************************************************************************************/
/*                      HANDLE CONFIGURATION UI CONTROLS                             */
/*************************************************************************************/

    const handleChangeMinSalary = (index) => {
        setSalaryMin(index);
        if (errMessage === "Error!  Min Salary must be less that 99%" && index <= 98.5)
            {setErrMessage("");}
      };

    const handleChangeNumbOfLineups = (index) => {
        setNumbOfLineups(index);
      };
   
    // Primary stacking
    const handleChangeCheckedStackPrimary = () => {
        setCheckedStackPrimary(!checkedStackPrimary);
      };

    function incrementPrimaryStackMinCount() {
        let tempCount = stackPrimaryTeamMinHitters
        if (tempCount < 4 && tempCount < stackPrimaryTeamMaxHitters)
            {tempCount = tempCount + 1;}
        setStackPrimaryTeamMinHitters(tempCount);
    }

    function decrementPrimaryStackMinCount() {
        let tempCount = stackPrimaryTeamMinHitters
        if (tempCount > 2)
            {tempCount = tempCount - 1;} 
        setStackPrimaryTeamMinHitters(tempCount);
    }

    function incrementPrimaryStackMaxCount() {
        let tempCount = stackPrimaryTeamMaxHitters
        if (tempCount < 5)
            {tempCount = tempCount + 1;}
        setStackPrimaryTeamMaxHitters(tempCount);
    }

    function decrementPrimaryStackMaxCount() {
        let tempCount = stackPrimaryTeamMaxHitters
        if (tempCount > 2 && tempCount > stackPrimaryTeamMinHitters)
            {tempCount = tempCount - 1;} 
        setStackPrimaryTeamMaxHitters(tempCount);
    }

    function incrementPrimaryStackOppMinCount() {
        let tempCount = stackPrimaryTeamOppMinHitters
        if (tempCount < 2 && tempCount < stackPrimaryTeamOppMaxHitters)
            {tempCount = tempCount + 1;}
        setStackPrimaryTeamOppMinHitters(tempCount);
    }

    function decrementPrimaryStackOppMinCount() {
        let tempCount = stackPrimaryTeamOppMinHitters
        if (tempCount > 0)
            {tempCount = tempCount - 1;} 
        setStackPrimaryTeamOppMinHitters(tempCount);
    }

    function incrementPrimaryStackOppMaxCount() {
        let tempCount = stackPrimaryTeamOppMaxHitters
        if (tempCount < 4)
            {tempCount = tempCount + 1;}
        setStackPrimaryTeamOppMaxHitters(tempCount);
    }

    function decrementPrimaryStackOppMaxCount() {
        let tempCount = stackPrimaryTeamOppMaxHitters
        if (tempCount > 0 && tempCount > stackPrimaryTeamOppMinHitters)
            {tempCount = tempCount - 1;} 
        setStackPrimaryTeamOppMaxHitters(tempCount);
    }

     // Secondary stacking
     const handleChangeCheckedStackSecondary = () => {
        setCheckedStackSecondary(!checkedStackSecondary);
      };

    function incrementSecondaryStackMinCount() {
        let tempCount = stackSecondaryTeamMinHitters
        if (tempCount < 2 && tempCount < stackSecondaryTeamMaxHitters)
            {tempCount = tempCount + 1;}
        setStackSecondaryTeamMinHitters(tempCount);
    }

    function decrementSecondaryStackMinCount() {
        let tempCount = stackSecondaryTeamMinHitters
        if (tempCount > 2)
            {tempCount = tempCount - 1;} 
        setStackSecondaryTeamMinHitters(tempCount);
    }

    function incrementSecondaryStackMaxCount() {
        let tempCount = stackSecondaryTeamMaxHitters
        if (tempCount < 5)
            {tempCount = tempCount + 1;}
        setStackSecondaryTeamMaxHitters(tempCount);
    }

    function decrementSecondaryStackMaxCount() {
        let tempCount = stackSecondaryTeamMaxHitters
        if (tempCount > 2 && tempCount > stackSecondaryTeamMinHitters)
            {tempCount = tempCount - 1;} 
        setStackSecondaryTeamMaxHitters(tempCount);
    }

    function incrementSecondaryStackOppMinCount() {
        let tempCount = stackSecondaryTeamOppMinHitters
        if (tempCount < 4 && tempCount < stackSecondaryTeamOppMaxHitters)
            {tempCount = tempCount + 1;}
        setStackSecondaryTeamOppMinHitters(tempCount);
    }

    function decrementSecondaryStackOppMinCount() {
        let tempCount = stackSecondaryTeamOppMinHitters
        if (tempCount > 0)
            {tempCount = tempCount - 1;} 
        setStackSecondaryTeamOppMinHitters(tempCount);
    }

    function incrementSecondaryStackOppMaxCount() {
        let tempCount = stackSecondaryTeamOppMaxHitters
        if (tempCount < 4)
            {tempCount = tempCount + 1;}
        setStackSecondaryTeamOppMaxHitters(tempCount);
    }

    function decrementSecondaryStackOppMaxCount() {
        let tempCount = stackSecondaryTeamOppMaxHitters
        if (tempCount > 0 && tempCount > stackSecondaryTeamOppMinHitters)
            {tempCount = tempCount - 1;} 
        setStackSecondaryTeamOppMaxHitters(tempCount);
    }

    // Stacking - lineup spots away
    function incrementStackLineupSpotsAway() {
        let tempCount = stackLineupSpotsAway
        if (tempCount < 5)
            {tempCount = tempCount + 1;}
        setStackLineupSpotsAway(tempCount);
    }

    function decrementStackLineupSpotsAway() {
        let tempCount = stackLineupSpotsAway
        if (tempCount > 2)
            {tempCount = tempCount - 1;} 
        setStackLineupSpotsAway(tempCount);
    }

    const handleChangeCheckedExcludeVsDef = () => {
        setCheckedExcludeVsDef(!checkedExcludeVsDef);
      };

    function incrementMaxPlayerCount() {
        let tempCount = countMaxPlayers
        if (tempCount < 5)
            {tempCount = tempCount + 1;}
        setCountMaxPlayers(tempCount);
    }

    function decrementMaxPlayerCount() {
        let tempCount = countMaxPlayers
        if (tempCount > 1)
            {tempCount = tempCount - 1;} 
        setCountMaxPlayers(tempCount);
    }

/*************************************************************************************/
/*                      HANDLE PLAYER RATINGS & IMAGES                               */
/*************************************************************************************/


    // this is for position sub-header for player list view/rankings
    const handleClickPosChoice = (name, e) => {
        setActivePositionChoice(name);
    }

    // Position filters
    const handleChangeCheckedFilterC = () => {
            // First get all checked positions and put into an array..
            let positionArray = [];

            if (!checkedFilterC === true) {positionArray.push('C');}    // Catcher is opposite
            if (checkedFilter1B === true) {positionArray.push('1B');}
            if (checkedFilter2B === true) {positionArray.push('2B');}
            if (checkedFilter3B === true) {positionArray.push('3B');}
            if (checkedFilterSS === true) {positionArray.push('SS');}
            if (checkedFilterOF === true) {positionArray.push('OF');}

            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, checkedAddRemoveNonStarters);

            // Now set the state variable
            setCheckedFilterC(!checkedFilterC);
      }

      const handleChangeCheckedFilter1B = () => {
            // First get all checked positions and put into an array..
            let positionArray = [];

            if (checkedFilterC === true) {positionArray.push('C');} 
            if (!checkedFilter1B === true) {positionArray.push('1B');}
            if (checkedFilter2B === true) {positionArray.push('2B');}
            if (checkedFilter3B === true) {positionArray.push('3B');}
            if (checkedFilterSS === true) {positionArray.push('SS');}
            if (checkedFilterOF === true) {positionArray.push('OF');}

            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, checkedAddRemoveNonStarters);

            // Now set the state variable
            setCheckedFilter1B(!checkedFilter1B);
      }

      const handleChangeCheckedFilter2B = () => {
            // First get all checked positions and put into an array..
            let positionArray = [];

            if (checkedFilterC === true) {positionArray.push('C');} 
            if (checkedFilter1B === true) {positionArray.push('1B');}
            if (!checkedFilter2B === true) {positionArray.push('2B');}
            if (checkedFilter3B === true) {positionArray.push('3B');}
            if (checkedFilterSS === true) {positionArray.push('SS');}
            if (checkedFilterOF === true) {positionArray.push('OF');}

            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, checkedAddRemoveNonStarters);

            // Now set the state variable
            setCheckedFilter2B(!checkedFilter2B);
      }

      const handleChangeCheckedFilter3B = () => {
            // First get all checked positions and put into an array..
            let positionArray = [];

            if (checkedFilterC === true) {positionArray.push('C');} 
            if (checkedFilter1B === true) {positionArray.push('1B');}
            if (checkedFilter2B === true) {positionArray.push('2B');}
            if (!checkedFilter3B === true) {positionArray.push('3B');}
            if (checkedFilterSS === true) {positionArray.push('SS');}
            if (checkedFilterOF === true) {positionArray.push('OF');}

            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, checkedAddRemoveNonStarters);

            // Now set the state variable
            setCheckedFilter3B(!checkedFilter3B);
      }

      const handleChangeCheckedFilterSS = () => {
            // First get all checked positions and put into an array..
            let positionArray = [];

            if (checkedFilterC === true) {positionArray.push('C');} 
            if (checkedFilter1B === true) {positionArray.push('1B');}
            if (checkedFilter2B === true) {positionArray.push('2B');}
            if (checkedFilter3B === true) {positionArray.push('3B');}
            if (!checkedFilterSS === true) {positionArray.push('SS');}
            if (checkedFilterOF === true) {positionArray.push('OF');}

            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, checkedAddRemoveNonStarters);

            // Now set the state variable
            setCheckedFilterSS(!checkedFilterSS);
      }

      const handleChangeCheckedFilterOF = () => {
            // First get all checked positions and put into an array..
            let positionArray = [];

            if (checkedFilterC === true) {positionArray.push('C');} 
            if (checkedFilter1B === true) {positionArray.push('1B');}
            if (checkedFilter2B === true) {positionArray.push('2B');}
            if (checkedFilter3B === true) {positionArray.push('3B');}
            if (checkedFilterSS === true) {positionArray.push('SS');}
            if (!checkedFilterOF === true) {positionArray.push('OF');}

            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, checkedAddRemoveNonStarters);

            // Now set the state variable
            setCheckedFilterOF(!checkedFilterOF);
      }

    /* This function will filter on all *3* filters:
	    1) Team filter
	    2) Position filter
	    3) Starters filter
    */
    const filterHitterDisplay = (arrTeams, arrPositions, bShowNonStarters) => { 
        var filteredX = []; // filtered players to be returned/set
        /* always add pitchers, as this filter is hitters only*/
        arrPositions.push('P');

        if (arrTeams.length === 0) // team filter has been cleared, show all teams!
        {
            if (bShowNonStarters === false) // do *not* include non starters
            {
                filteredX = playerPoolFull.filter(itemX => itemX.lineupOrder >= 0 && 
                                                  (
                                                    arrPositions.includes(itemX['Roster Position'].split('/')[0]) // check 1st position (most players just have this one)
                                                        // then check 2nd position (if they have it -- meaning if it's not undefined)
                                                    || (itemX['Roster Position'].split('/')[1] !== undefined && arrPositions.includes(itemX['Roster Position'].split('/')[1]))
                                                  )
                                                 ); 
            }
            else // include non starters
            {
                filteredX = playerPoolFull.filter(itemX => 
                                                    arrPositions.includes(itemX['Roster Position'].split('/')[0]) // check 1st position (most players just have this one)
                                                        // then check 2nd position (if they have it -- meaning if it's not undefined)
                                                    || (itemX['Roster Position'].split('/')[1] !== undefined && arrPositions.includes(itemX['Roster Position'].split('/')[1]))
                                                 ); 
            }
        }
        else  // we are filtering by team(s)
        {
            // Use map to get a simple array of team values. Ex: ['BOS', 'CLE', 'CHC']
            let teamFilter = arrTeams.map(itemY => { return itemY.label; });

            // Now filter on the team filter (and also look to see if we should be showing non-starters)
            if (bShowNonStarters === false) // do *not* include non starters
            {
                filteredX = playerPoolFull.filter(itemX => teamFilter.includes(itemX.TeamAbbrev) 
                                                    && itemX.lineupOrder >= 0 && 
                                                    (
                                                      arrPositions.includes(itemX['Roster Position'].split('/')[0]) // check 1st position (most players just have this one)
                                                          // then check 2nd position (if they have it -- meaning if it's not undefined)
                                                      || (itemX['Roster Position'].split('/')[1] !== undefined && arrPositions.includes(itemX['Roster Position'].split('/')[1]))
                                                    )
                                                   );  
            }
            else // include non starters
            {
                filteredX = playerPoolFull.filter(itemX => teamFilter.includes(itemX.TeamAbbrev) &&
                                                    (
                                                    arrPositions.includes(itemX['Roster Position'].split('/')[0]) // check 1st position (most players just have this one)
                                                        // then check 2nd position (if they have it -- meaning if it's not undefined)
                                                    || (itemX['Roster Position'].split('/')[1] !== undefined && arrPositions.includes(itemX['Roster Position'].split('/')[1]))
                                                    )
                                                    );  
            }
        }
        setplayerPool(filteredX);
    };

    // this is for removing all min salary players for performance reasons
    const handleAddRemoveNonStarters = (e) => {  
        let positionArray = [];

        if (checkedFilterC === true) {positionArray.push('C');} 
        if (checkedFilter1B === true) {positionArray.push('1B');}
        if (checkedFilter2B === true) {positionArray.push('2B');}
        if (checkedFilter3B === true) {positionArray.push('3B');}
        if (checkedFilterSS === true) {positionArray.push('SS');}
        if (checkedFilterOF === true) {positionArray.push('OF');}

        if (checkedAddRemoveNonStarters === true) // if they are *hiding* non-starters, don't ask/warn them
        {
            // Now filter the player pool on *all* filters (eg team, position, starters)
            filterHitterDisplay(ddlFilteredTeams, positionArray, !checkedAddRemoveNonStarters);

           // ddlTeamChangeHandler(ddlFilteredTeams, !checkedAddRemoveNonStarters); // now filter the changed display set to what the filter was when they clicked to add or remove non-starters
            setCheckedAddRemoveNonStarters(!checkedAddRemoveNonStarters);
        }
        else if (window.confirm('Are you sure you want to incldue non-starters?')) // if they are showing non-starters, ask/warn them because it's likely a mis-click
        {
            filterHitterDisplay(ddlFilteredTeams, positionArray, !checkedAddRemoveNonStarters);
            setCheckedAddRemoveNonStarters(!checkedAddRemoveNonStarters);
        }
    }
    
     /* Player Images */
     const getImage = (id) => {
        //id=id.replace("'", "");  // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
        let pictureURL = 'NoPlayerImage.png';
      
        if (masterPlayerInfo.find(player => {return player.NameTeam === id}) !== undefined)
             {pictureURL = masterPlayerInfo.find(player => {return player.NameTeam === id}).PicURL;}
        if (pictureURL.length === 0)
             {pictureURL = 'NoPlayerImage.png';}
        return pictureURL;
     }

    const getRating = (id) => {
        return playerPool.find(player => {return player.ID === id}).exposure;
    }

    const getRatingImage = (id) => {
        let ratingImage = '';
      
        if (id.length >= 7)
        {
             if (id.slice(0,7) === "1 Stars")
                 {ratingImage = "Stars/Stars_1.png";}
             else if (id.slice(0,7) === "2 Stars")
                 {ratingImage = "Stars/Stars_2.png";}
             else if (id.slice(0,7) === "3 Stars")
                 {ratingImage = "Stars/Stars_3.png";}
             else if (id.slice(0,7) === "4 Stars")
                 {ratingImage = "Stars/Stars_4.png";}
             else if (id.slice(0,7) === "5 Stars")
                 {ratingImage = "Stars/Stars_5.png";}
             else 
             {
                 console.log('prob: ' + id.toString());
             }
         }
         else if (id === "Lock")
         {
             ratingImage = "Lock.png";
         }
 
        return ratingImage;
     }
 
     const getRatingImageClass = (id) => {
         let ratingImageClass = '';
       
         if (id.length >= 7)
         {
             ratingImageClass = "lineup-item-bulkLineup-stars";
          }
          else if (id === "Lock")
          {
             ratingImageClass = "LockImageSize";
          }
  
         return ratingImageClass;
      }
 

    const getGameOpp = (sGameInfo, sTeamName) => {
        var GameOpp = "";

        if (sGameInfo.length > 0)
        {  
            if (sGameInfo.includes(' '))
            {
                GameOpp = sGameInfo.substring(0, sGameInfo.indexOf(' '));
            }
            else
            {
              GameOpp = sGameInfo;
            }
        }
  
        /* take it a step further and get just the opponent if hte team is passed in*/
        if (sTeamName.length > 0)
        {
            //console.log(sGameInfo.substring(sGameInfo.indexOf('@') + 1, (sGameInfo.indexOf('@') + 1 + sTeamName.length)));
            // if team is away, get the home team as the opponent
            if (GameOpp.substring(0, sTeamName.length) === sTeamName)
                {GameOpp = '@' + GameOpp.substring(sGameInfo.indexOf('@') + 1, sGameInfo.length);}
            else
                {GameOpp = GameOpp.substring(0, GameOpp.indexOf('@')); }
        }
        return GameOpp;
    }

    const getGameTime = (sGameInfo) => {
        var GameTime = "";

        if (sGameInfo.length > 0)
        {  
            if (sGameInfo.includes(' '))
            {
                GameTime = sGameInfo.substring(sGameInfo.indexOf(' ') + 1, sGameInfo.length);

                if (GameTime.includes(' '))
                {
                    GameTime = GameTime.substring(GameTime.indexOf(' ') + 1, GameTime.length);
                  
                }
            }
        }

        if (GameTime.length > 0)
        {
            GameTime = " " + GameTime;
        }
        return GameTime;
    }

    const getGameDateTime = (sGameInfo) => {
        var GameTime = "";

        if (sGameInfo.length > 0)
        {  
            if (sGameInfo.includes(' '))
            {
                GameTime = sGameInfo.substring(sGameInfo.indexOf(' ') + 1, sGameInfo.length);
            }
        }

        return GameTime;
    }

    const getStack = (id) => {
        return playerPool.find(player => {return player.ID === id}).stack;
    }

    const getStackClass = (playerPosition) => {
        var visible = "";
        if (playerPosition === "P")
            {visible = "Hidden";}
        else
            {visible = "Visible";}
        return visible;
    }

    const getLock = (id) => {
        return playerPool.find(player => {return player.ID === id}).lock;
    }

    const getLockByNameAndID = (id) => {
        //id=id.replace("'", ""); // i used to strip apostraphes out of the 'master' file but they don't seem to cause a problem anywhere... 
        let lockStatus = '';
     
       if (playerPool.find(player => {return player['Name + ID'] === id}) !== undefined)
            {
                if (playerPool.find(player => {return player['Name + ID'] === id}).lock === true)
                {
                    lockStatus = 'Lock';
                }
            }
      
       return lockStatus;
    }

     /* Player Hand */
     const getPlayerHand = (playerName, playerTeam) => {
        let playerHand = '';
      
        if (lineupOrder.find(player => {return player.playername === playerName && player.playerteam === playerTeam}) !== undefined)
             {playerHand = lineupOrder.find(player => {return player.playername === playerName && player.playerteam === playerTeam}).playerhand;}
        if (playerHand.length > 0)
             {playerHand = ' ' + playerHand + ' ';}

        return playerHand;
     }

     /* Player Hand */
     const getLineupSpotImage = (playerName, playerTeam) => {
        let lineupSpotURL = 'https://upload.wikimedia.org/wikipedia/commons/thumb/';
        let lineupSpot = 0;
        let confirm = "";

        if (lineupOrder.find(player => {return player.playername === playerName && player.playerteam === playerTeam}) !== undefined)
             {
                lineupSpot = lineupOrder.find(player => {return player.playername === playerName && player.playerteam === playerTeam}).lineupPosition;
                confirm = lineupOrder.find(player => {return player.playername === playerName && player.playerteam === playerTeam}).confirmedLineup;

                // is the spot confirmed? 
                if (confirm === "confirmed")
                {
                    switch(lineupSpot) {
                        case 0: lineupSpotURL = lineupSpotURL + "5/5c/Eo_circle_green_letter-s.svg/240px-Eo_circle_green_letter-s.svg.png"; break;
                        case 1: lineupSpotURL = lineupSpotURL + "6/66/Eo_circle_green_number-1.svg/240px-Eo_circle_green_number-1.svg.png"; break;
                        case 2: lineupSpotURL = lineupSpotURL + "a/a7/Eo_circle_green_number-2.svg/240px-Eo_circle_green_number-2.svg.png"; break;
                        case 3: lineupSpotURL = lineupSpotURL + "b/bc/Eo_circle_green_number-3.svg/240px-Eo_circle_green_number-3.svg.png"; break;
                        case 4: lineupSpotURL = lineupSpotURL + "4/49/Eo_circle_green_number-4.svg/240px-Eo_circle_green_number-4.svg.png"; break;
                        case 5: lineupSpotURL = lineupSpotURL + "2/26/Eo_circle_green_number-5.svg/240px-Eo_circle_green_number-5.svg.png"; break;
                        case 6: lineupSpotURL = lineupSpotURL + "3/3c/Eo_circle_green_number-6.svg/240px-Eo_circle_green_number-6.svg.png"; break;
                        case 7: lineupSpotURL = lineupSpotURL + "7/75/Eo_circle_green_number-7.svg/240px-Eo_circle_green_number-7.svg.png"; break;
                        case 8: lineupSpotURL = lineupSpotURL + "5/50/Eo_circle_green_number-8.svg/240px-Eo_circle_green_number-8.svg.png"; break;
                        case 9: lineupSpotURL = lineupSpotURL + "5/51/Eo_circle_green_number-9.svg/240px-Eo_circle_green_number-9.svg.png"; break;
                    }
                }
                else if (confirm === "unconfirmed")
                {
                    switch(lineupSpot) {
                        case 0: lineupSpotURL = lineupSpotURL + "5/58/Eo_circle_amber_white_letter-p.svg/240px-Eo_circle_amber_white_letter-p.svg.png"; break;
                        case 1: lineupSpotURL = lineupSpotURL + "f/f5/Eo_circle_amber_number-1.svg/240px-Eo_circle_amber_number-1.svg.png"; break;
                        case 2: lineupSpotURL = lineupSpotURL + "9/92/Eo_circle_amber_number-2.svg/240px-Eo_circle_amber_number-2.svg.png"; break;
                        case 3: lineupSpotURL = lineupSpotURL + "0/04/Eo_circle_amber_number-3.svg/240px-Eo_circle_amber_number-3.svg.png"; break;
                        case 4: lineupSpotURL = lineupSpotURL + "e/e3/Eo_circle_amber_number-4.svg/240px-Eo_circle_amber_number-4.svg.png"; break;
                        case 5: lineupSpotURL = lineupSpotURL + "9/9f/Eo_circle_amber_number-5.svg/240px-Eo_circle_amber_number-5.svg.png"; break;
                        case 6: lineupSpotURL = lineupSpotURL + "2/2b/Eo_circle_amber_number-6.svg/240px-Eo_circle_amber_number-6.svg.png"; break;
                        case 7: lineupSpotURL = lineupSpotURL + "6/62/Eo_circle_amber_number-7.svg/240px-Eo_circle_amber_number-7.svg.png"; break;
                        case 8: lineupSpotURL = lineupSpotURL + "7/7b/Eo_circle_amber_number-8.svg/240px-Eo_circle_amber_number-8.svg.png"; break;
                        case 9: lineupSpotURL = lineupSpotURL + "8/8f/Eo_circle_amber_number-9.svg/240px-Eo_circle_amber_number-9.svg.png"; break;
                    }
                }
            }

        if (lineupSpotURL === 'https://upload.wikimedia.org/wikipedia/commons/thumb/')
            { lineupSpotURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';} // transparent pixel
    
        return lineupSpotURL;
     }

     const getPlayerStats = (statType, id) => {
        if (statType === "Avg Points")
        {
            return playerPool.find(player => {return player.ID === id}).AvgPointsPerGame.toString();
        }
        else return '';
     }

    const setRatingValue = (id, newRating) => {

        const tempPlayers = playerPool; 
        const tempPlayersFull = playerPoolFull;
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        let tempPlayerFull = playerPoolFull.find(player => {return player.ID === id});
        clearAllMessages();

        if (newRating === null) // if user un-ranks a player
            {newRating = 0;}  // API/proc should ignore un-ranked players

        if (tempPlayer.lock === false) // Don't touch if locked, as it should remain with a max rating display
        {
            // update the player pool the user sees and ranks
            tempPlayer.exposure = newRating;
            let arrayindex = playerPool.findIndex(obj => obj.ID === id);
            tempPlayers[arrayindex] = tempPlayer;

            setplayerPool(tempPlayers);

            tempPlayerFull.exposure = newRating;
            let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === id);
            tempPlayersFull[arrayindexFull] = tempPlayerFull;

            setplayerPoolFull (tempPlayersFull);

            // update the player pool behind the scenes for the final 'generate' click
            const tempPlayers2 = finalPlayerPool; 
            let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
            if (tempPlayer2 !== undefined)
            {
                tempPlayer2.exposure = newRating;
                let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
                tempPlayers2[arrayindex2] = tempPlayer2;
            }
            else
            {
                tempPlayers2.push(tempPlayer);
            }

            setFinalPlayerPool(tempPlayers2);
            // dirty hack to refresh the player pool the user sees
            setTempCounter(tempCounter + 1); // ...  so updating a counter...
        }
    }

    const setStackValue = (id) => {

        // update the player pool the user sees and ranks
        const tempPlayers = playerPool; 
        const tempPlayersFull = playerPoolFull;
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        let tempPlayerFull = playerPoolFull.find(player => {return player.ID === id});
        let newStackValue = false;

        if (tempPlayer.stack === false)
        {
            newStackValue = true;
        }
        tempPlayer.stack = newStackValue;
        tempPlayerFull.stack = newStackValue;

        let arrayindex = playerPool.findIndex(obj => obj.ID === id);
        tempPlayers[arrayindex] = tempPlayer;
        setplayerPool(tempPlayers);

        let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === id);
        tempPlayersFull[arrayindexFull] = tempPlayerFull;
        setplayerPoolFull(tempPlayersFull);

        // update the player pool behind the scenes for the final 'generate' click
        const tempPlayers2 = finalPlayerPool; 
        let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
        if (tempPlayer2 !== undefined)
        {
            tempPlayer2.stack = newStackValue;
            let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
            tempPlayers2[arrayindex2] = tempPlayer2;
        }
        else
        {
            tempPlayers2.push(tempPlayer);
        }

        setFinalPlayerPool(tempPlayers2);
        // dirty hack to refresh the player pool the user sees
        setTempCounter(tempCounter + 1); // ...  so updating a counter...
    }

    const setLockValue = (id) => {

        // update the player pool the user sees and ranks
        const tempPlayers = playerPool; 
        const tempPlayersFull = playerPoolFull;
        let tempPlayer = playerPool.find(player => {return player.ID === id});
        let tempPlayerFull = playerPoolFull.find(player => {return player.ID === id});
        let newLockValue = false;

        if (tempPlayer.lock === false)
        {
            newLockValue = true;
            setRatingValue(id, 5);
        }
        tempPlayer.lock = newLockValue;
        tempPlayerFull.lock = newLockValue;

        let arrayindex = playerPool.findIndex(obj => obj.ID === id);
        tempPlayers[arrayindex] = tempPlayer;
        setplayerPool(tempPlayers);

        let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === id);
        tempPlayersFull[arrayindexFull] = tempPlayerFull;
        setplayerPoolFull(tempPlayersFull);

        // update the player pool behind the scenes for the final 'generate' click
        const tempPlayers2 = finalPlayerPool; 
        let tempPlayer2 = finalPlayerPool.find(player => {return player.ID === id});
        if (tempPlayer2 !== undefined)
        {
            tempPlayer2.lock = newLockValue;;
            let arrayindex2 = finalPlayerPool.findIndex(obj => obj.ID === id);
            tempPlayers2[arrayindex2] = tempPlayer2;
        }
        else
        {
            tempPlayers2.push(tempPlayer);
        }
        setFinalPlayerPool(tempPlayers2);
        // dirty hack to refresh the player pool the user sees
        setTempCounter(tempCounter + 1); // ...  so updating a counter...
    }

    /* 3-star quick rank on filtered players */
    const handleQuickRank = (e) => {

        if (quickRankUserWarning === true || window.confirm('This will rate all the un-rated displayed ' + activePositionChoice + ' with 3 stars.  Is that OK?'))
        {
            setQuickRankUserWarning(true);
            var quickRankAmount = 3;
            clearAllMessages();

            var jsonPlayerPool = playerPool;
            var jsonResultPlayerPool = [];
            var jsonPlayerPoolFull = playerPoolFull;
            var jsonPlayerPoolFinal = finalPlayerPool;

            for (var i = 0; i<jsonPlayerPool.length; i++)
            {
                var obj = jsonPlayerPool[i];
                var playerID = obj['ID'].toString();

                
                if (obj['lock']  === false && obj['exposure'] === 0 && ((activePositionChoice === "Pitchers" && obj['Roster Position'] === 'P') || (activePositionChoice === "Hitters" && obj['Roster Position'] !== 'P'))) // Don't touch if locked or already ranked
                {
                    obj['exposure'] = parseInt(quickRankAmount, 10);

                    let arrayindexFull = playerPoolFull.findIndex(obj => obj.ID === playerID);
                    jsonPlayerPoolFull[arrayindexFull] = obj;

                    let arrayindexFinal = finalPlayerPool.findIndex(obj => obj.ID === playerID);
                    if (arrayindexFinal !== -1)
                    {
                        jsonPlayerPoolFinal[arrayindexFinal] = obj;
                    }
                    else
                    {
                        jsonPlayerPoolFinal.push(obj);
                    }
                }
                jsonResultPlayerPool.push(obj);
            }
            setplayerPool(jsonResultPlayerPool);
            setplayerPoolFull(jsonPlayerPoolFull);
            setFinalPlayerPool(jsonPlayerPoolFinal);
        }
   }

   
/*************************************************************************************/
/*                      HANDLE DEFENSE MATCHUPS                                      */
/*************************************************************************************/
    const getOppPitcherName = (playername, playerteam) => {

        let oppPitcher = "N/A";

        let tempPlayer = lineupOrder.find(player => {return player.playername === playername && player.playerteam === playerteam});
            if (tempPlayer !== undefined)
            {
                oppPitcher = tempPlayer.opposingpitcher;
            }

        return oppPitcher;
     }

     const getOppPitcherStats = (playername, playerteam) => {

        let oppPitcherStats = "";

        let tempPlayer = lineupOrder.find(player => {return player.playername === playername && player.playerteam === playerteam});
            if (tempPlayer !== undefined)
            {
                oppPitcherStats = tempPlayer.opposingpitcherstats;
            }

        return oppPitcherStats;
     }

     // maybe a phase 2 to apply different colors based on ERA strength

     const getOppClassRank = (team, gameInfo, position) => {

        let matchup = "OppRankMLB";

        /*

        gameInfo=gameInfo.replace(team, "");
        gameInfo = gameInfo.substring(0, 4);
        gameInfo=gameInfo.replace(" ", "");
        gameInfo=gameInfo.replace("@", "");
        let opponentRank = 0;
       

        if (position === 'RB')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).RushDefRank;
            }
        }
        if (position === 'WR' || position === 'QB')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).PassDefRank;
            }
        }
        if (position === 'TE')
        {
            if (defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}) !== undefined)
            {
                opponentRank = defenseRank.find(teamDef => {return teamDef.TeamName === gameInfo}).TEDefRank;
            }
        }

        if (opponentRank > 0 && opponentRank < 5.5)
        {
            matchup = 'OppRankBrutal';
        }
        else if (opponentRank >= 5.5 && opponentRank < 10.5)
        {
            matchup = 'OppRankHard';
        }
        else if (opponentRank >= 10.5 && opponentRank < 14.5)
        {
            matchup = 'OppRankSlightlyHard';
        }
        else if (opponentRank >= 14.5 && opponentRank < 18.5)
        {
            matchup = 'OppRankAverage';
        }
        else if (opponentRank >= 18.5 && opponentRank < 22.5)
        {
            matchup = 'OppRankSlightlyEasy';
        }
        else if (opponentRank >= 22.5 && opponentRank < 27.5)
        {
            matchup = 'OppRankEasy';
        }
        else if (opponentRank >= 27.5 && opponentRank <= 32)
        {
            matchup = 'OppRankCupcake';
        }
        else
        {
            matchup = 'Loading....';
        }

        if (position === 'DST')
            {matchup = 'OppRankAverage'}

        */
        return matchup;
     }

     // Only get games that are within the slate's bounds... meaning we look at gametimes for DK slate and rotowire site and try to map
    const getLineupOrder = async (firstSlateGameDate, lastSlateGameDate) => { 

        try {
            const responseLineupOrder = await fetch(lineuporderAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                }
            });
            const result = await responseLineupOrder.json();
            var resultFiltered = [];

            let minSlateDateMinus1hour = new Date(firstSlateGameDate);
            minSlateDateMinus1hour.setHours(minSlateDateMinus1hour.getHours() - 1); // in case start times slightly off
            let maxSlateDatePlus1hour = new Date(lastSlateGameDate);
            maxSlateDatePlus1hour.setHours(maxSlateDatePlus1hour.getHours() + 1); // in case start times slightly off

            if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                {console.log('min game date minus 1 hour: ' + minSlateDateMinus1hour); 
                console.log('max game date plus 1 hour ' + maxSlateDatePlus1hour);
                }
            
            if (responseLineupOrder.ok) {
                if (result[0] !== undefined)
                {
                    for (var i = 0; i<result.length; i++)
                    { 
                        var confirmedOutsideOfSlate = false;
                        const tempGameTime = new Date(result[i]["gameTime"].toString() + ' ' + lineupOrderSlateTimeZone); // see explanations in this file.. rotowire is ET right now

                        if (tempGameTime !== undefined)
                        {
                            if (tempGameTime <= minSlateDateMinus1hour || tempGameTime >= maxSlateDatePlus1hour) // game is DEFINITELY outside slate time bounds
                            {
                                confirmedOutsideOfSlate = true;
                            }
                        }
                    
                        if (confirmedOutsideOfSlate === false)
                        {
                            resultFiltered.push(result[i]);
                        }
                    }

                    setLineupOrder(resultFiltered); // used to be result straight up

                    if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                        {console.log('total lineuporder players: ' + result.length.toString()); 
                        console.log('total lineuporder players filtered: ' + resultFiltered.length.toString());
                        }

                    // This is the last thing step 2 does loading...
                    //     i tried to show the 'next' button (to go to step 3) but sometimes this doesn't wait long enough
                    //     So before we show the 'next' button, let's wait 2 seconds
                    await delay(2000);
                    setShowNextBtn(true);
                }
            }
        } catch (err) {
            console.log('Error caught!  Problem getting lineup order for ' + sport + '. Error:' + err.message);
        } finally {
        }
    }

/*************************************************************************************/
/*                      HANDLE INJURY STATUSES                                       */
/*************************************************************************************/
// For MLB, changing 'getInjuryInfo' to 'awakenDB', as MLB uses Azure to actually generate lineups... and Azure can go to sleep to save $$
//          So the idea is while they are ranking/reviewing players, awaken the DB so it's ready for when they hit 'generate'
    const awakenDB = async () => {         // const getInjuryInfo = async () => {
        try {
            if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                {console.log('wakey wakey, eggs n bakey Azure!'); }

            var currentdateUTC = new Date();
            currentdateUTC = currentdateUTC.toUTCString();

            const responseInjury = await fetch(injuryAPI, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'sport': sport,
                    'reactdate': currentdateUTC,
                }
            });
            const result = await responseInjury.json();

            /* See comment above

            if (responseInjury.ok) {
                if (result[0] !== undefined)
                {
                    // Update the injury result set with either DK or FD name/team
                    let i = 0;
                    while (i < result.length)
                    {
                        let updatedPlayerAndTeam = masterPlayerInfo.find(playerAndTeam => {return playerAndTeam.NameTeamInjury === result[i].playerwithteam});
                        if (updatedPlayerAndTeam !== undefined)
                        {
                            result[i].playerwithteam = updatedPlayerAndTeam.NameTeam;
                        }
                        i++;
                    }
                }
            }

            // Now handle case of overrides in this sport's MasterPlayers.csv (loaded from previous step)
            // First, find all players that have an active injurty status override in NFLMasterPlayers.csv
            let i2 = 0;
            var currentdateUTC = new Date();
            currentdateUTC = currentdateUTC.toUTCString();

            let tempOverrideStatusPlayers = masterPlayerInfo.filter(player => {return player.OverrideStatus !== "" && (player.OverrideDateUTC === "" || Date.parse(player.OverrideDateUTC.toString() + " GMT") > Date.parse(currentdateUTC))});
            // Next, loop through those players and either UPDATE or INSERT into injury result set
            while (i2 < tempOverrideStatusPlayers.length)
                    {
                        let tempInjuredInfo = result.find(playerAndTeam => {return playerAndTeam.playerwithteam === tempOverrideStatusPlayers[i2].NameTeam});
                        if (tempInjuredInfo !== undefined) // UPDATE
                            {
                                let tempPlayerIndex = result.findIndex(obj => obj.playerwithteam === tempOverrideStatusPlayers[i2].NameTeam)
                                result[tempPlayerIndex].playerstatus = tempOverrideStatusPlayers[i2].OverrideStatus;
                                result[tempPlayerIndex].injurytype = "";
                            }
                        else  // INSERT
                            {
                                result.push({
                                    playername: "",
                                    playerteam: "",
                                    playerwithteam: tempOverrideStatusPlayers[i2].NameTeam,
                                    playerstatus: tempOverrideStatusPlayers[i2].OverrideStatus,
                                    injurytype: ""
                                });
                            }
                        i2 = i2 + 1;
            }
            setInjuryInfo(result);

            */
         
        } catch (err) {
            //setErrMessage('Error caught!  Problem getting injury news. ' + err.message);
            console.log('Error caught!  Problem awakening DB. ' + err.message);
        } finally {
        }
    }

    // This checks for unknown players in rotowire's lineup order HTML (e.g. if they have a 'Jr.' after a name and the FD/DK/master data we have does not)
        // un-matched players (based on combo of player name and player team) will print in console
        // the solution here is to modify the master MLB player CSV file (Azure) and fill in the column 'PlayerLineupName' w/ rotowire's player name
    // Turn this logging feature on/off from the .env file.
    const findUnknownLineupPlayers = () => {

        if (lineupOrder !== undefined && playerPoolFull !== undefined)
        {
            let i = 0;
            while (i < lineupOrder.length){  // loop through each player on lineup scrape site
                let tempLineupPlayer = lineupOrder[i];
                let tempSpecificTeam = playerPoolFull.filter(player => {return (player.TeamAbbrev === tempLineupPlayer.playerteam)});

                if (tempSpecificTeam !== undefined && tempSpecificTeam.length > 0) // this is so that if rotowire blanks out a game, you don't get a ton of players listed for those teams
                {
                    let tempPlayer = playerPoolFull.find(player => {return (player.Name === tempLineupPlayer.playername && player.TeamAbbrev ===  tempLineupPlayer.playerteam)});

                    if (tempPlayer === undefined) // if the player's team is part of slate and player can't be found (meaning names don't match), then log it! 
                    {
                        console.log('STOP!!! Player at lineups site but not on slate: ' + tempLineupPlayer.playername + " (" + tempLineupPlayer.playerteam + ")");
                    }
                }
                i++;
            }
        }
    }
    // this is for binding the injury status to player list
     const getInjuryStatus = (playerAndTeam) => {

        let injuryStatus = "";

        /* Maybe this is phase 2

        if (injuryInfo.find(user => {return user.playerwithteam === playerAndTeam}) !== undefined
            &&
            injuryInfo.find(user => {return user.playerwithteam === playerAndTeam}).playerstatus.length > 0
            )
        {
            injuryStatus = '(' + injuryInfo.find(user => {return user.playerwithteam === playerAndTeam}).playerstatus + ') ';
        }
        */

        return injuryStatus;
     }

/*************************************************************************************/
/*                      GENERATE LINEUP(S)                                           */
/*************************************************************************************/
    
  /* SINGLE lineup - called as user migrates the single lineups */
    const handleNextSingleLineup = (e) => {
         // if we've reached the end of the lineup batch, generate a new batch! 
        if (fullSinglLineupGenerate === (oneLineupCounter + 1))
        {
            setShowNextBtnSingleLineup(false);
            setIsLoading(true);
            generateLineups();
        }
        else
        {
            setOneLineupCounter(oneLineupCounter + 1);
            clearAllMessages();
        }
    }

    /* Generate clicked - for both SINGLE and BULK */
    const handleGenerateClick =  (e) => {
        try {
            // Prep

            clearAllMessages();
            setIsLoading(true);
            setLineupPlayerSummary(emptyArray);
            setLineupExport(emptyArray);
            setDisplayPlayerSummary(false);
            setDisplayOneLineup(false);

            // Validation

            if (salaryMin > 98.5)
                {setErrMessage('Error!  Min Salary must be less that 99%');}

            if (errMessage.length === 0)
            {
                const Pitchers = finalPlayerPool.filter(player => player['Roster Position'] === 'P');
                const Hitters = finalPlayerPool.filter(player => player['Roster Position'] !== 'P');
                const Catchers = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("c"));
                const FirstBasemen = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("1b"));
                const SecondBasemen = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("2b"));
                const ThirdBasemen = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("3b"));
                const ShortStops = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("ss"));
                const Outfielders = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("of"));

                if (Pitchers.length < 2 || Hitters.length < 10)
                    {setErrMessage('Error!  Pick at least 2 pitchers and 10 hitters.');}
                else if (Catchers.length === 0)
                    {setErrMessage('Error!  Pick a catcher.');}
                else if (FirstBasemen.length === 0)
                    {setErrMessage('Error!  Pick a first baseman.');}
                else if (SecondBasemen.length === 0)
                    {setErrMessage('Error!  Pick a second baseman.');}
                else if (ThirdBasemen.length === 0)
                    {setErrMessage('Error!  Pick a third baseman.');}
                else if (ShortStops.length === 0)
                    {setErrMessage('Error!  Pick a shortstop.');}
                else if (Outfielders.length < 3)
                    {setErrMessage('Error!  Pick more outfielders.');}
            }

            if (checkedStackPrimary === true && stackPrimaryTeamMinHitters > countMaxPlayers)
                {setErrMessage("Error!  You can't stack more hitters (" + stackPrimaryTeamMinHitters.toString() +  ") than your 'Same Team' limit (" + countMaxPlayers.toString() + ").");}

            // Generate

            if (errMessage.length === 0 && finalPlayerPool.length > 0)
            {
                generateLineups();
            }
            else if (finalPlayerPool.length === 0)
            {
                setErrMessage('Error!  Player Pool has ' + finalPlayerPool.length.toString() + ' players. Please rate players or refresh the page and start again.');
                setIsLoading(false);
            }
            else
            {setIsLoading(false);}
        }
        catch (err) {
            setErrMessage('Error caught!  Problem with generating lineups');
            setIsLoading(false);
        } finally {
                
        }
    }

    /* SINGLE and BULK lineups */
    const generateLineups =  async () => {
        try {
            const Pitchers = finalPlayerPool.filter(player => player['Roster Position'] === 'P');
            const Hitters = finalPlayerPool.filter(player => player['Roster Position'] !== 'P');
            const Catchers = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("c"));
            const FirstBasemen = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("1b"));
            const SecondBasemen = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("2b"));
            const ThirdBasemen = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("3b"));
            const ShortStops = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("ss"));
            const Outfielders = finalPlayerPool.filter(player => player['Roster Position'].toLowerCase().includes("of"));
       
            if( 
                (salaryMin <= 98.5)
                &&
                (Pitchers.length >= 2 && Hitters.length >= 10)
                &&
                (Catchers.length > 0 && FirstBasemen.length > 0 && SecondBasemen.length > 0 && ThirdBasemen.length > 0)
                &&
                (ShortStops.length > 0 && Outfielders.length > 2)
                &&
                (betaPassword.length > 0 || requiredPassword === false)
            )
            {

                const cleanPassword = "gutter";//betaPassword.toLowerCase().replace(/\s+$/g, ''); 
                const encodedData = btoa(cleanPassword); // encode a string
                
                if (process.env.REACT_APP_ENVIRONMENT === 'UAT')
                {
                    console.log (JSON.stringify({
                        loginName: "defaultuser",
                            passwordValue:  encodedData,
                            dfsSite: siteOfSlate,
                            dfsSlateType: typeOfSlate,
                            salaryMin: salaryMin,
                            numbOfLineups: numbOfLineups,
                            checkedStackPrimary: checkedStackPrimary,
                            stackPrimaryTeamMinHitters: stackPrimaryTeamMinHitters,
                            stackPrimaryTeamMaxHitters: stackPrimaryTeamMaxHitters,
                            stackPrimaryTeamOppMinHitters: stackPrimaryTeamOppMinHitters, 
                            stackPrimaryTeamOppMaxHitters: stackPrimaryTeamOppMaxHitters, 
                            checkedStackSecondary: checkedStackSecondary,
                            stackSecondaryTeamMinHitters: stackSecondaryTeamMinHitters,
                            stackSecondaryTeamMaxHitters: stackSecondaryTeamMaxHitters,
                            stackSecondaryTeamOppMinHitters: stackSecondaryTeamOppMinHitters,
                            stackSecondaryTeamOppMaxHitters: stackSecondaryTeamOppMaxHitters,
                            stackLineupSpotsAway: stackLineupSpotsAway,
                            checkedExcludeVsDef: checkedExcludeVsDef,
                            countMaxPlayers: countMaxPlayers,
                            finalPlayerPool: finalPlayerPool
                    }));
                }

                const response = await fetch(lineupAPI, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        loginName: "defaultuser",
                        passwordValue:  encodedData,
                        dfsSite: siteOfSlate,
                        dfsSlateType: typeOfSlate,
                        salaryMin: salaryMin,
                        numbOfLineups: numbOfLineups,
                        checkedStackPrimary: checkedStackPrimary,
                        stackPrimaryTeamMinHitters: stackPrimaryTeamMinHitters,
                        stackPrimaryTeamMaxHitters: stackPrimaryTeamMaxHitters,
                        stackPrimaryTeamOppMinHitters: stackPrimaryTeamOppMinHitters, 
                        stackPrimaryTeamOppMaxHitters: stackPrimaryTeamOppMaxHitters, 
                        checkedStackSecondary: checkedStackSecondary,
                        stackSecondaryTeamMinHitters: stackSecondaryTeamMinHitters,
                        stackSecondaryTeamMaxHitters: stackSecondaryTeamMaxHitters,
                        stackSecondaryTeamOppMinHitters: stackSecondaryTeamOppMinHitters,
                        stackSecondaryTeamOppMaxHitters: stackSecondaryTeamOppMaxHitters,
                        stackLineupSpotsAway: stackLineupSpotsAway,
                        checkedExcludeVsDef: checkedExcludeVsDef,
                        countMaxPlayers: countMaxPlayers,
                        finalPlayerPool: finalPlayerPool
                    })
                });
                const result = await response.json();
               
                if (!response.ok) {
                    setErrMessage('Error!  ' + result['originalError']['info']['message'].toString());
                    setIsLoading(false);
                  
                    if (result['originalError']['info']['message'].toString() !== "Incorrect password")
                    {
                        setRequiredPassword(false);
                    }
                }
                else
                {
                    if (result[0] !== undefined)
                    {
                        setRequiredPassword(false);
                   
                        if (result[0].length === 0)
                        {
                            setStep(3);
                            setWarningMessage('Warning:  No lineups generated.  Try to lower your "Min Salary" or add more players.');
                        }
                        else 
                        {
                            setShowNextBtn(false);

                            /* ONE LINEUP */
                            if (oneVsMany === "One")
                            {
                                if (result[0].length >= 1 && result[0].length < numbOfLineups)
                                {
                                    setStep(3);
                                    setWarningMessage('Warning: Not enough lineups generated.  Try to lower your "Min Salary" or add more players.');
                                    setSuccessMessage("");
                                }
                                else
                                {
                                    setSuccessMessage("");
                                    setDisplayOneLineup(true);
                                    setOneLineupCounter(0);
                                    setStep(4);
                                    setLineupExport(result[0]);
                                    setShowNextBtnSingleLineup(true);
                                    setIsLoading(false);
                                }
                            }
                            /* MANY LINEUPS */
                            else 
                            {
                                setStep(4);
                                setLineupExport(result[0]);
                                setDisplayPlayerSummary(true);
                                setLineupPlayerSummary(result[1]);
                                if (result[0].length >= 1 && result[0].length < numbOfLineups)
                                {
                                    setStep(3);
                                    setWarningMessage('Warning:  Only ' + result[0].length.toString() + ' of your ' + numbOfLineups.toString() + ' lineups generated.  Try to lower your "Min Salary" or add more players.');
                                    setSuccessMessage("");
                                }
                                else if (result[0].length === numbOfLineups)
                                {
                                    setSuccessMessage("Success!  Export your " + numbOfLineups.toString() + " lineups or go back to your pool.");
                                }
                            }
                        }
                        setIsLoading(false);
                    }
                }
            }
        } catch (err) {
            let errDescription = ""
            if (err.message.toString() === "f.originalError is undefined" || err.message.toString() === "Cannot read properties of undefined (reading 'info')")
            {
                errDescription = "Apologies, please hit 'generate' again.";
                console.log("Error message returned:  " + err.message.toString());
            }
            else
            {
                errDescription = 'Error caught!  Problem generating lineups from server. ' + err.message.toString();
            }
            setErrMessage(errDescription);
            setIsLoading(false);
        } finally {
           setIsLoading(false);
        }
    }

/*************************************************************************************/
/*                      USE-EFFECT() AND HTML RETURN                                 */
/*************************************************************************************/

    useEffect (() => {

        // Get query params to know what slate was selected
        const queryParams = new URLSearchParams(window.location.search);
        const slateID = parseInt(queryParams.get("slateID"));
        const oneVsManyParam = queryParams.get("lineups");
        const dirtyBackDoor = queryParams.get("lineup");

        if (slateID > 0)
        {
            getSlates(slateID); // this is for validation before loading the actual player data on the next step
            setStep(2);
            setShowPrevBtn(true);

            if (oneVsManyParam !== null && oneVsManyParam.length > 0)
                { setOneVsMany(oneVsManyParam); }
            else 
                { setOneVsMany("One"); } // Default to 'One' lineup if this variable isn't passed

            // quick way for me to modify query string and hack 's' off lineups... this skips awakening Azure (i'm frugal, not cheap!!)
            if (dirtyBackDoor !== null && dirtyBackDoor.length > 0) // && dirtyBackDoor.length > 0) 
                {setBackdoor(true);}

            /* Now time to load most of the master player and also slate data from Azure CSV files -- See step summary comments up top for details */
            getSlateAndLineupPlayerData(slateID);
            getMasterPlayerData(); 
        }
        else
        {
            setStep(1);
            setShowPrevBtn(true);
            setShowNextBtn(false);
            setErrMessage('Error! Slate not found. Hit "Back" and try again or contact support.');
        }
    }, []
    );

    return (
        <div className="content">
        <div className = "home">
          
            {
            (step===2) &&
                <h3>Min Salary
                    <Tooltip
                        title="% of site's salary cap"
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                </h3>
            }
             {
            (step===2) &&
                <Slider id="minSalarySlider" defaultValue={95} minValue={85} maxValue={100} stepAmount={0.5}
                value={salaryMin}
                onChange={handleChangeMinSalary}
                charDisplay="%"
                >
                </Slider>
            }
       {
            (step===2 && oneVsMany === "Many") &&
                <br></br>
            }
            {
            (step===2 && oneVsMany === "Many") &&
                <h3>Number of Lineups</h3>
            }
            {
            (step===2 && oneVsMany === "Many") &&
                <Slider id="numberOfLineups" defaultValue={fullSinglLineupGenerate} minValue={2} maxValue={150} stepAmount={1}
                value={numbOfLineups}
                onChange={handleChangeNumbOfLineups}
                charDisplay=""
                >
                </Slider>
            }
           
            {
            (step===2) &&
                <Accordion allowZeroExpanded preExpanded={['a']}>
                    <AccordionItem uuid="a">
                        <AccordionItemHeading>
                            <AccordionItemButton >
                                Additional Options
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        
                        <AccordionItemPanel >
                            <h3>Stacking
                                <Tooltip
                                    title="Leave blank if stacking isn't required.  Stack hitters from up to 3 teams across 2 games!  Two hitters from a team are considered 'stacked' if they are 'X' lineup spots away from each other.  You choose that 'X' below.  For example, a hitter from the 8th spot is 2 lineup spots away from the lead-off hitter."
                                    placement="top"
                                >
                                    <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                                </Tooltip>
                            </h3>
                            <div>
                                <h6><Checkbox
                                        label=""
                                        value={checkedStackPrimary}
                                        onChange={handleChangeCheckedStackPrimary}
                                    />&nbsp;Primary Game</h6>
                                {
                                    (checkedStackPrimary) &&
                                    <label className="divIndent">Team 1:</label>
                                }
                                {
                                    (checkedStackPrimary) &&
                                    <br></br>
                                }
                                {
                                    (checkedStackPrimary) &&    
                                <div className="divIndentLots" >
                                    <button className="buttonRemove" onClick={decrementPrimaryStackMinCount}></button>                            
                                        &nbsp;{stackPrimaryTeamMinHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementPrimaryStackMinCount}></button>
                                        &nbsp; to &nbsp;
                                    <button className="buttonRemove" onClick={decrementPrimaryStackMaxCount}></button>                            
                                        &nbsp;{stackPrimaryTeamMaxHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementPrimaryStackMaxCount}></button>
                                    &nbsp; hitters &nbsp;
                                </div>
                                }
                               
                                {
                                    (checkedStackPrimary) &&
                                    <label className="divIndent">Team 1's opponent:</label>
                                }
                                {
                                    (checkedStackPrimary) &&
                                    <br></br>
                                }
                                {
                                    (checkedStackPrimary) &&
                                  <div className="divIndentLots" >
                                    <button className="buttonRemove" onClick={decrementPrimaryStackOppMinCount}></button>                            
                                        &nbsp;{stackPrimaryTeamOppMinHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementPrimaryStackOppMinCount}></button>
                                        &nbsp; to &nbsp;
                                    <button className="buttonRemove" onClick={decrementPrimaryStackOppMaxCount}></button>                            
                                        &nbsp;{stackPrimaryTeamOppMaxHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementPrimaryStackOppMaxCount}></button>
                                    &nbsp; hitters &nbsp;
                                  </div>
                                }
                                {
                                    (checkedStackPrimary) &&
                                    <div className="blueLineFaintShort"></div>
                                }
                                {
                                    (checkedStackPrimary) &&
                                    <br></br>
                                }                                
                                {
                                    (checkedStackPrimary) &&
                                <h6><Checkbox
                                        label=""
                                        value={checkedStackSecondary}
                                        onChange={handleChangeCheckedStackSecondary}
                                    />&nbsp;Secondary Game (as room allows)</h6>
                                }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) &&
                                    <label className="divIndent">Team 2:</label>
                                }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) &&
                                    <br></br>
                                }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) &&
                                <div className="divIndentLots" >
                                    <button className="buttonRemove" onClick={decrementSecondaryStackMinCount}></button>                            
                                        &nbsp;{stackSecondaryTeamMinHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementSecondaryStackMinCount}></button>
                                        &nbsp; to &nbsp;
                                    <button className="buttonRemove" onClick={decrementSecondaryStackMaxCount}></button>                            
                                        &nbsp;{stackSecondaryTeamMaxHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementSecondaryStackMaxCount}></button>
                                    &nbsp; hitters &nbsp;
                                </div>
                                }
                              
                                 {
                                    (checkedStackPrimary) && (checkedStackSecondary) && (1===2) &&
                                    <label className="divIndent">Team 2's opponent:</label>
                                 }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) && (1===2) &&
                                    <br></br>
                                }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) && (1===2) &&
                                <div className="divIndentLots" >
                                    <button className="buttonRemove" onClick={decrementSecondaryStackOppMinCount}></button>                            
                                        &nbsp;{stackSecondaryTeamOppMinHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementSecondaryStackOppMinCount}></button>
                                        &nbsp; to &nbsp;
                                    <button className="buttonRemove" onClick={decrementSecondaryStackOppMaxCount}></button>                            
                                        &nbsp;{stackSecondaryTeamOppMaxHitters}&nbsp;
                                    <button className="buttonAdd" onClick={incrementSecondaryStackOppMaxCount}></button>
                                    &nbsp; hitters &nbsp;
                                </div>
                                }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) &&
                                    <div className="blueLineFaintShort"></div>
                                }
                                {
                                    (checkedStackPrimary) && (checkedStackSecondary) &&
                                    <br></br>
                                }
                                {
                                    (checkedStackPrimary) &&
                                <div display="inline">
                                    <label className="divIndent">Max lineup spots away:</label>&nbsp; 
                                    <button className="buttonRemove" onClick={decrementStackLineupSpotsAway}></button>                            
                                        &nbsp;{stackLineupSpotsAway}&nbsp;
                                    <button className="buttonAdd" onClick={incrementStackLineupSpotsAway}></button>  
                                </div>
                                }
                            </div>
                            <br></br>
                            <h3>Defense
                            <Tooltip
                                    title="It's not optimal to have hitters go against your pitcher(s)."
                                    placement="top"
                                >
                                    <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                                </Tooltip>
                            </h3>
                            <div>
                            <label className="divIndent">Exclude hitters vs your pitchers</label>
                            <Checkbox
                                        label=""
                                        value={checkedExcludeVsDef}
                                        onChange={handleChangeCheckedExcludeVsDef}
                                    />
                            </div>
                            <br></br>
                            <h3>Limits
                                <Tooltip
                                    title="A common MLB maximum on DFS platforms is '5'."
                                    placement="top"
                                >
                                    <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                                </Tooltip>
                            </h3>
                            <div display="inline">
                                <label className="divIndent">Max players from same team</label>&nbsp; 
                                <button className="buttonRemove" onClick={decrementMaxPlayerCount}></button>                            
                                &nbsp;{countMaxPlayers}&nbsp;
                                <button className="buttonAdd" onClick={incrementMaxPlayerCount}></button>  
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            }
           
           {(step===4) && displayOneLineup && showNextBtnSingleLineup &&
                <div className="div-flexRight">
                    <button className="button" onClick={handleNextSingleLineup}>Next Lineup</button>
                 </div>
            }
            {(step===4) && displayOneLineup && 
             <br></br>
            }
            {(step===4) && displayOneLineup && successMessage &&
             <br></br>
            }
            {errMessage&& (
                <p className="error"> {errMessage} </p>
                )
            }
             {successMessage&& (
                <p className="success"> {successMessage} </p>
                )
            }
            {successMessage && (step===4) && displayOneLineup && 
            <br></br>
            }
            {warningMessage&& (
                <p className="warning"> {warningMessage} </p>
                )
            }
            {isLoading&& (
                <p className="loading"> Loading... </p>
                )
            }

           
            {
            (step===3) && !isLoading &&
             
                    <h3>Players
                    <Tooltip
                        title="Rank is the likelihood the player will be in a lineup.  Players with no stars will be excluded from the result set.  Expected starters are shown with yellow lineup order images.  Confirmed are green."
                        placement="top"
                    >
                        <img src="/toolTip.png" className="toolTipImage" variant="contained" alt="Tool Tip"></img>
                    </Tooltip>
                    </h3>
              
                
            }

            {
           (step===3) && !isLoading &&
                    <div className="divInlineAndHeight">
                           {/*<Select className="custom-select-teams" options={ddlTeams} label="Choose Team" value={ddlTeamSelected} onChange={ddlTeamChangeHandler} ></Select> */}
                           <label>
                             Team Filter
                            </label>
                            <br></br>
                            <div display="inline">
                           <Select
                              
                                isMulti
                                name="label"
                                options={ddlTeams}
                                className="custom-select-teams" /*basic-multi-select*/
                                classNamePrefix="select"
                                onChange={ddlTeamChangeHandler}
                                isSearchable={false}
                            />
                                    <div className="div-flexRightGenerate-MLB">
                                <button className="button" onClick={handleGenerateClick}>Generate</button>
                                
                                    </div>
                            </div>
                    </div>
            }
            {
            (step===3) && activePositionChoice === 'Hitters' && !isLoading &&
                <div className="divMLBPosFilter" >
                   
                    <label className='responsiveNoDisplay'>
                             Position Filter
                    </label>
                    
                    <Checkbox
                        label=" C"
                        value={checkedFilterC}
                        onChange={handleChangeCheckedFilterC}
                    />
                    <Checkbox
                        label=" 1B"
                        value={checkedFilter1B}
                        onChange={handleChangeCheckedFilter1B}
                    />
                    <Checkbox
                        label=" 2B"
                        value={checkedFilter2B}
                        onChange={handleChangeCheckedFilter2B}
                    /> 
                     <Checkbox
                        label=" 3B"
                        value={checkedFilter3B}
                        onChange={handleChangeCheckedFilter3B}
                    />
                    <Checkbox
                        label=" SS"
                        value={checkedFilterSS}
                        onChange={handleChangeCheckedFilterSS}
                    />
                    <Checkbox
                        label=" OF"
                        value={checkedFilterOF}
                        onChange={handleChangeCheckedFilterOF}
                    />
                </div>
            }
            {
                (step===3) && activePositionChoice === 'Hitters' && <br></br>
            }
            {isLoading && <br></br>}
            {isLoading && <br></br>}
            {isLoading && <br></br>}
            {isLoading && <br></br>}
            {
            (step===3) && !isLoading &&
                <div className="divInlineAlignLeft">
                
                            <Checkbox
                            label=""
                            value={checkedAddRemoveNonStarters}
                            onChange={handleAddRemoveNonStarters}
                            />

                            <label className="labelItalicMLB" onClick={handleAddRemoveNonStarters}>Include non-starters
                            </label>
                 
                </div>
            }
           
            {
                (step===3) && <br></br>
            }
            {
            (step===3) &&
                <div className="divInlineAndHeight">
                    <ButtonGroup>
                        {positionTypes.map(type => (
                        <Button
                            key={type}
                            activePositionChoice={activePositionChoice === type}
                            onClick={() => handleClickPosChoice(type)}
                            >
                                {type}
                        </Button>
                        ))}
                    </ButtonGroup>
                   
                </div>
             }

            {
           (step===3) && !isLoading &&
                             <div className="div-quickRankMLB">
                                <button className="buttonQuickRankMLB" onClick={handleQuickRank}>Quick Rate</button>
                                </div>
            }

            {
            (step===3) &&
                <MLBFullPlayerList players={playerPool.filter((player) => (player['Roster Position'] === "P" && activePositionChoice === 'Pitchers') || (player['Roster Position'] !== 'P' && activePositionChoice === 'Hitters'))} title="Players" setRatingValue = {setRatingValue} getRating = {getRating} getImage = {getImage} getOppPitcherName = {getOppPitcherName} getOppPitcherStats = {getOppPitcherStats} getOppClassRank = {getOppClassRank} getInjuryStatus = {getInjuryStatus} setStackValue = {setStackValue} getStack = {getStack} getStackClass = {getStackClass} setLockValue = {setLockValue} getLock = {getLock} getPlayerHand = {getPlayerHand} getLineupSpotImage = {getLineupSpotImage} getPlayerStats = {getPlayerStats} getGameOpp = {getGameOpp} getGameTime = {getGameTime}></MLBFullPlayerList>
            }
             {
            (step===3) &&
            <br></br>
             }
             {(step===4) && displayPlayerSummary && 
                <MLBFullLineupList lineups={lineupPlayerSummary} lineupExport={lineupExport} getImage = {getImage} sport={sport} site={siteOfSlate} lineupCount={numbOfLineups} getRatingImage={getRatingImage} getRatingImageClass={getRatingImageClass}></MLBFullLineupList>
            }

            {(step===4) && displayOneLineup && 
             <br></br>
            }
             {(step===4) && displayOneLineup && 
                <MLBFullSingleLineup players={lineupExport} lineupCounter={oneLineupCounter} getImage = {getImage} getInjuryStatus = {getInjuryStatus} sport={sport} site={siteOfSlate} getLockByNameAndID = {getLockByNameAndID} getLineupSpotImage = {getLineupSpotImage}></MLBFullSingleLineup>
            }

             {(step===2 || step===4) &&
             <br></br>
            }
            
            {
            showPrevBtn &&
                <div className="div-flexLeft">
                <button className="button" onClick={handlePreviousClick}>Back</button>
                </div>
                }
            {
            showNextBtn &&
                <div className="div-flexRight">
                <button className="button" onClick={handleNextClick}>Next</button>
                </div>
            }
            {(step===4) && displayOneLineup && showNextBtnSingleLineup &&
                <div className="div-flexRight">
                    <button className="button" onClick={handleNextSingleLineup}>Next Lineup</button>
                 </div>
            }
            
        </div>
        </div>
    );
}
export default HomeMLBFull;