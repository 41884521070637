import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {CSVLink} from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { RoundaboutLeft } from '@mui/icons-material';

const PGAFullSingleLineup = ({players, lineupCounter, getImage, getInjuryStatus, sport, site, getLockByNameAndID}) => {
  
const csvHeaders = [
        { label: 'G', key: 'G1'},
        { label: 'G', key: 'G2'},
        { label: 'G', key: 'G3'},
        { label: 'G', key: 'G4'},
        { label: 'G', key: 'G5'},
        { label: 'G', key: 'G6'}
    ];

    // Get site salary max
    let maxSiteSalary = 0;
    if (site === 'DK')
    {
        maxSiteSalary = 50000;
    }
    else if (site === 'FD') 
    {
        maxSiteSalary = 60000;
    }

    /*Get the lineup and salary info to display */
    let oneLineupDisplay = players[lineupCounter]; //object
    let lineupSalary = oneLineupDisplay.G1Salary + oneLineupDisplay.G2Salary + oneLineupDisplay.G3Salary +
                        oneLineupDisplay.G4Salary + oneLineupDisplay.G5Salary + oneLineupDisplay.G6Salary;

    // format the salaries (for commas)
    const options = {  maximumFractionDigits: 2   }
    const formattedLineupSalary = Intl.NumberFormat("en-US",options).format(lineupSalary);
    const salaryLeft = Intl.NumberFormat("en-US",options).format(maxSiteSalary - lineupSalary);
  
    /*Get the lineup to export */
    let exportOneLineup = players.slice(lineupCounter,lineupCounter+1); // array

    // Set up the 'export' link
    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const randomNumber = parseInt(Math.random() * 100000); // do make the download unique (hopefully)

    const csvReport = {
    filename: 'Lineup_' + sport + '_' + site + '_' + month.toString() + '_' + date.toString() + '_' + year.toString() + '_' + randomNumber.toString() + '.csv',
    headers: csvHeaders,
    data: exportOneLineup
    }; 

    return (
        <div className="lineup-list-singleLineup-PGA"> 
     <div className = "div-flexLeft">
                    <CSVLink {...csvReport} className="buttonDownload">Export Lineup</CSVLink> 
            </div> 
           <div className = "div-flexRight">
                <b>Salary Used:</b>&nbsp;&nbsp; <div className='divBlue'>{formattedLineupSalary}</div>
          </div>
         
            <div className = "div-flexRight">
                    <b>Salary Left:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <div className='divPink'>{salaryLeft}</div>     
            </div>
            <br></br>
                <div className="outlet">
                        <div className="lineup-view-singleLineup-PGA">
                            <div className="lineup-item-singleLineup-center"><b>Pos</b></div>
                            <div className="lineup-item-singleLineup"><b></b></div>
                            <div className="lineup-item-singleLineup"><b>Player</b></div>
                            <div className="lineup-item-singleLineup"><b></b></div>
                            <div className="lineup-item-singleLineup-responsive"><b>Tourney</b></div>
                            <div className="lineup-item-singleLineup"><b>Salary</b></div>
                        </div>
                        <div className="blueLinePGA"> </div>
                            <div className = "lineup-view-singleLineup-PGA">
                                <div className="lineup-item-singleLineup-Border-Golfer-center"><b>G1</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.G1)}>
                                        <br></br>
                                       
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                         
                                        />
                                      
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.G1Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-singleLineup-Border-Golfer">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.G1Name)}</div>
                                    {oneLineupDisplay.G1Name}</div>
                                <div className="lineup-item-singleLineup-gamedate-PGA">{oneLineupDisplay.G1GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin">{oneLineupDisplay.G1Salary}</div>

                            </div>
                            <div className = "lineup-view-singleLineup-PGA">
                                <div className="lineup-item-singleLineup-Border-Golfer-center"><b>G2</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.G2)}>
                                        <br></br>
                                       
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                         
                                        />
                                      
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.G2Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-singleLineup-Border-Golfer">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.G2Name)}</div>
                                    {oneLineupDisplay.G2Name}</div>
                                <div className="lineup-item-singleLineup-gamedate-PGA">{oneLineupDisplay.G2GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin">{oneLineupDisplay.G2Salary}</div>

                            </div>
                            <div className = "lineup-view-singleLineup-PGA">
                                <div className="lineup-item-singleLineup-Border-Golfer-center"><b>G3</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.G3)}>
                                        <br></br>
                                       
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                         
                                        />
                                      
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.G3Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-singleLineup-Border-Golfer">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.G3Name)}</div>
                                    {oneLineupDisplay.G3Name}</div>
                                <div className="lineup-item-singleLineup-gamedate-PGA">{oneLineupDisplay.G3GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin">{oneLineupDisplay.G3Salary}</div>

                            </div>
                            <div className = "lineup-view-singleLineup-PGA">
                                <div className="lineup-item-singleLineup-Border-Golfer-center"><b>G4</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.G4)}>
                                        <br></br>
                                       
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                         
                                        />
                                      
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.G4Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-singleLineup-Border-Golfer">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.G4Name)}</div>
                                    {oneLineupDisplay.G4Name}</div>
                                <div className="lineup-item-singleLineup-gamedate-PGA">{oneLineupDisplay.G4GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin">{oneLineupDisplay.G4Salary}</div>

                            </div>
                            <div className = "lineup-view-singleLineup-PGA">
                                <div className="lineup-item-singleLineup-Border-Golfer-center"><b>G5</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.G5)}>
                                        <br></br>
                                       
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                         
                                        />
                                      
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.G5Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-singleLineup-Border-Golfer">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.G5Name)}</div>
                                    {oneLineupDisplay.G5Name}</div>
                                <div className="lineup-item-singleLineup-gamedate-PGA">{oneLineupDisplay.G5GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin">{oneLineupDisplay.G5Salary}</div>

                            </div>
                            <div className = "lineup-view-singleLineup-PGA">
                                <div className="lineup-item-singleLineup-Border-Golfer-center"><b>G6</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.G6)}>
                                        <br></br>
                                       
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                         
                                        />
                                      
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.G6Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-singleLineup-Border-Golfer">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.G6Name)}</div>
                                    {oneLineupDisplay.G6Name}</div>
                                <div className="lineup-item-singleLineup-gamedate-PGA">{oneLineupDisplay.G6GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin">{oneLineupDisplay.G6Salary}</div>

                            </div>
                     
                </div>
        </div>
    );
}
export default PGAFullSingleLineup;