import React from 'react';
import { useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {CSVLink} from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { RoundaboutLeft } from '@mui/icons-material';
//import html2canvas from "html2canvas";
//import jsPDF from "jspdf";
import { toPng } from 'html-to-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faImage} from '@fortawesome/free-solid-svg-icons';

const NFLFullSingleLineup = ({players, lineupCounter, getImage, getInjuryStatus, sport, site, getLockByNameAndID, getPlayerSearchURL, getOppRank, getOppClassRank, getFunnel}) => {
  
var csvHeaders = [];

if (site === 'DK')
{
    csvHeaders =   [
        { label: 'QB', key: 'QB'},
        { label: 'RB', key: 'RB1'},
        { label: 'RB', key: 'RB2'},
        { label: 'WR', key: 'WR1'},
        { label: 'WR', key: 'WR2'},
        { label: 'WR', key: 'WR3'},
        { label: 'TE', key: 'TE'},
        { label: 'FLEX', key: 'FLEX'},
        { label: 'DST', key: 'DST'}
    ];
}
else // only FD right now
{
    csvHeaders =   [
        { label: 'QB', key: 'QB'},
        { label: 'RB', key: 'RB1'},
        { label: 'RB', key: 'RB2'},
        { label: 'WR', key: 'WR1'},
        { label: 'WR', key: 'WR2'},
        { label: 'WR', key: 'WR3'},
        { label: 'TE', key: 'TE'},
        { label: 'FLEX', key: 'FLEX'},
        { label: 'DEF', key: 'DST'}
    ];
}

    // Get site salary max
    let maxSiteSalary = 0;
    if (site === 'DK')
    {
        maxSiteSalary = 50000;
    }
    else if (site === 'FD') 
    {
        maxSiteSalary = 60000;
    }

    /*Get the lineup and salary info to display */
    let oneLineupDisplay = players[lineupCounter]; //object
    let lineupSalary = oneLineupDisplay.QBSalary + oneLineupDisplay.RB1Salary + oneLineupDisplay.RB2Salary +
                        oneLineupDisplay.WR1Salary + oneLineupDisplay.WR2Salary + oneLineupDisplay.WR3Salary +
                        oneLineupDisplay.TESalary + oneLineupDisplay.FLEXSalary + oneLineupDisplay.DSTSalary;

    // format the salaries (for commas)
    const options = {  maximumFractionDigits: 2   }
    const formattedLineupSalary = Intl.NumberFormat("en-US",options).format(lineupSalary);
    const salaryLeft = Intl.NumberFormat("en-US",options).format(maxSiteSalary - lineupSalary);

    // figure out if there is a MAIN stack
    let QBStack = false,
        RB1Stack = false,
        RB2Stack = false,
        WR1Stack = false,
        WR2Stack = false,
        WR3Stack = false,
        TEStack = false,
        FLEXStack = false,
        DSTStack = false;
    
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.RB1GameInfo) { RB1Stack = true;}
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.RB2GameInfo) { RB2Stack = true;}
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.WR1GameInfo) { WR1Stack = true;}
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.WR2GameInfo) { WR2Stack = true;}
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.WR3GameInfo) { WR3Stack = true;}
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.TEGameInfo) { TEStack = true;}
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.FLEXGameInfo) { FLEXStack = true;} 
    if (oneLineupDisplay.QBGameInfo === oneLineupDisplay.DSTGameInfo) { DSTStack = true;} 

    if (RB1Stack === true || RB2Stack === true || WR1Stack === true || WR2Stack === true ||
        WR3Stack === true || TEStack === true || FLEXStack === true)
        {
            QBStack = true;
        }

    // in rare case where it's a QB/DST stack, don't show anything
    if (DSTStack === true && QBStack === false)
    {
        DSTStack = false;
    }
  
    // figure out if there is a MINI stack
        let RB1MiniStack = '',
        RB2MiniStack = '',
        WR1MiniStack = '',
        WR2MiniStack = '',
        WR3MiniStack = '',
        TEMiniStack = '',
        FLEXMiniStack = '',
        DSTMiniStack = '';
        
            //RB1 part of mini?
            if (oneLineupDisplay.RB1GameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.RB1GameInfo === oneLineupDisplay.RB2GameInfo || oneLineupDisplay.RB1GameInfo === oneLineupDisplay.WR1GameInfo
                    || oneLineupDisplay.RB1GameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.RB1GameInfo === oneLineupDisplay.WR3GameInfo
                    || oneLineupDisplay.RB1GameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.RB1GameInfo === oneLineupDisplay.FLEXGameInfo
                    || oneLineupDisplay.RB1GameInfo === oneLineupDisplay.DSTGameInfo
                    ) { RB1MiniStack = 'Mini'; RB1Stack = true;}
            }
            //RB2 part of mini?
            if (oneLineupDisplay.RB2GameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.RB2GameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.RB2GameInfo === oneLineupDisplay.WR1GameInfo
                    || oneLineupDisplay.RB2GameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.RB2GameInfo === oneLineupDisplay.WR3GameInfo
                    || oneLineupDisplay.RB2GameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.RB2GameInfo === oneLineupDisplay.FLEXGameInfo
                    || oneLineupDisplay.RB2GameInfo === oneLineupDisplay.DSTGameInfo
                    ) { RB2MiniStack = 'Mini'; RB2Stack = true;}
            }
            //WR1 part of mini?
            if (oneLineupDisplay.WR1GameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.WR1GameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.WR1GameInfo === oneLineupDisplay.RB2GameInfo
                    || oneLineupDisplay.WR1GameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.WR1GameInfo === oneLineupDisplay.WR3GameInfo
                    || oneLineupDisplay.WR1GameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.WR1GameInfo === oneLineupDisplay.FLEXGameInfo
                    || oneLineupDisplay.WR1GameInfo === oneLineupDisplay.DSTGameInfo
                    ) { WR1MiniStack = 'Mini'; WR1Stack = true;}
            }
            //WR2 part of mini?
            if (oneLineupDisplay.WR2GameInfo !== oneLineupDisplay.QBGameInfo)
                {
                    if (oneLineupDisplay.WR2GameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.WR2GameInfo === oneLineupDisplay.RB2GameInfo
                        || oneLineupDisplay.WR2GameInfo === oneLineupDisplay.WR1GameInfo || oneLineupDisplay.WR2GameInfo === oneLineupDisplay.WR3GameInfo
                        || oneLineupDisplay.WR2GameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.WR2GameInfo === oneLineupDisplay.FLEXGameInfo
                        || oneLineupDisplay.WR2GameInfo === oneLineupDisplay.DSTGameInfo
                        ) { WR2MiniStack = 'Mini'; WR2Stack = true;}
                }
            //WR3 part of mini?
            if (oneLineupDisplay.WR3GameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.WR3GameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.WR3GameInfo === oneLineupDisplay.RB2GameInfo
                    || oneLineupDisplay.WR3GameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.WR3GameInfo === oneLineupDisplay.WR1GameInfo
                    || oneLineupDisplay.WR3GameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.WR3GameInfo === oneLineupDisplay.FLEXGameInfo
                    || oneLineupDisplay.WR3GameInfo === oneLineupDisplay.DSTGameInfo
                    ) { WR3MiniStack = 'Mini'; WR3Stack = true;}
            }
            //TE part of mini?
            if (oneLineupDisplay.TEGameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.TEGameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.TEGameInfo === oneLineupDisplay.RB2GameInfo
                    || oneLineupDisplay.TEGameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.TEGameInfo === oneLineupDisplay.WR1GameInfo
                    || oneLineupDisplay.TEGameInfo === oneLineupDisplay.WR3GameInfo || oneLineupDisplay.TEGameInfo === oneLineupDisplay.FLEXGameInfo
                    || oneLineupDisplay.TEGameInfo === oneLineupDisplay.DSTGameInfo
                    ) { TEMiniStack = 'Mini'; TEStack = true;}
            }
            //FLEX part of mini?
            if (oneLineupDisplay.FLEXGameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.RB2GameInfo
                    || oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.WR1GameInfo
                    || oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.WR3GameInfo
                    || oneLineupDisplay.FLEXGameInfo === oneLineupDisplay.DSTGameInfo
                    ) { FLEXMiniStack = 'Mini'; FLEXStack = true;}
            }
            //DST part of mini?
            if (oneLineupDisplay.DSTGameInfo !== oneLineupDisplay.QBGameInfo)
            {
                if (oneLineupDisplay.DSTGameInfo === oneLineupDisplay.RB1GameInfo || oneLineupDisplay.DSTGameInfo === oneLineupDisplay.RB2GameInfo
                    || oneLineupDisplay.DSTGameInfo === oneLineupDisplay.WR2GameInfo || oneLineupDisplay.DSTGameInfo === oneLineupDisplay.WR1GameInfo
                    || oneLineupDisplay.DSTGameInfo === oneLineupDisplay.TEGameInfo || oneLineupDisplay.DSTGameInfo === oneLineupDisplay.WR3GameInfo
                    || oneLineupDisplay.DSTGameInfo === oneLineupDisplay.FLEXGameInfo
                    ) { DSTMiniStack = 'Mini'; DSTStack = true;}
            }

    /*Get the lineup to export */
    let exportOneLineup = players.slice(lineupCounter,lineupCounter+1); // array

    // Set up the 'export' link
    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const randomNumber = parseInt(Math.random() * 100000); // do make the download unique (hopefully)

    const csvReport = {
    filename: 'Lineup_' + sport + '_' + site + '_' + month.toString() + '_' + date.toString() + '_' + year.toString() + '_' + randomNumber.toString() + '.csv',
    headers: csvHeaders,
    data: exportOneLineup
    }; 

const elementRef = useRef(null); // for downloading lineup image

const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-image-name.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
    return (
        <div className="lineup-list-singleLineup"> 
            <div className = "div-inline">
                    <CSVLink {...csvReport} className="buttonDownload">Export Lineup</CSVLink> 
            </div>

           <div className = "div-flexRight">
                <b>Salary Used:</b>&nbsp;&nbsp; <div className='divBlue'>{formattedLineupSalary}</div>
          </div>
          
            <div className = "div-flexRight">
                    <b>Salary Left:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <div className='divPink'>{salaryLeft}</div>     
            </div>
           <br></br>
                <div className="ScreenShotSingleLineup" ref={elementRef}>
                        <div className="lineup-view-singleLineup-NFL-col">
                            <div className="lineup-item-singleLineup-center"><b>Pos</b></div>
                            <div className="lineup-item-singleLineup"><b></b></div>
                            <div className="lineup-item-singleLineup"><b>Player</b></div>
                            <div className="lineup-item-singleLineup"><b></b></div>
                            <div className="lineup-item-singleLineup"><b></b></div>
                            <div className="lineup-item-singleLineup"><b></b></div>
                            <div className="lineup-item-singleLineup-center"><b>Game</b></div>
                            <div className="lineup-item-singleLineup-center"><b>Salary</b></div>
                            <div className="lineup-item-singleLineup">
                                    <Image 
                                        src= {"BreezyImageDownload.png"}
                                        className={"ScreenShotIcon"}
                                        
						                onClick={htmlToImageConvert}
                                    />
                            </div>
                            {/* <div className="lineup-item-singleLineup"><FontAwesomeIcon icon={faImage} style={{color: "#1e90ff",}} className="ScreenShotIcon" onClick={htmlToImageConvert}></FontAwesomeIcon></div> */}
                        </div>
                        <div className="blueLine"> </div>
                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-QB"><b>QB</b></div>
                                <div></div>
                                <div className="lineup-item-singleLineup">
                                    <div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.QBName, oneLineupDisplay.QBTeam, "QB")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.QBName + ' ('  + oneLineupDisplay.QBTeam + ')')}
                                            className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.QBName + ' ('  + oneLineupDisplay.QBTeam + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.QBName, oneLineupDisplay.QBTeam, "QB")} target="_blank">
                                        {oneLineupDisplay.QBName.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.QBName.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.QBName.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.QBName}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.QBTeam, oneLineupDisplay.QBGameInfo, 'QB')}>
                                                {getOppRank(oneLineupDisplay.QBTeam, oneLineupDisplay.QBGameInfo, 'QB')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.QBTeam, oneLineupDisplay.QBGameInfo, 'QB') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.QBTeam + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.QBGameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.QBSalary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= "Stack.png"
                                            className={"lineup-item-singleLineup-StackImage-" + QBStack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-RB"><b>RB</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.RB1)}>
                                        
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <a href={getPlayerSearchURL(oneLineupDisplay.RB1Name, oneLineupDisplay.RB1Team, "RB")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.RB1Name + ' ('  + oneLineupDisplay.RB1Team + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                        </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.RB1Name + ' ('  + oneLineupDisplay.RB1Team + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.RB1Name, oneLineupDisplay.RB1Team, "RB")} target="_blank">
                                        {oneLineupDisplay.RB1Name.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.RB1Name.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.RB1Name.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.RB1Name}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.RB1Team, oneLineupDisplay.RB1GameInfo, 'RB')}>
                                                {getOppRank(oneLineupDisplay.RB1Team, oneLineupDisplay.RB1GameInfo, 'RB')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.RB1Team, oneLineupDisplay.RB1GameInfo, 'RB') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.RB1Team + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.RB1GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.RB1Salary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"Stack" + RB1MiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + RB1Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-RB"><b>RB</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.RB2)}>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.RB2Name, oneLineupDisplay.RB2Team, "RB")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.RB2Name + ' ('  + oneLineupDisplay.RB2Team + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.RB2Name + ' ('  + oneLineupDisplay.RB2Team + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.RB2Name, oneLineupDisplay.RB2Team, "RB")} target="_blank">
                                    {oneLineupDisplay.RB2Name.length > 20 &&
                                        <div className="divBoldVerySmall">{oneLineupDisplay.RB2Name.substring(0,12) + '..'}</div>
                                    }
                                     {oneLineupDisplay.RB2Name.length <= 20 &&
                                        <div className="divBoldVerySmall">{oneLineupDisplay.RB2Name}
                                        </div>
                                    }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.RB2Team, oneLineupDisplay.RB2GameInfo, 'RB')}>
                                                {getOppRank(oneLineupDisplay.RB2Team, oneLineupDisplay.RB2GameInfo, 'RB')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                         src= {getFunnel(oneLineupDisplay.RB2Team, oneLineupDisplay.RB2GameInfo, 'RB') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.RB2Team + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.RB2GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.RB2Salary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"Stack" + RB2MiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + RB2Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-WR"><b>WR</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.WR1)}>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.WR1Name, oneLineupDisplay.WR1Team, "WR")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.WR1Name + ' ('  + oneLineupDisplay.WR1Team + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.WR1Name + ' ('  + oneLineupDisplay.WR1Team + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.WR1Name, oneLineupDisplay.WR1Team, "WR")} target="_blank">
                                        {oneLineupDisplay.WR1Name.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.WR1Name.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.WR1Name.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.WR1Name}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.WR1Team, oneLineupDisplay.WR1GameInfo, 'WR')}>
                                                {getOppRank(oneLineupDisplay.WR1Team, oneLineupDisplay.WR1GameInfo, 'WR')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.WR1Team, oneLineupDisplay.WR1GameInfo, 'WR') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.WR1Team + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.WR1GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.WR1Salary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        
                                        <Image 
                                            src= {"Stack" + WR1MiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + WR1Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-WR2"><b>WR</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.WR2)}>
                                    <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                    />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.WR2Name, oneLineupDisplay.WR2Team, "WR")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.WR2Name + ' ('  + oneLineupDisplay.WR2Team + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.WR2Name + ' ('  + oneLineupDisplay.WR2Team + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.WR2Name, oneLineupDisplay.WR2Team, "WR")} target="_blank">
                                        {oneLineupDisplay.WR2Name.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.WR2Name.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.WR2Name.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.WR2Name}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.WR2Team, oneLineupDisplay.WR2GameInfo, 'WR')}>
                                                {getOppRank(oneLineupDisplay.WR2Team, oneLineupDisplay.WR2GameInfo, 'WR')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.WR2Team, oneLineupDisplay.WR2GameInfo, 'WR') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.WR2Team + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.WR2GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.WR2Salary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        
                                        <Image 
                                            src= {"Stack" + WR2MiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + WR2Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-WR"><b>WR</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.WR3)}>
                                    <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                    />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.WR3Name, oneLineupDisplay.WR3Team, "WR")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.WR3Name + ' ('  + oneLineupDisplay.WR3Team + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.WR3Name + ' ('  + oneLineupDisplay.WR3Team + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.WR3Name, oneLineupDisplay.WR3Team, "WR")} target="_blank">
                                        {oneLineupDisplay.WR3Name.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.WR3Name.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.WR3Name.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.WR3Name}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.WR3Team, oneLineupDisplay.WR3GameInfo, 'WR')}>
                                                {getOppRank(oneLineupDisplay.WR3Team, oneLineupDisplay.WR3GameInfo, 'WR')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.WR3Team, oneLineupDisplay.WR3GameInfo, 'WR') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.WR3Team + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.WR3GameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.WR3Salary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        
                                        <Image 
                                            src= {"Stack" + WR3MiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + WR3Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>

                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-TE"><b>TE</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.TE)}>
                                    <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                    />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.TEName, oneLineupDisplay.TETeam, "TE")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.TEName + ' ('  + oneLineupDisplay.TETeam + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.TEName + ' ('  + oneLineupDisplay.TETeam + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.TEName, oneLineupDisplay.TETeam, "TE")} target="_blank">
                                        {oneLineupDisplay.TEName.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.TEName.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.TEName.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.TEName}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.TETeam, oneLineupDisplay.TEGameInfo, 'TE')}>
                                                {getOppRank(oneLineupDisplay.TETeam, oneLineupDisplay.TEGameInfo, 'TE')}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.TETeam, oneLineupDisplay.TEGameInfo, 'TE') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.TETeam + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.TEGameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.TESalary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        
                                        <Image 
                                            src= {"Stack" + TEMiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + TEStack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>

                            <div className = "lineup-view-singleLineup-NFL">
                                <div className={"lineup-item-singleLineup-Border-" + oneLineupDisplay.FLEXPosition}><b>{'Flex (' + oneLineupDisplay.FLEXPosition + ')'}</b></div>
                                <div className={"lineup-item-singleLineup-NFL-" + getLockByNameAndID(oneLineupDisplay.FLEX)}>
                                    <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                    />
                                </div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.FLEXName, oneLineupDisplay.FLEXTeam, oneLineupDisplay.FLEXPosition)} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.FLEXName + ' ('  + oneLineupDisplay.FLEXTeam + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.FLEXName + ' ('  + oneLineupDisplay.FLEXTeam + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.FLEXName, oneLineupDisplay.FLEXTeam, oneLineupDisplay.FLEXPosition)} target="_blank">
                                        {oneLineupDisplay.FLEXName.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.FLEXName.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.FLEXName.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.FLEXName}</div>
                                        }
                                    </a>
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Matchup: </div> 
                                            <div className={getOppClassRank(oneLineupDisplay.FLEXTeam, oneLineupDisplay.FLEXGameInfo, oneLineupDisplay.FLEXPosition)}>
                                                {getOppRank(oneLineupDisplay.FLEXTeam, oneLineupDisplay.FLEXGameInfo, oneLineupDisplay.FLEXPosition)}
                                            </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.FLEXTeam, oneLineupDisplay.FLEXGameInfo, oneLineupDisplay.FLEXPosition) + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.FLEXTeam + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.FLEXGameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.FLEXSalary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        
                                        <Image 
                                            src= {"Stack" + FLEXMiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + FLEXStack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>

                            <div className = "lineup-view-singleLineup-NFL">
                                <div className="lineup-item-singleLineup-Border-DST"><b>DST</b></div>
                                <div></div>
                                <div className="lineup-item-singleLineup">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.DSTName, oneLineupDisplay.DSTTeam, "DST")} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.DSTName + ' ('  + oneLineupDisplay.DSTTeam + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-margin">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(oneLineupDisplay.DSTName + ' ('  + oneLineupDisplay.DSTTeam + ')')}</div>
                                    <a href={getPlayerSearchURL(oneLineupDisplay.DSTName, oneLineupDisplay.DSTTeam, "DST")} target="_blank">
                                        {oneLineupDisplay.DSTName.length > 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.DSTName.substring(0,12) + '..'}</div>
                                        }
                                        {oneLineupDisplay.DSTName.length <= 20 &&
                                            <div className="divBoldVerySmall">{oneLineupDisplay.DSTName}</div>
                                        }
                                    </a>
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                          src= {getFunnel(oneLineupDisplay.DSTTeam, oneLineupDisplay.DSTGameInfo, 'DST') + ".png"}
                                          className={"player-view-funnel-single"}
                                          rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        <Image 
                                            src= {"NFLLogos/" + oneLineupDisplay.DSTTeam + ".png"}
                                            className={"lineup-item-singleLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate-margin">{oneLineupDisplay.DSTGameInfo}</div>
                                <div className="lineup-item-singleLineup-margin-salary">{oneLineupDisplay.DSTSalary}</div>
                                <div className="lineup-item-singleLineup-margin-small">
                                        
                                        <Image 
                                            src= {"Stack" + DSTMiniStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + DSTStack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                     
                </div>
        </div>
    );
}
export default NFLFullSingleLineup;