import React from 'react';
//import Box from '@mui/material/Box';
//import Rating from '@mui/material/Rating';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {CSVLink} from "react-csv";

const PGAFullLineupList = ({lineups, lineupExport, getImage, sport, site, lineupCount}) => {
  
const csvHeaders = [
        { label: 'G', key: 'G1'},
        { label: 'G', key: 'G2'},
        { label: 'G', key: 'G3'},
        { label: 'G', key: 'G4'},
        { label: 'G', key: 'G5'},
        { label: 'G', key: 'G6'}
    ];

    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

const csvReport = {
    filename: 'Lineups_' + sport + '_' + site + '_' + month.toString() + '_' + date.toString() + '_' + year.toString() + '_--' + lineupCount.toString() + '--Lineups' + '.csv',
    headers: csvHeaders,
    data: lineupExport
}; 

    return (
        <div className="lineup-list"> <br></br>
          <CSVLink {...csvReport} className="button">Export Lineups</CSVLink> <br></br> <br></br>
        <h3>Player Exposure Summary</h3>
        <div className="blueLine"> </div>
                <div className="outlet">
                        <div className="lineup-view-PGA">
                            <div className="lineup-item"><b>Player</b></div>
                            <div className="lineup-item"><b></b></div>
                            <div className="lineup-item"><b>Lineup Count</b></div>
                            <div className="lineup-item-center"><b>Rating</b></div>
                            <div className="lineup-item"><b>Lock</b></div>
                        </div>
                     
                        <Scrollbars id="scrollBarPlayers" style={{ height: 400 }}>
                        {lineups.map((lineup) => (
                            <div className = "lineup-view-PGA" key={lineup.LineupPlayerSummaryID}>
                                <div className="lineup-item">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(lineup.PlayerName)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="lineup-item-PGA">{lineup.PlayerName}</div>
                                <div className="lineup-item-PGA"><b>{lineup.NumberOfLineups.toString()}</b>  <i>{' (' + Math.round(((lineup.NumberOfLineups / lineupCount) * 100)).toString() + '%)'}</i></div>
                                <div className="lineup-item-PGA">{lineup.RatingInfo}</div>
                                <div className={"lineupLockImagePGA-" + lineup.LockInfo}>
                                      
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                           className="LockImageSize"
                                        />
                                      
                                </div>
                            </div>
                        ))}
                        </Scrollbars> 
                </div>
        </div>
    );
}
export default PGAFullLineupList;