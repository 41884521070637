import React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Checkbox from './Checkbox';

const PGAFullPlayerList = ({players,title,handleDelete, setRatingValue, getRating, getImage, getInjuryStatus, setLockValue, getLock, getPlayerStat}) => {

    const styles = {
        Scrollbars: {
          wdith: 0,
          height: 500
        }
    }

    return (
        <div className="player-list">
                <div className="outlet">
                        <div className="player-view-PGA-col">
                            <div className="player-item-PGA-col"><b>Rating</b></div>
                            <div className="player-item-PGA-col"></div>
                            <div className="player-item-PGA-col"><b>Player</b></div>
                            <div className="player-item-PGA-col-responsive"><b>Salary</b></div>
                            <div className="player-item-PGA-col-responsive"><b>Tourney</b></div>
                        </div>
                        <Scrollbars  id="scrollBarPlayers" className="scrollbar" autoHide style={{ height: 500 }}>
                        <div>
                        {players.map((player) => (
                            <div className = "player-view-PGA" key={player.ID}>
                                <div className={"player-item" + getLock(player.ID).toString()}>
                                <Box component="fieldset" 
                                    mb={0} borderColor="transparent" 
                                    sx={{ width: "0%", paddingLeft: "0px", paddingRight: "0px", paddingTop: "2px", paddingBottom: "0px"}}>
                                        <Rating
                                            name="PlayerRating"
                                            defaultValue={0}
                                            value= {getRating(player.ID)}
                                            onChange={(event, newValue) => setRatingValue(player.ID, newValue)}
                                        />
                                    </Box>                              
                                    <div className={"Lock_" + player.Position.toString()}>
                                        <Checkbox
                                            label=" Lock"
                                            value= {getLock(player.ID)}
                                            onChange={() => setLockValue(player.ID)}
                                        />
                                    </div>
                                </div>
                                <div className="player-item-image">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                        <Image 
                                            src=
                                                {getImage(player.Name)}
                                            rounded
                                            thumbnail
                                        />
                                        </div>
                                </div>
                                <div className="player-item">
                                    <div className="InjuryStatus">{getInjuryStatus(player.Name)}</div>
                                    {player.Name}
                                    <br></br>
                                    <div className="Matchupbox">
                                            <div className="Matchup">Top 10s: </div> 
                                            <div className="PlayerStat">
                                                {getPlayerStat(player.Name,'Top10')}
                                            </div>
                                    </div>
                                    <br></br>
                                    <div className="responsive-PGA-playerInfo">
                                        <div className="Matchup">Salary: </div>{player.Salary} <br></br>
                                        <div className="Matchup">Tourney: </div>{player['Game Info']}
                                    </div>
                                </div>
                                <div className="player-item-margintop"><b>{player.Salary}</b></div>
                                <div className="player-item-margintop">{player['Game Info']}</div>
                            </div>
                        ))}
                        </div>
                        </Scrollbars> 
                </div>
        </div>
    );
}
export default PGAFullPlayerList;