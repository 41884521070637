import ReactSlider from "react-slider";
import styled from 'styled-components';

const StyledThumb = styled.div`
height: 25px;
line-height: 25px;
width: 25px;
text-align: center;
background-color: #FFFFFF;
color: #000000;
border-radius: 50%;
cursor: grab;
`;

const Slider = ({ id, defaultValue, minValue, maxValue, stepAmount, value, onChange, charDisplay }) => {
  
    return (
    <ReactSlider
      className="horizontal-slider"
      thumbClassName= "example-thumb-slider"
      trackClassName="example-track"
      markClassName="example-mark"
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      min={minValue}
      max={maxValue}
      step={stepAmount}
      renderThumb={(props, state) => <div {...props}><b>{state.valueNow}{charDisplay}</b></div>}
    />
  );
};
export default Slider;