import React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Checkbox from './Checkbox';

const MLBFullPlayerList = ({players,title, setRatingValue, getRating, getImage, getOppPitcherName, getOppPitcherStats, getOppClassRank, getInjuryStatus, setStackValue, getStack, getStackClass, setLockValue, getLock, getPlayerHand, getLineupSpotImage, getPlayerStats, getGameOpp, getGameTime}) => {


    const styles = {
        Scrollbars: {
          wdith: 0,
          height: 500
        }
    }

    return (
        <div className="player-list">
                <div className="outlet">
                        <div className="player-view-MLB-col-Responsive">
                            <div className="player-item-MLB-col"><b>Rating</b></div>
                            <div className="player-item-MLB-col"></div>
                            <div className="player-item-MLB-col"><b>Player</b></div>
                            <div className="player-item-MLB-col-Responsive"><b>Pos</b></div>
                            <div className="player-item-MLB-col-Responsive"><b>Salary</b></div>
                        </div>
                        <Scrollbars  id="scrollBarPlayers" className="scrollbar" autoHide style={{ height: 500 }}>
                        <div>
                        {players.map((player) => (
                            <div className = "player-view-MLB-Responsive" key={player.ID}>
                                <div className={"player-item-MLB-" + getLock(player.ID).toString()}>
                                <Box component="fieldset" 
                                    mb={0} borderColor="transparent" 
                                    sx={{ width: "0%", paddingLeft: "0px", paddingRight: "0px", paddingTop: "2px", paddingBottom: "0px"}}>
                                        <Rating
                                            name="PlayerRating"
                                            defaultValue={0}
                                            value= {getRating(player.ID)}
                                            onChange={(event, newValue) => setRatingValue(player.ID, newValue)}
                                        />
                                    </Box>
                                    {player['Roster Position'] === "X" &&  
                                    <div className={"Stack_Visible"}>
                                        <Checkbox
                                            label=" Stack Only"
                                            value= {getStack(player.ID)}
                                            onChange={() => setStackValue(player.ID)}
                                        />
                                    </div>
                                    }
                                    <div className={"Lock"}>
                                        <Checkbox
                                            label=" Lock"
                                            value= {getLock(player.ID)}
                                            onChange={() => setLockValue(player.ID)}
                                        />
                                    </div>
                                </div>
                                <div className="player-item-MLB">
                                    <Image 
                                            src=
                                                {getImage(player.Name + ' ('  + player.TeamAbbrev + ')')}
                                            rounded
                                            className={"player-item-image-MLB-image"}
                                            thumbnail
                                    />   
                                    <div className="player-item-image-lineup">
                                                <Image 
                                                src=
                                                    {getLineupSpotImage(player.Name, player.TeamAbbrev)}
                                                    className={"player-item-MLB-lineupOrderImage"}
                                                    />  
                                    </div>                                                      
                                </div>
                              
                                <div className="player-item-MLB-playername">
                                    <div className="InjuryStatus">{getInjuryStatus(player.Name + ' ('  + player.TeamAbbrev + ')')}</div>
                                    {player.Name.length > 12 &&
                                        <div className="divBoldSmall">{player.Name.substring(0,12) + '.. ('  + player.TeamAbbrev + ')'}</div>
                                    }
                                     {player.Name.length <= 12 &&
                                        <div className="divBold">{player.Name + ' ('  + player.TeamAbbrev + ')'}</div>
                                    }
                                    <div className="responsive-MLB-divBoldSmallItalics"> {getPlayerHand(player.Name, player.TeamAbbrev)} </div>
                                 
                                    {player['Roster Position'] !== "P" &&
                                    <div className="Matchupbox-MLB">
                                            <br></br>
                                            <div className="Matchup">Matchup: </div> 
                                                <div className="divSmallerFont">{getGameOpp(player['Game Info'], player.TeamAbbrev)}{getGameTime(player['Game Info'])}</div>
                                            <div className={getOppClassRank(player.TeamAbbrev, player['Game Info'], player.Position)}>
                                                {getOppPitcherName(player.Name,  player.TeamAbbrev)}
                                                &nbsp;({getOppPitcherStats(player.Name,  player.TeamAbbrev)})
                                            </div>
                                            <div className="responsive-MLB-playerInfo">
                                                <div className="Matchup">Position: </div> 
                                                    <div className="divSmallerFont">{player.Position} </div>
                                            </div>
                                            <div className="responsive-MLB-playerInfo">
                                            <br></br>     <div className="Matchup">Salary: </div> 
                                                    <div className="divSmallerFont">{player.Salary} </div>
                                            </div>
                                    </div>
                                    }

                                    {player['Roster Position'] === "P" &&
                                    <div className="Matchupbox-MLB">
                                             <br></br>
                                            <div className="Matchup">Matchup: </div> <div className="divSmallerFont">{getGameOpp(player['Game Info'], player.TeamAbbrev)}{getGameTime(player['Game Info'])}</div>
                                            
                                           <br></br>
                                            <div className="Matchup">Avg Points: </div>
                                            <div className="divSmallerFont">
                                                {getPlayerStats("Avg Points",player.ID)}
                                            </div>
                                            <br></br>
                                            <div className="responsive-MLB-playerInfo">
                                              <div className="Matchup">Salary: </div> 
                                                    <div className="divSmallerFont">{player.Salary} </div>
                                            </div>
                                    </div>
                                    }

                                   
                                </div>
                                <div className="player-item-small-Responsive"><br></br><b>{player.Position}</b></div>
                                <div className="player-item-small-Responsive"><br></br><b>{player.Salary}</b></div>
                            </div>
                        ))}
                        </div>
                        </Scrollbars> 
                </div>
        </div>
    );
}
export default MLBFullPlayerList;