import React from 'react';
//import Box from '@mui/material/Box';
//import Rating from '@mui/material/Rating';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {CSVLink} from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { RoundaboutLeft } from '@mui/icons-material';

const NFLFullLineupList = ({lineups, lineupExport, getImage, sport, site, lineupCount, getInjuryStatus, getRatingImage, getRatingImageClass, getPlayerSearchURL}) => {

var csvHeaders = [];

if (site === 'DK')
{
    csvHeaders = [
        { label: 'QB', key: 'QB'},
        { label: 'RB', key: 'RB1'},
        { label: 'RB', key: 'RB2'},
        { label: 'WR', key: 'WR1'},
        { label: 'WR', key: 'WR2'},
        { label: 'WR', key: 'WR3'},
        { label: 'TE', key: 'TE'},
        { label: 'FLEX', key: 'FLEX'},
        { label: 'DST', key: 'DST'}
    ];
}
else // only FD right now
{
    csvHeaders = [
        { label: 'QB', key: 'QB'},
        { label: 'RB', key: 'RB1'},
        { label: 'RB', key: 'RB2'},
        { label: 'WR', key: 'WR1'},
        { label: 'WR', key: 'WR2'},
        { label: 'WR', key: 'WR3'},
        { label: 'TE', key: 'TE'},
        { label: 'FLEX', key: 'FLEX'},
        { label: 'DEF', key: 'DST'}
    ];
}

    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

const csvReport = {
    filename: 'Lineups_' + sport + '_' + site + '_' + month.toString() + '_' + date.toString() + '_' + year.toString() + '_--' + lineupCount.toString() + '--Lineups' + '.csv',
    headers: csvHeaders,
    data: lineupExport
}; 

    return (
        <div className="lineup-list"> <br></br>
          <CSVLink {...csvReport} className="button">Export Lineups</CSVLink> <br></br> <br></br>
        <h3>Player Exposure Summary</h3>
        <div className="blueLine"> </div>
                <div className="outlet">
                        <div className="lineup-view-NFL">
                            <div className="lineup-item-center"><b>Player</b></div>
                            <div className="lineup-item"><b></b></div>
                            <div className="lineup-item"><b></b></div>
                            <div className="lineup-item-center"><b>Pos</b></div>
                            <div className="lineup-item-center"><b>Lineup Count</b></div>
                            <div className="lineup-item-center"><b>Rating</b></div>
                        </div>
                     
                        <Scrollbars id="scrollBarPlayers" style={{ height: 400 }}>
                        {lineups.map((lineup) => (
                            <div className = "lineup-view-NFL" key={lineup.LineupPlayerSummaryID}>
                                <div className="lineup-item">
                                    <div style={{ display: "block", width: 60, height: 75 }}>
                                    <a href={getPlayerSearchURL(lineup.PlayerName, lineup.TeamAbbrev, lineup.Position)} target="_blank">
                                        <Image 
                                            src=
                                                {getImage(lineup.PlayerName + ' ('  + lineup.TeamAbbrev + ')')}
                                                className={"lineup-item-player-image"}
                                            rounded
                                            thumbnail
                                        />
                                    </a>
                                    </div>
                                </div>
                                <div className="lineup-item-NFL-name">
                                    <div className="InjuryStatusSmallResponsive">{getInjuryStatus(lineup.PlayerName + ' ('  + lineup.TeamAbbrev + ')')}</div>
                                    <a href={getPlayerSearchURL(lineup.PlayerName, lineup.TeamAbbrev, lineup.Position)} target="_blank">
                                        {lineup.PlayerName.length > 13 &&
                                            <div className="divBoldExtraSmall">{lineup.PlayerName}</div>
                                        }
                                        {lineup.PlayerName.length <= 13 &&
                                            <div className="divBoldVerySmall">{lineup.PlayerName}</div>
                                        }
                                    </a>
                                    <a href={getPlayerSearchURL(lineup.PlayerName, lineup.TeamAbbrev, lineup.Position)} target="_blank">
                                            <Image 
                                            src= {"RwLogo.png"}
                                            className={"player-view-rwLogo"}
                                            rounded
                                            />
                                    </a>
                                </div>
                                <div className="lineup-item-NFL-margin">
                                        <Image 
                                            src= {"NFLLogos/" + lineup.TeamAbbrev + ".png"}
                                            className={"lineup-item-bulkLineup-nfllogo"}
                                            rounded
                                        />
                                </div>
                                <div className={"lineup-item-bulkLineup-Border-" + lineup.Position}>{lineup.Position}</div>
                                <div className="lineup-item-NFL-margin-paddingright">
                                    <b>{lineup.NumberOfLineups.toString()}</b>   <div className="responsiveHide"><i>{' (' + Math.round(((lineup.NumberOfLineups / lineupCount) * 100)).toString() + '%)'}</i></div>
                                    
                                </div>
                                <div className="lineup-item-NFL-margin-xsmall">
                                        <Image 
                                            src= {getRatingImage(lineup.RatingInfo)}
                                            className={getRatingImageClass(lineup.RatingInfo)}
                                            rounded
                                        />
                                </div>
                            </div>
                        ))}
                        </Scrollbars> 
                </div>
        </div>
    );
}
export default NFLFullLineupList;