import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';

/*
npm install --save @fortawesome/free-brands-svg-icons --legacy-peer-deps
npm install --save @fortawesome/react-fontawesome --legacy-peer-deps
npm install --save @fortawesome/fontawesome-svg-core --legacy-peer-deps
npm install --save @fortawesome/free-solid-svg-icons --legacy-peer-deps
*/

const About = () => {
  return (
    <div className="content">
      <Helmet>
        <title>Breezy Builder | About Page</title>
        <meta name="description" content="Create NFL, MLB and PGA Daily Fantasy lineups from your own player ratings with advanced stacking & run-back options.."></meta>
        <link rel="canonical" href="/about" />
      </Helmet>
         <h1 className="h1About">Welcome to Breezy Builder!</h1>
         <h2 className="h2About">The free DFS lineup builder that can:</h2>
            <ul>
            <li>Create NFL, MLB and PGA lineups from your <i><b>own player ratings</b></i></li>
            <li>Review <i><b>1 lineup at a time</b></i> or <i><b>export up to 150 lineups</b></i></li>
            <li>Toggle advanced <i><b>stacking</b></i> options, with <i><b>run-backs</b></i></li>
            <li><i><b>Lock</b></i> players and set salary constraints</li>
            <li>Compatible with <i><b>Draftkings</b></i> and <i><b>Fanduel</b></i> contests</li>
            </ul>
              <br></br>
            For inquiries or feedback, contact <b>BreezyBuilderDFS@gmail.com</b> or follow on Twitter &nbsp; 
            <div className="socialMediaIcons">
              <a href="https://twitter.com/BreezyBuilder" target='_blank'>
                <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
              </a>
           </div>
            <br></br><br></br>
            {/*
                  Server costs add up; any <div className="divPink">donations</div> would be appreciated.  <br></br>
              <div className="divIndentAndColor"><div className="divPink">PayPal:</div>  BreezyBuilderDFS@gmail.com </div>
        
                <div className="divIndentAndColor"><div className="divPink">Ether: </div> BreezyBuilder.eth</div><br></br>
            */}
          <div className="divFinePrint">
                By using Breezy Builder, you are consenting that Breezy Builder is not responsible for the lineups it generates via its proprietary algorithm. 
                Please review all lineups before applying them in any way. Breezy Builder is meant for entertainment purposes only. 
           </div>
          
    </div>
  );
};
  
export default About;