import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {CSVLink} from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { RoundaboutLeft } from '@mui/icons-material';

const MLBFullSingleLineup = ({players, lineupCounter, getImage, getInjuryStatus, sport, site, getLockByNameAndID, getLineupSpotImage}) => {
  
const csvHeaders = [
    { label: 'P', key: 'P1'},
    { label: 'P', key: 'P2'},
    { label: 'C', key: 'C'},
    { label: '1B', key: 'B1'},
    { label: '2B', key: 'B2'},
    { label: '3B', key: 'B3'},
    { label: 'SS', key: 'SS'},
    { label: 'OF', key: 'OF1'},
    { label: 'OF', key: 'OF2'},
    { label: 'OF', key: 'OF3'}
];

    // Get site salary max
    let maxSiteSalary = 0;
    if (site === 'DK')
    {
        maxSiteSalary = 50000;
    }
    else if (site === 'FD') 
    {
        maxSiteSalary = 60000;
    }

    /*Get the lineup and salary info to display */
    let oneLineupDisplay = players[lineupCounter]; //object
    let lineupSalary = oneLineupDisplay.P1Salary + oneLineupDisplay.P2Salary + oneLineupDisplay.CSalary +
                        oneLineupDisplay.B1Salary + oneLineupDisplay.B2Salary + oneLineupDisplay.B3Salary +
                        oneLineupDisplay.SSSalary + oneLineupDisplay.OF1Salary + oneLineupDisplay.OF2Salary + oneLineupDisplay.OF3Salary;

    // format the salaries (for commas)
    const options = {  maximumFractionDigits: 2   }
    const formattedLineupSalary = Intl.NumberFormat("en-US",options).format(lineupSalary);
    const salaryLeft = Intl.NumberFormat("en-US",options).format(maxSiteSalary - lineupSalary);

    // STACKING -- figure out if player in this lineup is in primary, secondary or no stack
    let CStack = oneLineupDisplay.CStackType;
    let B1Stack = oneLineupDisplay.B1StackType;
    let B2Stack = oneLineupDisplay.B2StackType;
    let B3Stack = oneLineupDisplay.B3StackType;
    let SSStack = oneLineupDisplay.SSStackType;
    let OF1Stack = oneLineupDisplay.OF1StackType;
    let OF2Stack = oneLineupDisplay.OF2StackType;
    let OF3Stack = oneLineupDisplay.OF3StackType;

    /*Get the lineup to export */
    let exportOneLineup = players.slice(lineupCounter,lineupCounter+1); // array

    // Set up the 'export' link
    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const randomNumber = parseInt(Math.random() * 100000); // do make the download unique (hopefully)

    const csvReport = {
    filename: 'Lineup_' + sport + '_' + site + '_' + month.toString() + '_' + date.toString() + '_' + year.toString() + '_' + randomNumber.toString() + '.csv',
    headers: csvHeaders,
    data: exportOneLineup
    }; 

    return (
        <div className="lineup-list-singleLineup"> 
     <div className = "div-flexLeft">
                    <CSVLink {...csvReport} className="buttonDownload">Export Lineup</CSVLink> 
            </div> 
           <div className = "div-flexRight">
                <b>Salary Used:</b>&nbsp;&nbsp; <div className='divBlue'>{formattedLineupSalary}</div>
          </div>
          
            <div className = "div-flexRight">
                    <b>Salary Left:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <div className='divPink'>{salaryLeft}</div>     
            </div>
           <br></br>
                <div className="outlet">
                        <div className="lineup-view-singleLineup-MLB">
                            <div className="lineup-item-singleLineup-mlb"><b>Pos</b></div>
                            <div className="lineup-item-singleLineup-mlb"><b></b></div>
                            <div className="lineup-item-singleLineup-mlb"><b>Player</b></div>
                            <div className="lineup-item-singleLineup-mlb"><b></b></div>
                            <div className="lineup-item-singleLineup-mlb"><b></b></div>
                            <div className="lineup-item-singleLineup-mlb"><b>Game</b></div>
                            <div className="lineup-item-singleLineup-mlb"><b>Salary</b></div>
                            <div className="lineup-item-singleLineup-mlb"><b></b></div>
                        </div>
                        <div className="blueLine"> </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-P"><br></br><b>P</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.P1)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.P1Name + ' ('  + oneLineupDisplay.P1Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.P1Name, oneLineupDisplay.P1Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.P1Name + ' ('  + oneLineupDisplay.P1Team + ')')}</div>
                                    {oneLineupDisplay.P1Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.P1Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.P1GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.P1Salary}</div>
                                <div>
                                        <br></br>
                                        <Image 
                                            src= "Stack.png"
                                            className={"lineup-item-singleLineup-StackImage-"}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-P"><br></br><b>P</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.P2)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.P2Name + ' ('  + oneLineupDisplay.P2Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.P2Name, oneLineupDisplay.P2Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.P2Name + ' ('  + oneLineupDisplay.P2Team + ')')}</div>
                                    {oneLineupDisplay.P2Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.P2Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.P2GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.P2Salary}</div>
                                <div>
                                        <br></br>
                                        <Image 
                                            src= "Stack.png"
                                            className={"lineup-item-singleLineup-StackImage-"}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-AllBases"><br></br><b>C</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.C)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.CName + ' ('  + oneLineupDisplay.CTeam + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.CName, oneLineupDisplay.CTeam)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.CName + ' ('  + oneLineupDisplay.CTeam + ')')}</div>
                                    {oneLineupDisplay.CName}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.CTeam + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.CGameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.CSalary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + CStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + CStack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-AllBases"><br></br><b>1B</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.B1)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.B1Name + ' ('  + oneLineupDisplay.B1Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.B1Name, oneLineupDisplay.B1Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.B1Name + ' ('  + oneLineupDisplay.B1Team + ')')}</div>
                                    {oneLineupDisplay.B1Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.B1Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.B1GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.B1Salary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + B1Stack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + B1Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-AllBases"><br></br><b>2B</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.B2)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.B2Name + ' ('  + oneLineupDisplay.B2Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.B2Name, oneLineupDisplay.B2Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.B2Name + ' ('  + oneLineupDisplay.B2Team + ')')}</div>
                                    {oneLineupDisplay.B2Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.B2Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.B2GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.B2Salary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + B2Stack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + B2Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-AllBases"><br></br><b>3B</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.B3)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.B3Name + ' ('  + oneLineupDisplay.B3Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.B3Name, oneLineupDisplay.B3Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.B3Name + ' ('  + oneLineupDisplay.B3Team + ')')}</div>
                                    {oneLineupDisplay.B3Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.B3Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.B3GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.B3Salary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + B3Stack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + B3Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-AllBases"><br></br><b>SS</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.SS)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.SSName + ' ('  + oneLineupDisplay.SSTeam + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.SSName, oneLineupDisplay.SSTeam)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.SSName + ' ('  + oneLineupDisplay.SSTeam + ')')}</div>
                                    {oneLineupDisplay.SSName}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.SSTeam + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.SSGameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.SSSalary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + SSStack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + SSStack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-OF"><br></br><b>OF</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.OF1)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.OF1Name + ' ('  + oneLineupDisplay.OF1Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.OF1Name, oneLineupDisplay.OF1Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.OF1Name + ' ('  + oneLineupDisplay.OF1Team + ')')}</div>
                                    {oneLineupDisplay.OF1Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.OF1Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.OF1GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.OF1Salary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + OF1Stack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + OF1Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-OF"><br></br><b>OF</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.OF2)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.OF2Name + ' ('  + oneLineupDisplay.OF2Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.OF2Name, oneLineupDisplay.OF2Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.OF2Name + ' ('  + oneLineupDisplay.OF2Team + ')')}</div>
                                    {oneLineupDisplay.OF2Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.OF2Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.OF2GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.OF2Salary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + OF2Stack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + OF2Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>
                            <div className = "lineup-view-singleLineup-MLB">
                                <div className="lineup-item-singleLineup-Border-OF3"><br></br><b>OF</b></div>
                                <div className={"lineup-item-singleLineup-" + getLockByNameAndID(oneLineupDisplay.OF3)}>
                                        <br></br>
                                        <Image 
                                            src= "Lock.png"
                                            rounded
                                            className="LockImageSize"
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-mlb">
                                    <div style={{ display: "block", width: 50, height: 66 }}>
                                        <Image 
                                            src=
                                                {getImage(oneLineupDisplay.OF3Name + ' ('  + oneLineupDisplay.OF3Team + ')')}
                                            rounded
                                            thumbnail
                                        />
                                        <div className="player-item-image-lineup-MLB-Single">
                                            <Image 
                                                src=
                                                    {getLineupSpotImage(oneLineupDisplay.OF3Name, oneLineupDisplay.OF3Team)}
                                                className={"player-item-MLB-lineupOrderImage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lineup-item-singleLineup-mlb-padding">
                                    <div className="InjuryStatus">{getInjuryStatus(oneLineupDisplay.OF3Name + ' ('  + oneLineupDisplay.OF3Team + ')')}</div>
                                    {oneLineupDisplay.OF3Name}
                                </div>
                                <div>
                                        <Image 
                                            src= {"MLBLogos/" + oneLineupDisplay.OF3Team + ".png"}
                                            className={"lineup-item-singleLineup-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className="lineup-item-singleLineup-gamedate">{oneLineupDisplay.OF3GameInfo}</div>
                                <div className="lineup-item-singleLineup-MLBSalary">{oneLineupDisplay.OF3Salary}</div>
                                <div>
                                        <Image 
                                            src= {"Stack" + OF3Stack + ".png"}
                                            className={"lineup-item-singleLineup-StackImage-" + OF3Stack.toString()}
                                            rounded
                                        />
                                </div>
                            </div>  
                </div>
        </div>
    );
}
export default MLBFullSingleLineup;