//import { NavLink as Link } from "react-router-dom";
//import styled from "styled-components";

const Navbar = () => {
    return (
      <nav className="navbar">
        <img className="headerLogo" src="./BBLogo.png" alt="Breezy Builder" title="Breezy Builder" width="300px" height="70px" loading="eager" ></img>
        <div className="links">
          <a href="/">Home</a>
          <a href="/about" style={{ 
            color: 'white', 
            backgroundColor: '#f1356d',
            borderRadius: '8px' 
          }}>About</a>&nbsp;&nbsp;
        </div>
        <div className="divIndentAndCenterAndColor">Daily Fantasy Sports Lineup Builder</div>
      </nav>
    );
  }
   
  export default Navbar;