import React from 'react'
import './index04092024.css'
import Image from "react-bootstrap/Image";

const RadioButtonImage = ({ name, id, value, onChange, checked, text, title, alt }) => {
  return (
    <label htmlFor={id} className="radio-labelImage">
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      /> 
      <span className="custom-radioImage" />
      {name}

        <div className='radioImageDiv'>
        <Image 
        src=
        {text}
        title={title}
        alt={alt}
        rounded
        width={100}
        height={100}
        loading="eager"
        />
       </div>
    </label>
      
  )
}

export default RadioButtonImage