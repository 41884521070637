import React from 'react';
//import Box from '@mui/material/Box';
//import Rating from '@mui/material/Rating';
import "bootstrap/dist/css/bootstrap.css";
import Image from "react-bootstrap/Image";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {CSVLink} from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { RoundaboutLeft } from '@mui/icons-material';

const MLBFullLineupList = ({lineups, lineupExport, getImage, sport, site, lineupCount, getRatingImage, getRatingImageClass}) => {
  
const csvHeaders = [
        { label: 'P', key: 'P1'},
        { label: 'P', key: 'P2'},
        { label: 'C', key: 'C'},
        { label: '1B', key: 'B1'},
        { label: '2B', key: 'B2'},
        { label: '3B', key: 'B3'},
        { label: 'SS', key: 'SS'},
        { label: 'OF', key: 'OF1'},
        { label: 'OF', key: 'OF2'},
        { label: 'OF', key: 'OF3'}
    ];

    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

const csvReport = {
    filename: 'Lineups_' + sport + '_' + site + '_' + month.toString() + '_' + date.toString() + '_' + year.toString() + '_--' + lineupCount.toString() + '--Lineups' + '.csv',
    headers: csvHeaders,
    data: lineupExport
}; 

    return (
        <div className="lineup-list"> <br></br>
          <CSVLink {...csvReport} className="button">Export Lineups</CSVLink> <br></br> <br></br>
        <h3>Player Exposure Summary</h3>
        <div className="blueLine"> </div>
                <div className="outlet">
                        <div className="lineup-view-MLB">
                            <div className="lineup-item"><b>Player</b></div>
                            <div className="lineup-item"><b></b></div>
                            <div className="lineup-item"><b></b></div>
                            <div className="lineup-item-center"><b>Pos</b></div>
                            <div className="lineup-item-center"><b>Lineup Count</b></div>
                            <div className="lineup-item-center"><b>Rating</b></div>
                        </div>
                     
                        <Scrollbars id="scrollBarPlayers" style={{ height: 400 }}>
                        {lineups.map((lineup) => (
                            <div className = "lineup-view-MLB" key={lineup.LineupPlayerSummaryID}>
                                <div className="lineup-item-MLB-playerImage">
                                        <Image 
                                            src=
                                                {getImage(lineup.PlayerName + ' ('  + lineup.TeamAbbrev + ')')}
                                            rounded
                                            thumbnail
                                            className={"lineup-item-bulk-playerimage"}
                                        />
                                </div>
                                <div className="divBoldResponsive-marginTop">{lineup.PlayerName}</div>
                                <div className="lineup-item-mlb-center">
                                        <Image 
                                            src= {"MLBLogos/" + lineup.TeamAbbrev + ".png"}
                                            className={"lineup-item-bulk-mlblogo"}
                                            rounded
                                        />
                                </div>
                                <div className={"lineup-item-bulkLineup-MLB-Border-" + lineup.Position.charAt(0)}>{lineup.Position}</div>
                                <div className="lineup-item-mlb-center"><b>{lineup.NumberOfLineups.toString()}</b>  <div className="divXSmallFinePrint">{' (' + Math.round(((lineup.NumberOfLineups / lineupCount) * 100)).toString() + '%)'}</div></div>
                                <div className="lineup-item-mlb-xsmall">
                                    <Image 
                                            src= {getRatingImage(lineup.RatingInfo)}
                                            className={getRatingImageClass(lineup.RatingInfo)}
                                            rounded
                                        />
                                </div>
                            </div>
                        ))}
                        </Scrollbars> 
                </div>
        </div>
    );
}
export default MLBFullLineupList;